import { useState, useRef, ChangeEvent } from "react";
import { Modal, Form, Input, Card, Divider, Button, Progress, Checkbox, CheckboxProps } from "antd";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./UploadAssets.scss";
import { IMAGES } from "../../../shared/images";
import { RunVisionFileType, getUserId } from "../../../shared";
import { uploadAsset, uploadAssetURL } from "../../../services/contentManagement";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import {
  setFileUploadProgress,
  setURLUploadProgress,
} from "../../../store/contentManagement/contentSlice";

interface IUploadAssets {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
}

const UploadAssets = ({ isModalOpen, setIsModalOpen }: IUploadAssets) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [isUploadingUrl, setIsUploadingUrl] = useState<boolean>(false);
  const [isUploadingUrlDisabel, setIsUploadingUrlDisabel] = useState<boolean>(true);
  const [runVision, setRunVision] = useState<boolean>(false);
  const [fileType, setFileType] = useState<string>("");
  const [isShowRunVision, setIsShowRunVision] = useState<boolean>(false);

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const { fileUploadProgress, urlUploadProgress } = useSelector(
    (state: RootState) => state.content
  );

  const handleClose = () => {
    form.resetFields();
    setFileName("");
    setIsModalOpen(false);
    setFile(null);
    handleRemoveFile();
    setRunVision(false);
    setFileType("");
    setIsShowRunVision(false);
  };

  const handleClickUploadFile = () => {
    if (fileInputRef.current != null) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    setRunVision(false);
    setIsShowRunVision(false);

    if (e.target.files) {
      const file = e.target.files[0];
      const fileType = file?.name?.substring(file?.name.lastIndexOf(".") + 1);
      setIsShowRunVision(RunVisionFileType.includes(fileType));

      setFileType(fileType);
      setFile(file);
      setFileName(file.name);
    }
  };

  const handleRemoveFile = () => {
    setFileName("");
    setIsShowRunVision(false);
    setRunVision(false);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const onFinish = async () => {
    setIsUploading(true);
    dispatch(setFileUploadProgress(0));

    if (!!file) {
      const formData = new FormData();
      formData.append("file", file);

      await uploadAsset(formData, getUserId(), fileType, navigate, runVision);
      setIsModalOpen(false);
    } else {
      toast.error("Failed to upload asset");
    }
    setIsUploading(false);
    handleRemoveFile();
  };

  const onFinishURL = async () => {
    setIsUploadingUrl(true);
    dispatch(setURLUploadProgress(0));

    const uploadAssetsViaURL = form.getFieldValue("uploadAssetsViaURL");

    if (uploadAssetsViaURL) {
      await uploadAssetURL(getUserId(), uploadAssetsViaURL, navigate);
      setIsModalOpen(false);
    } else {
      toast.error("Failed to upload asset");
    }
    setIsUploadingUrl(false);
  };

  const handleChangeURL = (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.value?.trim()) {
      setIsUploadingUrlDisabel(false);
    } else {
      setIsUploadingUrlDisabel(true);
    }
  };

  const renderAsserUploadingState = (fileName: string, fileUploadProgress: number) => {
    return (
      <div className="uploading-inner">
        <h3 className="uploading-title">Uploading...</h3>
        <div className="uploading-progress">
          <div className="uploading-fileName">{fileName}</div>
          {fileUploadProgress ? (
            <Progress percent={fileUploadProgress} strokeColor={"#A100FF"} />
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  const handleChangeRunVision: CheckboxProps["onChange"] = (e) => {
    setRunVision(e.target.checked);
  };

  return (
    <Modal
      open={isModalOpen}
      closeIcon={false}
      onCancel={handleClose}
      centered
      footer={null}
      width={500}>
      <div className="container upload-asset-modal">
        <div className="header">
          <p className="title">Upload Asset</p>
          {!(isUploading || isUploadingUrl) && (
            <span onClick={handleClose}>
              <img className="closeIcon" alt="close-icon" src={IMAGES.closeIcon} />
            </span>
          )}
        </div>

        <div>
          <Form
            name="uploadFile"
            onFinish={onFinish}
            requiredMark={false}
            size="large"
            className="form-container add-user-form">
            <Card className="input-card">
              <p className="input-label">Upload</p>
              <div>
                <input
                  ref={fileInputRef}
                  className="file-input"
                  type="file"
                  multiple={false}
                  placeholder="Upload Asset"
                  onChange={handleFileUpload}
                />
                <div className="upload-file-container">
                  <div className="file-name">
                    {fileName ? (
                      <>
                        <span>{fileName}</span>
                        {!isUploading && (
                          <img src={IMAGES.closeIcon} alt="remove" onClick={handleRemoveFile} />
                        )}
                      </>
                    ) : (
                      <span>
                        <img className="browseIcon" alt="browse-icon" src={IMAGES.browseIcon} />
                        <span>Browse</span>
                      </span>
                    )}
                  </div>

                  <Button
                    onClick={handleClickUploadFile}
                    className="btn-primary btn-type2"
                    type="primary"
                    size="middle"
                    disabled={isUploading}>
                    Browse
                  </Button>
                </div>
              </div>

              {!isUploading && (
                <div className="run-vision-warpper">
                  <Checkbox
                    onChange={handleChangeRunVision}
                    checked={runVision}
                    value={runVision}
                    disabled={!isShowRunVision}>
                    Run Vision
                  </Checkbox>
                </div>
              )}

              {isUploading && renderAsserUploadingState(fileName, fileUploadProgress)}

              <Button
                loading={isUploading}
                htmlType="submit"
                className="btn-primary btn-type2 upload-btn"
                type="primary"
                size="middle"
                disabled={fileName === ""}>
                Upload
              </Button>
            </Card>

            <Divider className="divider">
              <p className="text-or">OR</p>
            </Divider>
          </Form>

          <Form
            form={form}
            name="uploadURL"
            initialValues={{ uploadAssetsViaURL: "" }}
            onFinish={onFinishURL}
            requiredMark={false}
            size="large"
            className="form-container add-user-form">
            <Card className="input-card">
              <p className="input-label">Upload via URL</p>

              <Form.Item
                name="uploadAssetsViaURL"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please enter the URL",
                  },
                  {
                    pattern:
                      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                    message: "Please enter valid URL",
                  },
                ]}>
                <Input placeholder="http://example.com" size="middle" onChange={handleChangeURL} />
              </Form.Item>

              {isUploadingUrl &&
                renderAsserUploadingState(
                  form.getFieldValue("uploadAssetsViaURL"),
                  urlUploadProgress
                )}

              <Button
                loading={isUploadingUrl}
                htmlType="submit"
                className="btn-primary btn-type2 upload-url"
                type="primary"
                size="middle"
                disabled={isUploadingUrlDisabel}>
                Upload
              </Button>
            </Card>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default UploadAssets;
