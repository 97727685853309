import "./AddEditUserManagement.scss";
import { Button, Form, Input, Modal, Select, Tag } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IMAGES, generatePassword, getCapitalizedStr } from "../../../shared";
import { CopyOutlined, RedoOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { addUser, updateUser } from "../../../services/userManagement";
import { IUser, IUserTaxonomyMapping } from "../../../store/userManagement/user.interface";
import { Industry } from "../../../pages/home/Home";
import { RootState } from "../../../store";
import { UserRoles } from "../../../shared";
const { Option } = Select;

interface IModal {
  isModalOpen: boolean;
  onCancel: VoidFunction;
  title: string;
  selectedUser: IUser | null;
  industries: Industry[];
  functions: Industry[];
  searchText: string;
  industryTaxonomyId: number | null;
  functionTaxonomyId: number | null;
  handleLogout: VoidFunction;
  userDetails: IUser;
}

const AddNewUser = ({
  isModalOpen,
  onCancel,
  title = "Update",
  selectedUser,
  industries,
  functions,
  searchText = "",
  industryTaxonomyId,
  functionTaxonomyId,
  handleLogout,
  userDetails,
}: IModal) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [autoPassword, setAutoPassword] = useState<any>("");
  const { userCurrPage } = useSelector((state: RootState) => state.user);
  const [selectedUserRole, setSelectedUserRole] = useState<string>("ADMIN");
  const [showRoleInfo, setShowRoleInfo] = useState<boolean>(false);

  const getSelectedTaxonomy = () => {
    let selectedIndustryIds: number[] = [];
    let selectedFunctionIds: number[] = [];
    const role = selectedUser?.userPermissions[0]?.role || "";

    if (
      selectedUser?.taxonomyMapping &&
      selectedUser?.taxonomyMapping.length &&
      role !== UserRoles.Admin
    ) {
      selectedUser?.taxonomyMapping.forEach((node: IUserTaxonomyMapping) => {
        if (node?.taxonomyId === industryTaxonomyId) {
          selectedIndustryIds = node?.taxonomyNodeIds || [];
        }
        if (node?.taxonomyId === functionTaxonomyId) {
          selectedFunctionIds = node?.taxonomyNodeIds || [];
        }
      });
    }

    return { selectedIndustryIds, selectedFunctionIds };
  };

  useEffect(() => {
    if (selectedUser) {
      const usersDeatilsSet = {
        name: selectedUser?.name,
        email: selectedUser?.email,
        roleName: selectedUser?.userPermissions[0].role,
      };
      const { selectedIndustryIds, selectedFunctionIds } = getSelectedTaxonomy();

      form.setFieldsValue({
        ...usersDeatilsSet,
        industry: selectedIndustryIds,
        functions: selectedFunctionIds,
      });
      setSelectedUserRole(usersDeatilsSet.roleName);
    } else {
      form.resetFields();
    }
  }, [selectedUser]);

  const getIsValuesChanged = (values: any) => {
    const prevName = selectedUser?.name || "";
    const prevEmail = selectedUser?.email || "";
    const prevRole = selectedUser?.userPermissions[0]?.role || "";
    const prevTaxonomyMapping = selectedUser?.taxonomyMapping || [];

    const currName = values?.name || "";
    const currEmail = values?.email || "";
    const currRole = values?.roleName || "";
    const currTaxonomyMapping = [
      { taxonomyId: industryTaxonomyId, taxonomyNodeIds: values?.industry || [] },
      { taxonomyId: functionTaxonomyId, taxonomyNodeIds: values?.functions || [] },
    ].filter((item) => item?.taxonomyNodeIds?.length);

    let isFormValueChanged = false;
    if (prevName !== currName || prevEmail !== currEmail || prevRole !== currRole) {
      isFormValueChanged = true;
    }

    let isTaxonomyMappingChanged = false;
    if (prevRole === UserRoles.Admin) {
      if (prevTaxonomyMapping?.length > 0) {
        isTaxonomyMappingChanged = true;
      }
    } else if (JSON.stringify(prevTaxonomyMapping) !== JSON.stringify(currTaxonomyMapping)) {
      isTaxonomyMappingChanged = true;
    }

    if (
      (prevRole === UserRoles.Admin && currTaxonomyMapping?.length) ||
      (currRole !== UserRoles.Admin &&
        JSON.stringify(prevTaxonomyMapping) !== JSON.stringify(currTaxonomyMapping))
    ) {
      isTaxonomyMappingChanged = true;
    }

    return { isFormValueChanged, isTaxonomyMappingChanged };
  };

  const onFinish = async (values: any) => {
    const { isFormValueChanged, isTaxonomyMappingChanged } = getIsValuesChanged(values);
    setIsLoading(true);

    try {
      const { roleName } = values;
      const request = {
        ...values,
        name: getCapitalizedStr(values.name),
        userPermission: [
          {
            roleName: roleName,
            configSettingId: 1,
          },
        ],
      };
      if (request.roleName) delete request.roleName;

      let taxonomybody = {
        taxonomyMapping: [
          { taxonomyId: industryTaxonomyId, taxonomyNodeIds: values?.industry || [] },
          { taxonomyId: functionTaxonomyId, taxonomyNodeIds: values?.functions || [] },
        ],
      };
      if (values?.roleName === UserRoles.Admin) {
        taxonomybody = {
          taxonomyMapping: [],
        };
      }

      if (selectedUser) {
        await updateUser(
          { ...request, userId: selectedUser?.id },
          searchText,
          userCurrPage,
          taxonomybody,
          isFormValueChanged,
          isTaxonomyMappingChanged
        );
        if (
          selectedUser.userPermissions[0].role != request.rolename &&
          selectedUser.id === userDetails?.id
        ) {
          handleLogout();
        }
      } else {
        await addUser(request, searchText, userCurrPage, taxonomybody, isTaxonomyMappingChanged);
      }
      onCancel();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setAutoPassword(generatePassword());
  }, []);

  const passwordCopied = () => {
    navigator.clipboard.writeText(autoPassword);
    toast.success("message copied");
  };

  const onPreventMouseDown = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const tagRender = (props: any) => {
    const { label, closable, onClose } = props;

    return (
      <Tag
        // color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginInlineEnd: 4,
        }}>
        <span className="label-ui">{label}</span>
      </Tag>
    );
  };

  const handleSelectRole = (value: string) => {
    setSelectedUserRole(value);
    if ([String(UserRoles.User), UserRoles.ContentEditor].includes(value) && !selectedUser) {
      if (industries && industries.length && industryTaxonomyId) {
        const industryNodeIds = industries.map((item) => item.taxonomyNodeId);
        form.setFieldValue("industry", industryNodeIds);
      }
      if (functions && functions.length && functionTaxonomyId) {
        const functionNodeIds = functions.map((item) => item.taxonomyNodeId);
        form.setFieldValue("functions", functionNodeIds);
      }
    }
    if (selectedUser) {
      form.setFieldValue("industry", []);
      form.setFieldValue("functions", []);
    }

    if (value === UserRoles.Admin && selectedUser?.userPermissions[0].role === value) {
      setShowRoleInfo(false);
    } else {
      setShowRoleInfo(true);
    }
  };

  const handleIndustryChange = (value: any[]) => {
    if (value.includes("selectAll")) {
      if (industries && industries.length && industryTaxonomyId) {
        const industryNodeIds = industries.map((item) => item.taxonomyNodeId);
        form.setFieldValue("industry", industryNodeIds);
      }
    }
  };

  const handleFunctionChange = (value: any[]) => {
    if (value.includes("selectAll")) {
      if (functions && functions.length && functionTaxonomyId) {
        const functionNodeIds = functions.map((item) => item.taxonomyNodeId);
        form.setFieldValue("functions", functionNodeIds);
      }
    }
  };

  return (
    <Modal
      centered
      title={title}
      afterClose={() => form.resetFields()}
      width={400}
      footer={
        <>
          <Button
            onClick={() => {
              form.resetFields();
              onCancel();
            }}
            className="btn-type1"
            size={"middle"}>
            Cancel
          </Button>
          <Button
            style={{ background: "var(--primary)" }}
            loading={isLoading}
            onClick={() => form.submit()}
            htmlType="submit"
            className="btn-type2"
            type="primary"
            size={"middle"}>
            {title}
          </Button>
        </>
      }
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      open={isModalOpen}>
      <div className="add-user-inner">
        <Form
          form={form}
          name="addUser"
          initialValues={{
            name: "",
            email: "",
            password: "",
            roleName: "",
            industry: [],
            functions: [],
          }}
          onFinish={onFinish}
          requiredMark={false}
          size="large"
          className="form-container add-user-form">
          <div className="container2">
            <div className="form-inner">
              <Form.Item
                label="Full Name"
                name="name"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please input your Full Name",
                  },
                  {
                    pattern: new RegExp(/^[a-zA-Z\s]*$/),
                    message: "Name field only accept alphabets",
                  },
                ]}>
                <Input placeholder="Enter full name" autoComplete="off" />
              </Form.Item>

              <Form.Item
                label="Email Address"
                name="email"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please input valid Email",
                  },
                ]}>
                <Input placeholder="Enter email address" autoComplete="off" />
              </Form.Item>

              <Form.Item
                className="select-input"
                label="Role"
                name="roleName"
                labelCol={{ span: 24 }}
                rules={[{ required: true, message: "Please select your role!" }]}>
                <Select
                  popupClassName="ui-dropdown-default"
                  value={selectedUser?.userPermissions[0].role || "USER"}
                  placeholder="Select role"
                  onChange={(e) => handleSelectRole(e)}>
                  <Option value="USER">USER</Option>
                  <Option value="CONTENT_EDITOR">CONTENT EDITOR</Option>
                  <Option value="ADMIN">ADMIN</Option>
                </Select>
              </Form.Item>
              {selectedUser && showRoleInfo && selectedUser.id === userDetails?.id && (
                <div className="role-alert edit-role-alert">
                  <img src={IMAGES.alertIcon} />
                  <span>Changing your role will log you out from the app.</span>
                </div>
              )}
              {selectedUserRole !== "ADMIN" && (
                <Form.Item
                  className="select-input"
                  label="Industry"
                  name="industry"
                  labelCol={{ span: 24 }}>
                  <Select
                    className="select-dropdown-multiple"
                    maxTagCount={3}
                    mode="multiple"
                    tagRender={tagRender}
                    popupClassName="ui-dropdown-default"
                    placeholder="Select Industry"
                    options={[{ label: "Select All", value: "selectAll" }, ...industries]}
                    allowClear
                    onChange={handleIndustryChange}
                  />
                </Form.Item>
              )}

              {selectedUserRole !== "ADMIN" && (
                <Form.Item
                  className="select-input"
                  label="Functions"
                  name="functions"
                  labelCol={{ span: 24 }}>
                  <Select
                    className="select-dropdown-multiple"
                    maxTagCount={3}
                    mode="multiple"
                    tagRender={tagRender}
                    popupClassName="ui-dropdown-default"
                    placeholder="Select Functions"
                    options={[{ label: "Select All", value: "selectAll" }, ...functions]}
                    allowClear
                    onChange={handleFunctionChange}
                  />
                </Form.Item>
              )}

              {!selectedUser && (
                <Form.Item
                  className="password-input"
                  label="Password"
                  name="password"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}>
                  <Input.Password placeholder="Enter password" />
                </Form.Item>
              )}
              {!selectedUser && (
                <div className="passwordCreater">
                  Suggested password is <div className="passwordText">{autoPassword}</div>
                  <div
                    className="copyPassword"
                    onClick={() => {
                      passwordCopied();
                    }}>
                    <CopyOutlined />
                  </div>
                  <div className="reset" onClick={() => setAutoPassword(generatePassword())}>
                    <RedoOutlined />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AddNewUser;
