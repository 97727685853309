import { LocalStorage, Month, RunVisionFileType } from "./constants";
import { setIsPdfShow, setPdfDetails } from "../store/pdfViewer/pdfViewer.slice";
import { store } from "../store";
import { toast } from "react-toastify";
import { ITaxonomyTree } from "../store/taxonomy/taxonomy.interface";
import { IMAGES } from ".";
import Cookies from "js-cookie";
import configs from "./config";

export const getLocalStorageItem = (name: string) => {
  return localStorage.getItem(name);
};

export const removeLocalstorage = () => {
  localStorage.removeItem(LocalStorage.Token);
  localStorage.removeItem(LocalStorage.User);
};

export const removeCookie = () => {
  Cookies.remove(LocalStorage.Token, { domain: configs.HOST });
  Cookies.remove(LocalStorage.User, { domain: configs.HOST });
};

export const getUserId = (): number => {
  const user = getLocalStorageItem(LocalStorage.User);
  const parsedUser = user && JSON.parse(user);

  return parsedUser?.id ?? -1;
};

export const formateDate = (date: string): string => {
  if (!!date) {
    const formatDate = new Date(date); // Convert received time to a JavaScript Date object

    const currentDate = new Date(); // Get the current date
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);
    const dayOfWeek = formatDate?.toLocaleDateString("en-US", {
      weekday: "short",
    });

    if (
      formatDate.getDate() === currentDate.getDate() &&
      formatDate.getMonth() === currentDate.getMonth() &&
      formatDate.getFullYear() === currentDate.getFullYear()
    )
      return "Today";
    if (
      formatDate.getDate() === yesterday.getDate() &&
      formatDate.getMonth() === currentDate.getMonth() &&
      formatDate.getFullYear() === currentDate.getFullYear()
    )
      return "Yesterday";

    return ` ${dayOfWeek} ${formatDate.getDate()}, ${formatDate.getFullYear()}`;
  }
  return "Invalid Date";
};

// under 999
export const uniqueId = () => {
  // Generate a timestamp
  const timestamp = new Date().getTime();

  // Generate a random number
  const random = Math.floor(Math.random() * 1000);

  // Concatenate timestamp and random number
  const combinedId = `${timestamp}${random}`;

  // Convert the combined string to a number and take the modulus with 1000
  const numericId = parseInt(combinedId, 10) % 1000;

  return numericId;
};

export const generatePassword = () => {
  var length = 8,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

export const getFormattedDate = (date: string, type: string): string => {
  if (date) {
    const newDate = new Date(date);
    const day = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();

    if (type === "type1") return `${Month[month]} ${day}, ${year}`;
    return `${day}, ${Month[month]} ${year}`;
  } else {
    return "";
  }
};

export const handleOpenPdfDoc = (
  assetId: number,
  fileName: string,
  presignedUrl: string,
  title: string,
  page: number,
  showOpenIcon: boolean
) => {
  store.dispatch(setIsPdfShow(true));
  store.dispatch(
    setPdfDetails({
      assetId: assetId,
      fileName: fileName,
      presignedUrl: presignedUrl,
      title,
      page,
      showOpenIcon,
    })
  );
};

export function convertDataLevels(dataLevels: any) {
  const result: any = [];

  dataLevels.forEach((item: any) => {
    const levels = item?.tree.reduceRight((acc: any, level: any) => {
      if (level?.level?.length > 1) {
        return level.level;
      } else {
        const currLevel = {
          id: level?.level[0]?.id,
          levelname: level?.level[0]?.levelname,
          description: level?.level[0]?.description,
          linkedAsset: level?.level[0]?.linkedAsset,
          children: acc?.length > 0 ? [...acc] : [],
        };
        return [currLevel];
      }
    }, []);

    levels.reduceRight((acc: any, curr: any) => {
      const existing = acc.find((item: any) => item?.levelname === curr?.levelname);
      if (existing) {
        existing?.children.push(...curr?.children);
      } else {
        acc.push(curr);
      }
      return acc;
    }, result);
  });

  return result;
}

export const getIndustryList = (data: ITaxonomyTree[] | null | undefined) => {
  const tempIndustriesArr: any = [];
  data?.length &&
    data.forEach((element: any) => {
      tempIndustriesArr.push({
        ...element,
        value: element.taxonomyNodeId,
        label: element.taxonomyNodeName,
      });
    });
  return tempIndustriesArr;
};

export const getFunctionsList = (data: ITaxonomyTree[]) => {
  const tempFunctionsArr: any = [];
  data.forEach((element: any) => {
    tempFunctionsArr.push({
      ...element,
      value: element.taxonomyNodeId,
      label: element.taxonomyNodeName,
    });
  });
  return tempFunctionsArr;
};

export const onDownloadPdf = async (
  pre_signed_url: string,
  setIsDownloading: (v: boolean) => void,
  fileName: string
) => {
  if (pre_signed_url) {
    try {
      setIsDownloading(true);
      // Fetch the PDF file from the URL
      const response = await fetch(pre_signed_url);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      // Create a temporary anchor element
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", fileName); // Specify the filename for the downloaded file
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(blobUrl);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      toast.error("Failed to download File");
    } finally {
      setIsDownloading(false);
    }
  }
};

export const getAllIds = (obj: any) => {
  const ids: any = [];
  function collectIds(obj: any) {
    if (typeof obj === "object" && obj !== null && "taxonomyNodeId" in obj) {
      ids.push(obj.taxonomyNodeId.toString());
    }
    if (Array.isArray(obj)) {
      obj.forEach((item) => collectIds(item));
    } else if (typeof obj === "object" && obj !== null) {
      for (let key in obj) {
        collectIds(obj[key]);
      }
    }
  }
  collectIds(obj);
  return ids;
};

export const mergeArrays = (newIds: string[], existingIds: string[]) => {
  for (let i = 0; i < newIds.length; i++) {
    if (!existingIds.includes(newIds[i])) {
      existingIds.push(newIds[i]);
    }
  }
  return existingIds;
};
export const generateRandomNumber = () => {
  const currentDate = new Date();
  const timestamp = currentDate.getTime(); // Get the current timestamp in milliseconds
  const randomSeed = timestamp % 10000; // Take last 4 digits of the timestamp
  const randomPart = Math.floor(Math.random() * 10000)
    .toString()
    .padStart(4, "0"); // Generate a random number and pad it to 4 digits
  return parseInt(String(randomSeed) + randomPart); // Append the random number to the timestamp
};

export const getUserRole = () => {
  const user = getLocalStorageItem(LocalStorage.User);
  const parsedUser = user && JSON.parse(user);

  return parsedUser?.userRole ?? null;
};

export const convertToNumber = (data: string[]) => {
  var numberArray = data.map(function (element) {
    return parseFloat(element);
  });
  return !!numberArray?.length ? numberArray : [];
};

export const convertToString = (data: number[]) => {
  var stringArray = data.map(function (element) {
    return element.toString();
  });
  return !!stringArray?.length ? stringArray : [];
};

export const compareFilters = (data: string[], existingData: string[]) => {
  const tempExistingData = [...existingData];

  tempExistingData.sort();
  data.sort();

  if (tempExistingData.length !== data.length) {
    return false;
  }

  for (let i = 0; i < tempExistingData.length; i++) {
    if (tempExistingData[i] !== data[i]) {
      return false;
    }
  }
  return true; // return true if all elements are equal
};

export const getCapitalizedStr = (str: string) => {
  if (str) {
    str = str.toLowerCase();
    const words = str.split(" ");

    // Capitalize the first letter of each word
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].slice(1);
    }

    return words.join(" ");
  }
  return "";
};

export const renderFileTypeIcon = (type: string | null) => {
  if (type === "png" || type === "jpg" || type === "jpeg") {
    return IMAGES.imageIcon;
  }
  if (type === "ppt" || type === "pptx") {
    return IMAGES.pptIcon;
  }
  if (type === "pdf") {
    return IMAGES.pdfIcon;
  }
  if (type === "xls" || type === "xlsx") {
    return IMAGES.xlsIcon;
  }
  if (type === "doc" || type === "docx") {
    return IMAGES.docsIcon;
  }
  if (!type) {
    return IMAGES.imageIcon;
  }
};

export const getUserName = () => {
  const user = getLocalStorageItem(LocalStorage.User);
  const parsedUser = user && JSON.parse(user);

  return parsedUser?.name ?? "";
};

// const groupItemsByFileName = (items: ICitations[]): IGroupted[] => {
//   const grouped: { [key: string]: IGroupedItems } = {};
//   items.forEach((item) => {
//     const key = `${item.title}-${item.file_name}-${item.original_file_name}`;
//     if (!(key in grouped)) {
//       grouped[key] = {
//         id: item.asset_id,
//         title: item.title,
//         file_name: item.file_name,
//         original_file_name: item.original_file_name,
//         page: [],
//       };
//     }
//     if (!grouped[key].page.includes(item.page)) {
//       grouped[key].page.push(item.page);
//     }
//   });

//   return Object.values(grouped);
// };

export const getCurrentLevelIds = (data: any) => {
  const ids: any = [];
  data.map((node: ITaxonomyTree) => {
    ids.push(node.taxonomyNodeId);
  });

  return ids;
};

export const truncateString = (str: string, maxLength: number) => {
  if (typeof str === "string" && str.length > maxLength) {
    return str.slice(0, maxLength) + "...";
  }
  return str;
};

export const getIsShowRunVision = (fileName: string) => {
  const fileType = fileName?.substring(fileName.lastIndexOf(".") + 1);
  return RunVisionFileType.includes(fileType);
};
