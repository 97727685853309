import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useBlocker } from "react-router-dom";
import { DeleteModal } from "../../../admin/components";
import { clearAllData } from "../../../store/generation/kpis/kpiGenerationSlice";
import { IFields, IKpiGenerations } from "../../../store/generation/kpis/kpiGeneration.interface";
import { getIsProcessing, getListKeys } from "../../../helper/GenerationHelper";

interface IUnsavedWarn {
  localFields: IFields;
  fields: IFields;
  kpiGenerations: IKpiGenerations;
}

const UnsavedWarn = ({ localFields, fields, kpiGenerations }: IUnsavedWarn) => {
  const dispatch = useDispatch();
  const [isShowUnsavedChangesModal, setIsShowUnsavedChangesModal] = useState<boolean>(false);

  const getIsAnyFieldChanged = () => {
    const prevTopic = fields?.topic?.trim() || "";
    const prevFunctionsKeys = (fields?.functionsKeys || []).slice().sort((a: any, b: any) => a - b);
    const prevProcess = getListKeys(fields?.process || [])
      .slice()
      .sort((a: any, b: any) => a - b);
    const prevSubProcess = getListKeys(fields?.subProcess || [])
      .slice()
      .sort((a: any, b: any) => a - b);

    const currTopic = localFields?.topic?.trim() || "";
    const currFunctionsKeys = (localFields?.functionsKeys || [])
      .slice()
      .sort((a: any, b: any) => a - b);
    const currProcess = getListKeys(localFields?.process || [])
      .slice()
      .sort((a: any, b: any) => a - b);
    const currSubProcess = getListKeys(localFields?.subProcess || [])
      .slice()
      .sort((a: any, b: any) => a - b);

    const isProcessing =
      getIsProcessing(kpiGenerations?.process_status) ||
      getIsProcessing(kpiGenerations?.sub_process_status) ||
      getIsProcessing(kpiGenerations?.document_status);

    if (
      prevTopic !== currTopic ||
      JSON.stringify(prevFunctionsKeys) !== JSON.stringify(currFunctionsKeys) ||
      JSON.stringify(prevProcess) !== JSON.stringify(currProcess) ||
      JSON.stringify(prevSubProcess) !== JSON.stringify(currSubProcess) ||
      isProcessing
    ) {
      return true;
    } else {
      return false;
    }
  };

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname && getIsAnyFieldChanged()
  );

  const handlecancelUnsavedChanges = () => {
    setIsShowUnsavedChangesModal(false);
    blocker.state === "blocked" && blocker.reset();
  };

  const handleProceedUnsavedChanges = () => {
    if (blocker.state === "blocked") {
      dispatch(clearAllData());
      blocker.proceed();
    }
  };

  useEffect(() => {
    if (blocker?.state === "blocked") {
      setIsShowUnsavedChangesModal(true);
    }
  }, [blocker]);

  return (
    <>
      <DeleteModal
        isLoading={false}
        isModalOpen={isShowUnsavedChangesModal}
        handleCancel={handlecancelUnsavedChanges}
        handleSubmit={handleProceedUnsavedChanges}
        message="This will remove your unsaved changes."
        title="Are you sure you want to leave?"
        submitBtnName="Proceed"
      />
    </>
  );
};

export default UnsavedWarn;
