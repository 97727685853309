import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useBlocker } from "react-router-dom";
import { DeleteModal } from "../../../admin/components";
import { RootState } from "../../../store";
import { clearAllDataBusProp } from "../../../store/generation/businessProposal/businessProposalSlice";

const UnsavedWarn = () => {
  const dispatch = useDispatch();
  const { fields } = useSelector((state: RootState) => state.busProposalSlice);
  const [isShowUnsavedChangesModal, setIsShowUnsavedChangesModal] = useState<boolean>(false);

  const getIsAnyFieldChanged = () => {
    // Need to update condition once API will work
    if (
      fields?.topic?.trim()?.length ||
      (fields?.industry && fields?.function?.length) ||
      (fields?.function && fields?.function?.length) ||
      (fields?.agenda && fields?.agenda?.length) ||
      (fields?.competitors && fields?.competitors?.length)
    ) {
      return true;
    } else {
      return false;
    }
  };

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname && getIsAnyFieldChanged()
  );

  const handlecancelUnsavedChanges = () => {
    setIsShowUnsavedChangesModal(false);
    blocker.state === "blocked" && blocker.reset();
  };

  const handleProceedUnsavedChanges = () => {
    if (blocker.state === "blocked") {
      dispatch(clearAllDataBusProp());
      blocker.proceed();
    }
  };

  useEffect(() => {
    if (blocker?.state === "blocked") {
      setIsShowUnsavedChangesModal(true);
    }
  }, [blocker]);

  return (
    <>
      <DeleteModal
        isLoading={false}
        isModalOpen={isShowUnsavedChangesModal}
        handleCancel={handlecancelUnsavedChanges}
        handleSubmit={handleProceedUnsavedChanges}
        message="This will remove your unsaved changes."
        title="Are you sure you want to leave?"
        submitBtnName="Proceed"
      />
    </>
  );
};

export default UnsavedWarn;
