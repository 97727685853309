import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { AssetData, IAssets, IAssetsSliceProps, RecentlyViewedAssets } from "./assets.interface";

const initialState: IAssetsSliceProps = {
  recentlyUploadedAssets: [],
  recentlyViewedAssets: [],
  isRecentlyUploadedAssetsLoading: false,
  linkedAssetsData: [],
  isLinkedAssetLoading: false,
  isRecentlyViewedAssetsLoading:false,
};

export const assetsSlice = createSlice({
  name: "assetsSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setRecentlyUploadedAssets: (state, action: PayloadAction<IAssets[]>) => {
      state.recentlyUploadedAssets = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isRecentlyUploadedAssetsLoading = action.payload;
    },
    setRecentlyViewedAssets: (state, action: PayloadAction<RecentlyViewedAssets[]>) => {
      state.recentlyViewedAssets = action.payload;
    },
    setLinkedAssetsData: (state, action: PayloadAction<AssetData[]>) => {
      state.linkedAssetsData = action.payload;
    },
    setIsLinkedAssetLoading: (state, action: PayloadAction<boolean>) => {
      state.isLinkedAssetLoading = action.payload;
    },
    setIsRecentlyViewedAssetsLoading: (state, action: PayloadAction<boolean>) => {
      state.isRecentlyViewedAssetsLoading = action.payload;
    },
  },
});

export const {
  setRecentlyUploadedAssets,
  setIsLoading,
  setRecentlyViewedAssets,
  setLinkedAssetsData,
  setIsLinkedAssetLoading,
  setIsRecentlyViewedAssetsLoading
} = assetsSlice.actions;

export default assetsSlice.reducer;
