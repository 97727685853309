import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Card, Table } from "antd";

import SkeletonTable from "./SkeletonTable";
import { RootState } from "../../../store";
import { exportDocColumns, exportDocData, getIsProcessing } from "../../../helper/GenerationHelper";
import { IMAGES, onDownloadPdf } from "../../../shared";

const KpiExportDoc = () => {
  const { isDisable, isLoading, kpiGenerations, isAllLoading, isKpiDataLoading } = useSelector(
    (state: RootState) => state.kpiGeneration
  );
  const loading = isLoading?.exportDoc || isAllLoading;

  const rowSelection = {
    selectedRowKeys: [exportDocData[0].key],
  };

  const handleExportDocNext = async () => {
    if (kpiGenerations?.document_pre_signed_url) {
      onDownloadPdf(
        kpiGenerations?.document_pre_signed_url,
        () => {},
        kpiGenerations?.document_name || `generated_kpi_${kpiGenerations?.kpi_id}`
      );
      toast.success("Document successfully exported");
    } else {
      toast.error("Failed to export");
    }
  };

  return (
    <>
      <Card className="card-item">
        <div className="card-header">
          <span className="card-number">6</span>
          <h2>Export Document</h2>
        </div>

        <div className="card-content">
          {isKpiDataLoading ? (
            <SkeletonTable />
          ) : (
            <Table
              rowSelection={{
                type: "radio",
                ...rowSelection,
              }}
              columns={exportDocColumns}
              dataSource={exportDocData}
              pagination={false}
              rowClassName={isDisable?.exportDoc || loading ? "disable-table-row" : ""}
            />
          )}
        </div>

        {!isKpiDataLoading && (
          <div className="card-footer acn-flex acn-flex-end">
            <div className="card-footer-right acn-flex acn-flex-middle">
              {(isLoading?.exportDoc || getIsProcessing(kpiGenerations?.document_status)) && (
                <div className="processing-wrap">
                  <img src={IMAGES.loadingSpinner} alt="loading" className="spinnerSvg" />
                  Processing
                </div>
              )}
              <Button
                type="primary"
                className="outline-btn"
                disabled={isDisable?.exportDoc || loading}
                onClick={handleExportDocNext}>
                Export
              </Button>
            </div>
          </div>
        )}
      </Card>
    </>
  );
};

export default KpiExportDoc;
