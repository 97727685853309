import { useLocation, useNavigate } from "react-router-dom";
import "./AdminSideBar.scss";
import { IMAGES, PATHS, UserRoles, getUserRole } from "../../shared";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { clearContentFilter } from "../../store/contentManagement/contentSlice";
import { setUserCurrPage } from "../../store/userManagement/usersSlice";
import { setTaxonomyCurrPage } from "../../store/taxonomy/taxonomySlice";

const AdminSideBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const checkIsActive = (path: string): boolean => {
    const parts = path.split("/admin/");
    const currentPath = parts.length > 1 ? parts[1] : "";

    if (pathname.includes(currentPath)) return true;
    return false;
  };

  const handlePath = (path: PATHS) => {
    if (path === PATHS.adminContentManagement) {
      dispatch(clearContentFilter());
      navigate(`${path}?page=1&searchText=`);
      return;
    } else if (path === PATHS.adminUserManagement) {
      dispatch(setUserCurrPage(1));
    } else if (path === PATHS.adminTaxonomyManagement) {
      dispatch(setTaxonomyCurrPage(1));
    }
    navigate(path);
  };

  const renderNavigation = (title: string, icon: string, path: PATHS) => {
    return (
      <div
        className={
          checkIsActive(path)
            ? "activeSideNavTextIconWrap sideNavTextIconWrap"
            : "sideNavTextIconWrap"
        }
        onClick={() => {
          if (path !== pathname) {
            handlePath(path);
          }
        }}>
        <img className="logo-img" src={icon} alt="userManagement" />
        <div className="sideNavText">{title}</div>
      </div>
    );
  };
  // toggleMenu
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.classList.toggle("sidebar-filter-open"); // Toggle the class on body tag
  };
  return (
    <div id="sidebar" className="sidebar position-fixed">
      <span className="sidebar-overlay" onClick={toggleMenu}></span>
      <div className="mobileView toggle-click" onClick={toggleMenu}>
        <div className="arrow-list">
          <img className="icon" src={IMAGES.arrowMenu} alt="Icon" />
        </div>
        Admin Menu
      </div>
      <div className="adminSideNavWrap">
        {getUserRole() === UserRoles.Admin &&
          renderNavigation("User Management", IMAGES.usreManagementIcon, PATHS.adminUserManagement)}
        {getUserRole() === UserRoles.Admin &&
          renderNavigation(
            "Taxonomy Management",
            IMAGES.taxonomyManagement,
            PATHS.adminTaxonomyManagement
          )}
        {renderNavigation(
          "Content Management",
          IMAGES.contentManagement,
          PATHS.adminContentManagement
        )}
        {getUserRole() === UserRoles.Admin &&
          renderNavigation("Logs", IMAGES.logos, PATHS.adminLogs)}
      </div>
    </div>
  );
};

export default AdminSideBar;
