import { Skeleton, Table } from "antd";
import "../../../pages/generation/Generation.scss";

const SkeletonTable = () => {
  const columns = [
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "no",
      key: "no",
      width: "auto",
      render: () => <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />,
    },
    {
      title: <Skeleton.Input style={{ width: 30, height: 14 }} active={true} />,
      dataIndex: "name",
      key: "name",
      width: "auto",
      render: () => <Skeleton.Input style={{ width: 80, height: 22 }} active={true} />,
    },
    {
      title: "",
      dataIndex: "email",
      key: "email",
      width: "auto",
      render: () => null,
    },
    {
      title: "",
      dataIndex: "tag",
      key: "tag",
      width: "auto",
      render: () => null,
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={[{}, {}, {}, {}]}
        tableLayout="fixed"
        scroll={{ y: "calc(100vh - 315px)" }}
        pagination={false}
      />
    </>
  );
};

export default SkeletonTable;
