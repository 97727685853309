import { Button, Card, Col, DatePicker, Row, Typography } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getAdminLogsData } from "../../../services/conversationalSearch";
import { IMAGES } from "../../../shared";
import { RootState } from "../../../store";
import "./AdminLogs.scss";
import { toast } from "react-toastify";
import { Dayjs } from "dayjs";

const { RangePicker } = DatePicker;

const AdminLogs = () => {
  const { isExportingChatHistoryLoading } = useSelector(
    (state: RootState) => state.conversationalSearch
  );
  const [selectedDateRangeKeys, setSelectedDateRangeKeys] = useState<string[] | null>(null);

  const handleExportCsvAction = () => {
    selectedDateRangeKeys
      ? getAdminLogsData(selectedDateRangeKeys[0], selectedDateRangeKeys[1])
      : toast.error("Please select date range");
  };

  const onChange = (_: [Dayjs | null, Dayjs | null], dateStrings: [string, string]) => {
    if (dateStrings[0] === "" || dateStrings[1] === "") {
      setSelectedDateRangeKeys(null);
    } else setSelectedDateRangeKeys(dateStrings);
  };

  const disabled7DaysDate = (current: Dayjs, info: { from?: Dayjs }) => {
    const { from } = info;
    if (from) {
      return Math.abs(current.diff(from, "days")) >= 7;
    }
    return false;
  };

  return (
    <div className="content conversational-search-page sidebar-with-content">
      <div className="conversational-asset-container">
        <Row>
          <Col className="page-title-card" span={24}>
            <Card>
              <div className="page-title-box acn-flex acn-flex-middle acn-flex-between">
                <Typography className="card-heading">Logs</Typography>
                <div className="acn-flex acn-flex-middle acn-flex-between date-range">
                  <label>Select date range: </label>
                  <RangePicker onChange={onChange} disabledDate={disabled7DaysDate} />
                </div>
              </div>
            </Card>
          </Col>

          <Col className="global-table-card logs-card" span={24}>
            <Card>
              <div className="logs-frame">
                <img src={IMAGES.logsFrame} alt="avatar" />
                <div className="export-logs">
                  <h6>Export your logs easily</h6>
                  <Button
                    icon={<img src={IMAGES.exportIcon} alt="add" />}
                    className="add-user-button acn-flex acn-flex-middle csv-export"
                    loading={isExportingChatHistoryLoading}
                    onClick={handleExportCsvAction}>
                    Export
                  </Button>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AdminLogs;
