import { Button, Card, Col, Select, Table, Typography } from "antd";
import { TaxonomyView } from "../../../shared/constants";
import "./ManageTaxonomyTags.scss";
import { IMAGES } from "../../../shared";
import { Link } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";


interface DataType {
  key: React.Key;
  rowNumber: any;
  name: string;
  email: string;
  role: string;
}

const ManageTaxonomyTags = () => {
  var taxonomyView = TaxonomyView.FlatKeywords;

  const renderTaxonomyView = () => {
    if (taxonomyView === TaxonomyView.TreeHierarchy) {
      return "tree";
    } else {
      return "flat";
    }
  };


  const columns: ColumnsType<DataType> = [
    {
      title: "No.",
      dataIndex: "name",
      key: "name",
      render: () => (
        <>
          <span className="td-label">No. : </span>1
        </>
      ),
    },
    {
      title: "Title",
      dataIndex: "email",
      key: "email",
      render: () => (
        <>
          <span className="td-label">Title : </span>
          <span>Tech</span>
        </>
      ),
    },
    {
      title: "Description",
      dataIndex: "role",
      key: "role",
      render: () => (
        <>
          <span className="td-label">Description : </span>
          <span>
            Lorem Ipsum is simply dummy text of the printing and typesetting indust... <span className="more-link">+more</span>
          </span>
        </>
      ),
    },
    {
      title: "No. of linked assets",
      key: "action",
      render: () => (
        <>
          <span className="td-label">No. of linked assets : </span>
          <span>50</span>
        </>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: "1",
      rowNumber: "1",
      name: "Tech",
      email: "Lorem Ipsum is simply dummy text of the printing and typesetting indust... +more",
      role: "50",
    },
    {
      key: "1",
      rowNumber: "1",
      name: "Tech",
      email: "Lorem Ipsum is simply dummy text of the printing and typesetting indust... +more",
      role: "50",
    },
    {
      key: "1",
      rowNumber: "1",
      name: "Tech",
      email: "Lorem Ipsum is simply dummy text of the printing and typesetting indust... +more",
      role: "50",
    },
    {
      key: "1",
      rowNumber: "1",
      name: "Tech",
      email: "Lorem Ipsum is simply dummy text of the printing and typesetting indust... +more",
      role: "50",
    },
    {
      key: "1",
      rowNumber: "1",
      name: "Tech",
      email: "Lorem Ipsum is simply dummy text of the printing and typesetting indust... +more",
      role: "50",
    },
  ];
  

  return (
    <>
    <div className="page-content top-container user-management-page">
    <Col className="page-title-card" span={24}>
          <Card>
            <div className="page-title-box acn-flex acn-flex-middle acn-flex-between">
              <div className="page-title-left">
                <Typography className="card-heading">
                  <Link to={"/ "} type="button" className="mr-5">
                    <Button className="back-arrow">
                      <LeftOutlined />
                    </Button>
                  </Link>
                  Manage taxonomy
                </Typography>
              </div>

              <div className="page-title-right acn-flex acn-flex-middle acn-flex-between">
                <div className="flat-keywords">
                  <img src={IMAGES.keywordIcon} alt="avatar" />
                  Flat keywords
                </div>
                
                <Select className="levels-dropdown"
                  placeholder="Select levels"
                  optionFilterProp="children"
                  size="small"
                  options={[
                    {
                      value: "level 01",
                      label: "Level 01",
                    },
                    {
                      value: "level 02",
                      label: "Level 02",
                    },
                    {
                      value: "level 03",
                      label: "Level 03",
                    },
                  ]}
                />
              </div>
            </div>
          </Card>
        </Col>



        <Col className="global-table-card table-ui" span={24}>
          <Card>
            <Table className="global-table responsive-table" columns={columns} dataSource={data} />
          </Card>
        </Col>

      
        </div>
         {renderTaxonomyView()}
    </>
  );
};

export default ManageTaxonomyTags;
