import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import { Button, Card, Col, Dropdown, MenuProps, Row, Table, Typography } from "antd";
import "./TaxonomyManagement.scss";
import { RootState } from "../../../store";
import {
  IMAGES,
  PATHS,
  TaxonomyType,
  PageLimit,
  CustomPagination,
  truncateString,
} from "../../../shared";
import { CreateTaxonomy, DeleteModal } from "../../components";
import SummaryManagement from "../contentManagement/SummaryManagement";
import { TaxonomyData } from "../../../store/taxonomy/taxonomy.interface";
import { deleteTaxonomyManagement, fetchAllTaxonomy } from "../../../services/taxonomy";
import { setTaxonomyCurrPage } from "../../../store/taxonomy/taxonomySlice";
import SkeletonTable from "../userManagement/SkeletonTable";

const TaxonomyManagement = () => {
  const dispatch = useDispatch();
  const {
    allTaxonomyData,
    taxonomyTableLoading,
    taxonomyDeleteLoading,
    totalTaxonomyCount,
    taxonomyCurrPage,
  } = useSelector((state: RootState) => state.taxonomy);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [deleteModal, setIsDeleteModal] = useState<boolean>(false);
  const [seletedRowId, setSelectedRowId] = useState<number>(-1);
  const [isSummaryModal, setIsSummaryModal] = useState<number>(-1);
  const [summaryDescription, setSummaryDescirption] = useState<string>("");

  const navigate = useNavigate();

  useEffect(() => {
    fetchAllTaxonomy(taxonomyCurrPage);
  }, [taxonomyCurrPage]);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            dispatch(setTaxonomyCurrPage(taxonomyCurrPage));
            setIsModalOpen(true);
            navigate(`${PATHS.adminTaxonomyOverview}/${seletedRowId}`, {
              state: { isEditMode: true },
            });
          }}>
          Edit
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            dispatch(setTaxonomyCurrPage(taxonomyCurrPage));
            setIsDeleteModal(true);
          }}
          className="taxonomyDeleteText">
          Delete
        </div>
      ),
    },
  ];

  const renderTaxonomyType = (type: string) => {
    if (type === TaxonomyType.TREE)
      return (
        <div className="taxonomyTypeIconWrap">
          <img src={IMAGES.organogramIconPng} alt="organogram" />
          Tree hierarchy
        </div>
      );
    return (
      <div className="taxonomyTypeIconWrap">
        <img src={IMAGES.flatKeywordIconPng} alt="flatKeyword" />
        Flat keywords
      </div>
    );
  };

  const columns: ColumnsType<TaxonomyData> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "230px",
      render: (text, v: TaxonomyData) => (
        <>
          <span className="td-label">Title : </span>
          <strong
            className="taxonomyTableTitle cursor-pointer"
            onClick={() => {
              dispatch(setTaxonomyCurrPage(taxonomyCurrPage));
              navigate(`${PATHS.adminTaxonomyOverview}/${v.taxonomyId}`, {
                state: { isEditMode: false },
              });
            }}>
            {text}
          </strong>
        </>
      ),
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
      key: "purpose",
      width: "400px",
      render: (text, row) => {
        const maxLength = 60;
        return (
          <>
            <span className="td-label">Purpose : </span>
            <div className="tree-discription acn-flex">
              <span>{truncateString(text, maxLength)}</span>
              {text?.length > maxLength && (
                <u
                  onClick={() => {
                    setSummaryDescirption(row?.purpose);
                    setIsSummaryModal(row?.taxonomyId);
                  }}
                  className="more-tag">
                  +more
                </u>
              )}

              <SummaryManagement
                isOpen={isSummaryModal === row?.taxonomyId}
                description={summaryDescription}
                setModalClose={handleSummaryModal}
              />
            </div>
          </>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "250px",
      render: (_, row) => (
        <>
          <span className="td-label">Type : </span>
          {renderTaxonomyType(row?.taxonomyType)}
        </>
      ),
    },
    {
      title: "Levels",
      dataIndex: "levels",
      key: "levels",
      width: "120px",
      render: (text) => (
        <>
          <span className="td-label">Levels : </span>
          {text}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "60px",
      render: (v: TaxonomyData) => {
        return (
          <>
            <span className="td-label">Action : </span>
            <div className="actions-icons-wrap">
              <Dropdown
                overlayClassName="table-action-dropdown"
                menu={{
                  onClick: () => {
                    setSelectedRowId(v?.taxonomyId);
                  },
                  items,
                }}
                trigger={["click"]}
                placement="bottomRight">
                <Button className="action-bta" onClick={() => setSelectedRowId(v.taxonomyId)}>
                  <img src={IMAGES.dotsIcon} alt="add" className="dots-icon" />
                </Button>
              </Dropdown>
              <img
                src={IMAGES.arrowIcon}
                alt="arrowRightIcon"
                onClick={() => {
                  dispatch(setTaxonomyCurrPage(taxonomyCurrPage));
                  navigate(`${PATHS.adminTaxonomyOverview}/${v.taxonomyId}`, {
                    state: { isEditMode: false },
                  });
                }}
              />
            </div>
          </>
        );
      },
    },
  ];

  const handleSummaryModal = () => {
    setIsSummaryModal(-1);
    setSummaryDescirption("");
  };

  const onRemove = async () => {
    await deleteTaxonomyManagement(seletedRowId);

    setIsDeleteModal(false);
    dispatch(setTaxonomyCurrPage(taxonomyCurrPage));
  };

  const handleOnCancel = () => {
    setIsModalOpen(false);
    setIsDeleteModal(false);
  };

  const handlePagination = (page: number) => {
    dispatch(setTaxonomyCurrPage(page));
  };

  return (
    <div className="page-content top-container user-management-page  taxonomy-management-page">
      <Row>
        <Col className="page-title-card" span={24}>
          <Card>
            <div className="page-title-box acn-flex acn-flex-middle acn-flex-between">
              <div className="page-title-left">
                <Typography className="card-heading">Taxonomy Management</Typography>
              </div>

              <div className="page-title-right">
                <Button
                  className="add-user-button acn-flex acn-flex-middle plus-icon-bg-btn"
                  onClick={() => setIsModalOpen(true)}>
                  <span className="acn-flex acn-flex-middle acn-flex-center">
                    <img src={IMAGES.roundPlusIcon} alt="add" />
                  </span>
                  Create taxonomy
                </Button>
              </div>
            </div>
          </Card>
        </Col>

        <Col className="global-table-card table-ui" span={24}>
          <Card>
            {taxonomyTableLoading ? (
              <SkeletonTable />
            ) : (
              <Table
                className="global-table responsive-table"
                columns={columns}
                dataSource={allTaxonomyData}
                scroll={{ y: 350 }}
                pagination={false}
              />
            )}

            {allTaxonomyData && allTaxonomyData.length ? (
              <CustomPagination
                currentPage={taxonomyCurrPage}
                total={totalTaxonomyCount}
                handlePagination={handlePagination}
                pageSize={PageLimit.Taxonomy}
              />
            ) : (
              <></>
            )}
          </Card>
        </Col>
      </Row>

      {/* create taxonomy modal */}
      <CreateTaxonomy
        title={"Create taxonomy"}
        isModalOpen={isModalOpen}
        onCancel={handleOnCancel}
        setIsModalOpen={setIsModalOpen}
      />

      {/* delete taxonomy modal */}
      <DeleteModal
        isLoading={taxonomyDeleteLoading}
        isModalOpen={deleteModal}
        handleCancel={() => setIsDeleteModal(false)}
        handleSubmit={onRemove}
        message="Are you sure you want to delete this taxonomy?"
        title="Delete Taxonomy"
      />
    </div>
  );
};

export default TaxonomyManagement;
