import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Card } from "antd";

import {
  UnsavedWarn,
  Topic,
  IndustryFun,
  Agenda,
  Competitors,
} from "../../../components/generation/businessProposal";
import { RootState } from "../../../store";
import { IMAGES } from "../../../shared";
import "./BusinessProposal.scss";
import "../Generation.scss";

const GenerationBusinessProposalPage = () => {
  const navigate = useNavigate();
  const { isShow, fields } = useSelector((state: RootState) => state.busProposalSlice);
  console.log("BusProps fields  ===", fields);

  return (
    <>
      <div className="generation-page">
        <div className="container">
          <Card className="page-card-title">
            <div className="page-title-left title-back-iconWrap">
              <img src={IMAGES.backIcon} alt="back" onClick={() => navigate(-1)} />
              <h1>Generate Business Proposal</h1>
            </div>
          </Card>

          <div className="card-scroll">
            <Topic />
            {isShow.industryFun && <IndustryFun />}
            {isShow.agenda && <Agenda />}
            {isShow.competitors && <Competitors />}
          </div>
        </div>
      </div>

      <UnsavedWarn />
    </>
  );
};

export default GenerationBusinessProposalPage;
