import { Button, Card, Select, SelectProps, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  generationFields,
  setUpdateFields,
  setUpdateIsDisable,
} from "../../../store/generation/businessProposal/businessProposalSlice";
import { IMAGES } from "../../../shared";
import { toast } from "react-toastify";

const Competitors = () => {
  const dispatch = useDispatch();
  const { isLoading, isDisable, fields } = useSelector(
    (state: RootState) => state.busProposalSlice
  );

  const options: SelectProps["options"] = [
    {
      value: "provide1",
      label: "Provide",
    },
    {
      value: "provide2",
      label: "Provide 2",
    },
    {
      value: "provide3",
      label: "Provide 3",
    },
    {
      value: "provide4",
      label: "Provide 4",
    },
    {
      value: "provide5",
      label: "Provide 5",
    },
  ];

  const handleNext = async () => {
    if (fields?.competitors && fields?.competitors?.length) {
      dispatch(setUpdateIsDisable({ field: generationFields.industryFun, value: true }));
      dispatch(setUpdateIsDisable({ field: generationFields.agenda, value: true }));
      dispatch(setUpdateIsDisable({ field: generationFields.competitors, value: true }));
    } else {
      toast.error("Please select competitors");
    }
  };

  const handleSelectCompetitors = (value: string[]) => {
    dispatch(setUpdateFields({ field: generationFields.competitors, value: value }));
  };

  return (
    <>
      <Card className="card-item">
        <div className="card-header">
          <span className="card-number">4</span>
          <h2>Provide Competitors</h2>
        </div>
        <div className="card-content">
          <div className="selects-wrap multiple-wrap">
            <Select
              className="select-dropdown-multiple"
              maxTagCount={8}
              mode="multiple"
              popupClassName="ui-dropdown-default"
              placeholder="Choose Competitors"
              options={options}
              value={fields?.competitors || []}
              disabled={isDisable?.competitors || isLoading?.competitors}
              onChange={handleSelectCompetitors}
              optionRender={(option) => <Space>{option.data.label}</Space>}></Select>
          </div>
        </div>

        <div className="card-footer acn-flex acn-flex-end">
          <div className="card-footer-right acn-flex acn-flex-middle">
            {isLoading?.competitors && (
              <div className="processing-wrap">
                <img src={IMAGES.loadingSpinner} alt="loading" className="spinnerSvg" />
                Processing
              </div>
            )}
            <Button
              type="primary"
              className="outline-btn"
              disabled={isDisable?.competitors || isLoading?.competitors}
              onClick={handleNext}>
              Export
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
};

export default Competitors;
