import { Dispatch, SetStateAction } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Card, Table } from "antd";

import SkeletonTable from "./SkeletonTable";
import { RootState } from "../../../store";
import { IFields } from "../../../store/generation/kpis/kpiGeneration.interface";
import {
  generationFields,
  setUpdateFields,
  setUpdateIsDisable,
  setUpdateIsShow,
} from "../../../store/generation/kpis/kpiGenerationSlice";
import { fetchKpiProcessByFunction } from "../../../services/generation";
import {
  functionsColumn,
  getFormattedFunction,
  getIsProcessing,
} from "../../../helper/GenerationHelper";
import { IMAGES } from "../../../shared";

interface IKpiFunctions {
  localFields: IFields;
  setLocalFields: Dispatch<SetStateAction<IFields>>;
}

const KpiFunctions = ({ localFields, setLocalFields }: IKpiFunctions) => {
  const dispatch = useDispatch();
  const { isDisable, isLoading, kpiGenerations, isAllLoading, isKpiDataLoading } = useSelector(
    (state: RootState) => state.kpiGeneration
  );
  const loading = isLoading?.functions || isAllLoading;

  const handleSelectFunctions = (keys: React.Key[], selectedRows: any[]) => {
    if (selectedRows && selectedRows?.length) {
      setLocalFields((field) => ({ ...field, functionsKeys: keys, functions: selectedRows }));
    } else {
      setLocalFields((field) => ({ ...field, functionsKeys: [], functions: [] }));
    }
  };

  const rowSelection = {
    columnTitle: () => <div style={{ visibility: "hidden" }}></div>,
    selectedRowKeys: localFields?.functionsKeys || [],
    onChange: handleSelectFunctions,
  };

  const handleFunctionsNext = async () => {
    if (
      kpiGenerations?.kpi_id &&
      localFields?.functionsKeys &&
      localFields?.functionsKeys?.length
    ) {
      const res = await fetchKpiProcessByFunction(
        kpiGenerations?.kpi_id,
        localFields?.functionsKeys
      );

      if (res) {
        dispatch(
          setUpdateFields({
            field: generationFields.functionsKeys,
            value: localFields?.functionsKeys,
          })
        );
        dispatch(
          setUpdateFields({
            field: generationFields.functions,
            value: localFields?.functions,
          })
        );

        dispatch(setUpdateIsShow({ field: generationFields.process, value: true }));
        dispatch(setUpdateIsDisable({ field: generationFields.tags, value: true }));
        dispatch(setUpdateIsDisable({ field: generationFields.functions, value: true }));
      }
    } else {
      toast.error("Please select functions");
    }
  };

  return (
    <>
      <Card className="card-item">
        <div className="card-header">
          <span className="card-number">2</span>
          <h2>Selected function(s)</h2>
        </div>

        <div className="card-content">
          {isKpiDataLoading ? (
            <SkeletonTable />
          ) : (
            <Table
              rowSelection={rowSelection}
              columns={functionsColumn}
              dataSource={getFormattedFunction(kpiGenerations?.functions || [])}
              pagination={false}
              rowClassName={isDisable?.functions || loading ? "disable-table-row" : ""}
            />
          )}
        </div>

        {!isKpiDataLoading && kpiGenerations?.functions && kpiGenerations?.functions?.length ? (
          <div className="card-footer acn-flex acn-flex-end">
            <div className="card-footer-right acn-flex acn-flex-middle">
              {(isLoading?.functions || getIsProcessing(kpiGenerations?.process_status)) && (
                <div className="processing-wrap">
                  <img src={IMAGES.loadingSpinner} alt="loading" className="spinnerSvg" />
                  Processing
                </div>
              )}
              <Button
                type="primary"
                className="outline-btn"
                disabled={isDisable?.functions || loading}
                onClick={handleFunctionsNext}>
                Next
              </Button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
};

export default KpiFunctions;
