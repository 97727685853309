import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import {
  IFields,
  IGenerationSliceProps,
  IIsDisable,
  IIsLoading,
  IIsShow,
  IKpiGenerations,
  IProcess,
  ISubProcess,
} from "./kpiGeneration.interface";

export const generationFields = {
  topic: "topic",
  tags: "tags",
  tagsKeys: "tagsKeys",
  functions: "functions",
  functionsKeys: "functionsKeys",
  process: "process",
  subProcess: "subProcess",
  subProcessKeys: "subProcessKeys",
  exportDoc: "exportDoc",
  exportDocKeys: "exportDocKeys",
};

const isShowInitValue: IIsShow = {
  topic: true,
  tags: false,
  functions: false,
  process: false,
  subProcess: false,
  exportDoc: false,
};

const isDisableInitValue: IIsDisable = {
  topic: false,
  tags: false,
  functions: false,
  process: false,
  subProcess: false,
  exportDoc: false,
};

const isLoadingInitValue: IIsLoading = {
  topic: false,
  tags: false,
  functions: false,
  process: false,
  subProcess: false,
  exportDoc: false,
};

export const fieldsInitValue: IFields = {
  topic: "",
  tags: [],
  tagsKeys: [],
  functions: [],
  functionsKeys: [],
  process: [],
  subProcess: [],
  exportDoc: [],
  exportDocKeys: [],
};

export const kpiGenerationsInitValue: IKpiGenerations = {
  kpi_id: null,
  prompt: "",
  document_name: null,
  document_pre_signed_url: null,

  tags: [],
  functions: [],
  processes: [],
  sub_processes: [],

  tags_status: "",
  function_status: "",
  process_status: "",
  sub_process_status: "",
  document_status: "",
};

const initialState: IGenerationSliceProps = {
  isShow: isShowInitValue,
  isDisable: isDisableInitValue,
  isLoading: isLoadingInitValue,
  fields: fieldsInitValue,
  processList: [],
  subProcessList: [],
  isAllLoading: true,
  isKpiDataLoading: false,
  kpiGenerations: kpiGenerationsInitValue,
};

export const kpiGenerationSlice = createSlice({
  name: "kpiGenerationSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setUpdateIsShow: (state, action: PayloadAction<{ field: string; value: boolean }>) => {
      state.isShow = {
        ...state.isShow,
        [action.payload.field]: action.payload.value,
      };
    },
    clearIsShow: (state) => {
      state.isShow = isShowInitValue;
    },

    setUpdateIsDisable: (state, action: PayloadAction<{ field: string; value: boolean }>) => {
      state.isDisable = {
        ...state.isDisable,
        [action.payload.field]: action.payload.value,
      };
    },
    clearIsDisable: (state) => {
      state.isDisable = isDisableInitValue;
    },

    setUpdateIsLoading: (state, action: PayloadAction<{ field: string; value: boolean }>) => {
      state.isLoading = {
        ...state.isLoading,
        [action.payload.field]: action.payload.value,
      };
    },
    clearIsLoading: (state) => {
      state.isLoading = isLoadingInitValue;
    },

    setUpdateFields: (
      state,
      action: PayloadAction<{ field: string; value: string | React.Key[] | any[] }>
    ) => {
      state.fields = {
        ...state.fields,
        [action.payload.field]: action.payload.value,
      };
    },
    clearFields: (state) => {
      state.fields = fieldsInitValue;
    },

    clearAllData: (state) => {
      state.isShow = isShowInitValue;
      state.isDisable = isDisableInitValue;
      state.isLoading = isLoadingInitValue;
      state.fields = fieldsInitValue;
      state.kpiGenerations = kpiGenerationsInitValue;
    },
    clearDataOnTopicStart: (state) => {
      state.isShow = isShowInitValue;
      state.isDisable = isDisableInitValue;
      state.isLoading = isLoadingInitValue;
      state.fields = { ...fieldsInitValue, topic: state?.fields?.topic };
      state.kpiGenerations = {
        ...kpiGenerationsInitValue,
        kpi_id: state?.kpiGenerations?.kpi_id,
        prompt: state?.kpiGenerations?.prompt,
      };
    },

    setProcessList: (state, action: PayloadAction<IProcess[]>) => {
      state.processList = action.payload;
    },
    setSubProcessList: (state, action: PayloadAction<ISubProcess[]>) => {
      state.subProcessList = action.payload;
    },

    setIsAllLoading: (state, action: PayloadAction<boolean>) => {
      state.isAllLoading = action.payload;
    },
    setIsKpiDataLoading: (state, action: PayloadAction<boolean>) => {
      state.isKpiDataLoading = action.payload;
    },
    setKpiGenerations: (state, action: PayloadAction<any>) => {
      state.kpiGenerations = action.payload;
    },
  },
});

export const {
  setUpdateIsShow,
  clearIsShow,
  setUpdateIsDisable,
  clearIsDisable,
  setUpdateIsLoading,
  clearIsLoading,
  setUpdateFields,
  clearFields,
  clearAllData,
  clearDataOnTopicStart,
  setProcessList,
  setSubProcessList,
  setIsAllLoading,
  setIsKpiDataLoading,
  setKpiGenerations,
} = kpiGenerationSlice.actions;

export default kpiGenerationSlice.reducer;
