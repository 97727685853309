import { useEffect, useState } from "react";
import "./ContentManagement.scss";
import {
  Button,
  Dropdown,
  MenuProps,
  Space,
  Table,
  Switch,
  Select,
  Card,
  Col,
  Row,
  Typography,
  Input,
  Skeleton,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  IMAGES,
  PATHS,
  ContentManage,
  TaxonomyTitle,
  useDebounce,
  AssetUploadStatus,
  PageLimit,
  CustomPagination,
  PublishedOption,
  getLocalStorageItem,
  UserRoles,
  getUserRole,
  getIsShowRunVision,
  LocalStorage,
} from "../../../shared";
import { UploadAssets, DeleteModal } from "../../components";
import { RootState } from "../../../store";
import {
  IContent,
  IFormattedIndustry,
  ITagsByAssestId,
} from "../../../store/contentManagement/content.interface";
import {
  setUpdatePublishDraft,
  setContentById,
  contentByIdInitialValue,
  setSelectedAllTagsByAssestId,
  tagsByAssestIdInitValue,
  setUpdateContentFilter,
  contentFilterField,
  updateAssetByAssetId,
  setAllContentDetails,
} from "../../../store/contentManagement/contentSlice";
import {
  getAllContent,
  enablePublish,
  disablePublish,
  deleteAssetById,
  getContentExportCSV,
  getDownloadLimit,
  updateDownloadLimit,
  runVision,
  getAssetDetailsByAssetId,
  stopProcessing,
} from "../../../services/contentManagement";
import SummaryManagement from "./SummaryManagement";
import { ITaxonomyTree } from "../../../store/taxonomy/taxonomy.interface";
import { RightOutlined } from "@ant-design/icons";
import { getTaxonomyByTitle } from "../../../services/taxonomy";
import { SelectedTagModal } from "../../components/viewEditContentManagement";
import SkeletonTable from "../userManagement/SkeletonTable";
import { toast } from "react-toastify";

const ContentManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = Number(searchParams.get("page"));
  const searchTextParam = searchParams.get("searchText") || "";

  const [isOpenUploadAsset, setIsOpenUploadAsset] = useState<boolean>(false);
  const {
    contents,
    isContentTableLoading,
    totalContentsCount,
    contentDeleteLoading,
    contentFilter,
    isExportCSVLoading,
    downloadLimit,
    isFetchDownloadLimit,
    isSaveDownloadLimit,
  } = useSelector((state: RootState) => state.content);

  const currentPage = contentFilter?.currentPage ?? 1;
  const publishedFilter = contentFilter?.publishedFilter ?? "ALL";
  const searchText = contentFilter?.searchText ?? "";

  const [isSummaryModal, setIsSummaryModal] = useState<number>(-1);
  const [summaryDescription, setSummaryDescirption] = useState<string>("");
  const { assetTypeTaxonomy } = useSelector((state: RootState) => state.taxonomy);
  const [isShowAssetDeleteModal, setIsShowAssetDeleteModal] = useState<boolean>(false);
  const [selectedContent, setSelectedContent] = useState<IContent | null>(null);
  const [isTagsModal, setIsTagsModal] = useState<number>(-1);
  const [downloadLimitCount, setDownloadLimitCount] = useState(downloadLimit);
  const [isEnableDeleteLimit, setIsEnableDeleteLimit] = useState(false);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [pendingContents, setPendingContents] = useState<IContent[]>([]);

  const handleSetPendingContents = () => {
    if (!isContentTableLoading) {
      let filteredPendingContents: IContent[] = [];
      if (contents && contents.length) {
        contents.forEach((content) => {
          if (
            [
              String(AssetUploadStatus.PROCESSING),
              AssetUploadStatus.SENT_TO_LLM,
              AssetUploadStatus.UPLOADED_TO_DO,
              AssetUploadStatus.STOPPING,
            ].includes(content.assetStatus)
          ) {
            filteredPendingContents.push(content);
          }
        });
      }
      setPendingContents(filteredPendingContents);
    }
  };

  useEffect(() => {
    handleSetPendingContents();
  }, [currentPage, isContentTableLoading, contents]);

  useEffect(() => {
    let contentInterval: any;
    if (pendingContents?.length > 0) {
      contentInterval = setInterval(async () => {
        for (const file of pendingContents) {
          let res = await getAssetDetailsByAssetId(file.asset_id);
          if (
            (res?.asset_id && res?.assetStatus === AssetUploadStatus.COMPLETED) ||
            res.assetStatus === AssetUploadStatus.FAILED ||
            res.assetStatus === AssetUploadStatus.FAILED_IN_LLM ||
            res.assetStatus === AssetUploadStatus.STOPPED
          ) {
            dispatch(updateAssetByAssetId({ data: res, id: res.asset_id }));
            setPendingContents(pendingContents.filter((asset) => asset.asset_id !== res.asset_id));
          }
        }
      }, 10000);
    } else {
      clearInterval(contentInterval);
    }

    return () => {
      clearInterval(contentInterval);
    };
  }, [pendingContents]);

  useEffect(() => {
    const localStorageItem = getLocalStorageItem(LocalStorage.User);
    const details: string | undefined = localStorageItem !== null ? localStorageItem : undefined;
    let parsedDetails: any;
    if (details) {
      parsedDetails = JSON.parse(details);
      setUserRole(parsedDetails.userRole);
    }
    pageParam &&
      dispatch(
        setUpdateContentFilter({ field: contentFilterField?.currentPage, value: pageParam })
      );
  }, [pageParam]);

  useEffect(() => {
    dispatch(
      setUpdateContentFilter({ field: contentFilterField?.searchText, value: searchTextParam })
    );
  }, [searchTextParam]);

  useEffect(() => {
    const updatedPage = pageParam ? pageParam : currentPage;
    const updatedSearchText = searchTextParam ? searchTextParam : searchText;

    getTaxonomyByTitle(TaxonomyTitle.AssetType);
    getAllContent(updatedSearchText, updatedPage, publishedFilter);
    if (getUserRole() === UserRoles.Admin) {
      getDownloadLimit();
    }
  }, []);

  useEffect(() => {
    if (downloadLimit) {
      setDownloadLimitCount(downloadLimit);
    } else {
      setDownloadLimitCount(0);
    }
  }, [downloadLimit]);

  const handleViewEditContentNavigate = (assetId: number, isEditMode = false) => {
    dispatch(setContentById(contentByIdInitialValue));
    dispatch(setSelectedAllTagsByAssestId(tagsByAssestIdInitValue));
    dispatch(
      setUpdateContentFilter({ field: contentFilterField?.currentPage, value: currentPage })
    );

    navigate(`${PATHS.viewEditContentManagement}/${assetId}`, {
      state: { isEditMode },
    });
  };

  function findParentNodesByValue(value: number, mappedTaxonomyTree: any) {
    let parents: any[] = [];

    function traverse(node: ITaxonomyTree, parentPath: ITaxonomyTree[]) {
      if (node.taxonomyNodeId === value) {
        parents.push(parentPath);
      }
      if (node.children) {
        node.children.forEach((child: ITaxonomyTree) => {
          traverse(child, [...parentPath, node]);
        });
      }
    }

    for (let item of mappedTaxonomyTree) {
      traverse(item, []);
    }
    if (parents && parents.length) {
      parents = parents[0];
    }

    return parents;
  }

  const findNodeNameByValue = (data: ITaxonomyTree[], value: number): string => {
    for (const item of data) {
      if (item.taxonomyNodeId === value) {
        return item.taxonomyNodeName;
      }
      if (item.children) {
        const result = findNodeNameByValue(item.children, value);
        if (result) {
          return result;
        }
      }
    }
    return "";
  };

  const handleCloseTagModal = () => {
    setIsTagsModal(-1);
    setSelectedContent(null);
  };

  const renderTag = (linkedTags: ITagsByAssestId, content: IContent) => {
    const selectedIndustryIds = linkedTags?.linkedTaxonomyNodes?.Industry || [];
    const selectedFunctionIds = linkedTags?.linkedTaxonomyNodes?.Function || [];
    const ids = [...selectedIndustryIds, ...selectedFunctionIds];
    const formattedTags: IFormattedIndustry[] = [];

    if (ids && ids.length) {
      ids.forEach((value: number) => {
        const strValue = String(value);
        formattedTags.push({
          parents: findParentNodesByValue(value, linkedTags.taxonomyTree),
          value: strValue,
          id: strValue,
          title: findNodeNameByValue(linkedTags.taxonomyTree, value),
        });
      });
    }

    return (
      <>
        <span className="td-label">Tags</span>
        {formattedTags && formattedTags.length ? (
          formattedTags.slice(0, 1).map((industry: IFormattedIndustry, industryIdx: number) => {
            if (industry.parents && industry.parents.length) {
              if (industry.parents.length === 1) {
                return (
                  <div className="item-tags item-tags-ui" key={industryIdx}>
                    <Space size={[0, 8]} wrap className="tags-span">
                      {industry.parents.slice(0, 1).map((indParent: any, indParentIdx: number) => {
                        return (
                          <p key={indParentIdx} className="tags-p">
                            {indParent.taxonomyNodeName} {indParentIdx === 0 && <RightOutlined />}
                            {industry.title}
                          </p>
                        );
                      })}
                      <span
                        className="more-tag"
                        onClick={() => {
                          setSelectedContent(content);
                          setIsTagsModal(content?.asset_id);
                        }}>
                        +more
                      </span>
                      {content?.asset_id === isTagsModal && (
                        <SelectedTagModal
                          isOpen={content?.asset_id === isTagsModal}
                          setModalClose={handleCloseTagModal}
                          selectedContent={selectedContent}
                        />
                      )}
                    </Space>
                  </div>
                );
              } else {
                return (
                  <div className="item-tags item-tags-ui" key={industryIdx}>
                    <Space size={[0, 8]} wrap className="tags-span">
                      {industry.parents.slice(0, 2).map((indParent: any, indParentIdx: number) => {
                        return (
                          <p key={indParentIdx} className="tags-p">
                            {indParent.taxonomyNodeName} {indParentIdx === 0 && <RightOutlined />}
                          </p>
                        );
                      })}
                      <span
                        className="more-tag"
                        onClick={() => {
                          setSelectedContent(content);
                          setIsTagsModal(content?.asset_id);
                        }}>
                        +more
                      </span>
                      {content?.asset_id === isTagsModal && (
                        <SelectedTagModal
                          isOpen={content?.asset_id === isTagsModal}
                          setModalClose={handleCloseTagModal}
                          selectedContent={selectedContent}
                        />
                      )}

                      {/* <span
                        onClick={() =>
                          navigate(`${PATHS.viewEditContentManagement}/${content?.asset_id}`, {
                            state: {
                              isEditMode: true,
                            },
                          })
                        }>
                        <img className="tags-edit-icon" src={IMAGES.editIcon} alt="edit" />
                      </span> */}
                    </Space>
                  </div>
                );
              }
            } else {
              return (
                <div className="item-tags item-tags-ui" key={industryIdx}>
                  <Space size={[0, 8]} wrap className="tags-span">
                    {industry.title}
                    <span
                      className="more-tag"
                      onClick={() => {
                        setSelectedContent(content);
                        setIsTagsModal(content?.asset_id);
                      }}>
                      +more
                    </span>
                    {content?.asset_id === isTagsModal && (
                      <SelectedTagModal
                        isOpen={content?.asset_id === isTagsModal}
                        setModalClose={handleCloseTagModal}
                        selectedContent={selectedContent}
                      />
                    )}
                    {/* <span
                      onClick={() =>
                        navigate(`${PATHS.viewEditContentManagement}/${content?.asset_id}`, {
                          state: {
                            isEditMode: true,
                          },
                        })
                      }>
                      <img className="tags-edit-icon" src={IMAGES.editIcon} alt="edit" />
                    </span> */}
                  </Space>
                </div>
              );
            }
          })
        ) : (
          <></>
        )}
      </>
    );
  };

  const getAssetTypeSelectedLabel = (value: number | null) => {
    let label = "";
    if (value) {
      assetTypeTaxonomy.taxonomyTree.forEach((assetType) => {
        if (assetType.taxonomyNodeId === value) {
          label = assetType.taxonomyNodeName;
          return label;
        }
      });
    }
    return label;
  };

  const renderAssetType = (linkedTags: ITagsByAssestId) => {
    const ids = linkedTags?.linkedTaxonomyNodes?.["Asset Type"] || [];
    const id = ids && ids.length ? ids[0] : null;

    return (
      <>
        <span className="td-label">Asset type</span>
        {getAssetTypeSelectedLabel(id)}
      </>
    );
  };

  const handlePublishedValidation = (content: IContent) => {
    let flag = false;
    let messageList = [];
    const assetType = content?.linkedTags?.linkedTaxonomyNodes?.["Asset Type"] || [];

    if (!content?.title || content?.title.trim() === "") {
      messageList.push("Title");
      flag = true;
    }
    if (!content?.summary || content?.summary.trim() === "") {
      messageList.push("Summary");
      flag = true;
    }
    if (!assetType.length) {
      messageList.push("Asset type");
      flag = true;
    }

    if (messageList && messageList.length) {
      let message = "";
      messageList.forEach((item, index) => {
        message =
          message +
          `${
            index === 0 ? "" : index > 0 && index === messageList.length - 1 ? " and " : ", "
          }${item}`;
      });

      if (message) {
        message = message + ` ${messageList.length > 1 ? "inputs" : "input"} cannot be empty`;
        toast.error(message);
      }
    }

    return flag;
  };

  const renderAssetStatus = (isPublished: boolean | string, content: IContent, index: number) => {
    switch (content?.assetStatus) {
      case AssetUploadStatus.PROCESSING:
      case AssetUploadStatus.UPLOADED_TO_DO:
      default:
        return (
          <div className="inProgressStatus">
            <img src={IMAGES.loadingSpinner} alt="loading" className="spinnerSvg" />
            In processing
          </div>
        );

      case AssetUploadStatus.LOADED_INTO_LLM:
      case AssetUploadStatus.COMPLETED:
        return (
          <Switch
            value={["TRUE", true].includes(isPublished) ? true : false}
            onChange={() => {
              if (handlePublishedValidation(content)) return;

              const isPublishedValue = ["TRUE", true].includes(isPublished) ? true : false;
              if (!isPublishedValue) {
                enablePublish(content?.asset_id);
              } else {
                disablePublish(content?.asset_id);
              }
              dispatch(setUpdatePublishDraft(index));
            }}
          />
        );

      case AssetUploadStatus.FAILED:
      case AssetUploadStatus.FAILED_IN_LLM:
        return <div className="failedStatusText">Failed</div>;

      case AssetUploadStatus.STOPPING:
        return (
          <div className="inProgressStatus">
            <img src={IMAGES.loadingSpinner} alt="loading" className="spinnerSvg" />
            Process stopping
          </div>
        );
      case AssetUploadStatus.STOPPED:
        return <div className="failedStatusText">Stopped</div>;
    }
  };

  const getRunVisionLabel = (content: IContent) => {
    let label = "Run Vision";

    if (content.visionParsed) {
      if (
        [
          String(AssetUploadStatus.UPLOADED_TO_DO),
          AssetUploadStatus.PROCESSING,
          AssetUploadStatus.SENT_TO_LLM,
        ].includes(content.assetStatus)
      ) {
        label = "Run Vision Processing";
      } else if (content.assetStatus === AssetUploadStatus.COMPLETED) {
        label = "Run Vision Completed";
      } else if (content.assetStatus === AssetUploadStatus.FAILED) {
        label = "Run Vision Failed";
      }
    }
    return label;
  };

  const getIsDisableRunVision = (content: IContent) => {
    let isDisable = true;

    if (content.visionParsed === false && content.assetStatus === AssetUploadStatus.COMPLETED) {
      isDisable = false;
    }
    return isDisable;
  };

  const handleRunVision = async (content: IContent | null, index: number) => {
    if (content?.asset_id) {
      toast.success("Vision is running");
      const res = await runVision(content?.asset_id);

      if (res?.data?.visionParsed) {
        const updatedContent = {
          ...content,
          assetStatus: AssetUploadStatus.SENT_TO_LLM,
          visionParsed: true,
        };
        const contentsValues = [...contents];
        contentsValues[index] = updatedContent;
        dispatch(setAllContentDetails(contentsValues));
      }
    }
  };

  const handleStopProcessing = async (content: IContent | null, index: number) => {
    if (content?.asset_id) {
      const res = await stopProcessing(content?.asset_id);

      if (res?.data) {
        const updatedContent = {
          ...content,
          assetStatus: AssetUploadStatus.STOPPING,
        };
        const contentsValues = [...contents];
        contentsValues[index] = updatedContent;
        dispatch(setAllContentDetails(contentsValues));
      }
    }
  };

  const getIsShowStopBtn = (content: IContent) =>
    [
      String(AssetUploadStatus.UPLOADED_TO_DO),
      AssetUploadStatus.PROCESSING,
      AssetUploadStatus.SENT_TO_LLM,
      AssetUploadStatus.CONVERTED_TO_PDF,
    ].includes(content.assetStatus);

  const getStopBtnLabel = (content: IContent) => {
    let label = "";

    if (
      [
        String(AssetUploadStatus.UPLOADED_TO_DO),
        AssetUploadStatus.PROCESSING,
        AssetUploadStatus.SENT_TO_LLM,
        AssetUploadStatus.CONVERTED_TO_PDF,
      ].includes(content.assetStatus)
    ) {
      label = "Stop";
    } else if (AssetUploadStatus.STOPPING === content.assetStatus) {
      label = "Stopping";
    }

    return label;
  };

  const getIsDisableStopBtn = (content: IContent) =>
    AssetUploadStatus.STOPPING === content.assetStatus;

  const getIsDisableEditContent = (content: IContent) =>
    [
      String(AssetUploadStatus.STOPPING),
      AssetUploadStatus.STOPPED,
      AssetUploadStatus.FAILED,
    ].includes(content.assetStatus);

  const getItems = (content: IContent, index: number) => {
    let items: MenuProps["items"] = [];
    const isDisable = getIsDisableRunVision(content);
    const label = getRunVisionLabel(content);
    const isShowRunVision = getIsShowRunVision(content?.original_file_name || "");

    if (isShowRunVision) {
      items = [
        {
          key: "1",
          label: (
            <div
              onClick={() => {
                handleRunVision(selectedContent, index);
              }}>
              {label}
            </div>
          ),
          className: isDisable ? "run-vision-disable" : "",
        },
        {
          key: "2",
          label: (
            <div
              onClick={() => {
                handleViewEditContentNavigate(selectedContent?.asset_id || 0, true);
              }}>
              Edit
            </div>
          ),
          className: getIsDisableEditContent(content) ? "run-vision-disable" : "",
        },
        {
          key: "3",
          label: (
            <div
              onClick={() => {
                dispatch(
                  setUpdateContentFilter({
                    field: contentFilterField?.currentPage,
                    value: currentPage,
                  })
                );
                if (getIsShowStopBtn(content)) {
                  handleStopProcessing(selectedContent, index);
                } else {
                  setIsShowAssetDeleteModal(true);
                }
              }}
              className="taxonomyDeleteText">
              {getStopBtnLabel(content) ? getStopBtnLabel(content) : "Delete"}
            </div>
          ),
          className: getIsDisableStopBtn(content) ? "run-vision-disable" : "",
        },
      ];
    } else {
      items = [
        {
          key: "1",
          label: (
            <div
              onClick={() => {
                handleViewEditContentNavigate(selectedContent?.asset_id || 0, true);
              }}>
              Edit
            </div>
          ),
          className: getIsDisableEditContent(content) ? "run-vision-disable" : "",
        },
        {
          key: "2",
          label: (
            <div
              onClick={() => {
                dispatch(
                  setUpdateContentFilter({
                    field: contentFilterField?.currentPage,
                    value: currentPage,
                  })
                );
                if (getIsShowStopBtn(content)) {
                  handleStopProcessing(selectedContent, index);
                } else {
                  setIsShowAssetDeleteModal(true);
                }
              }}
              className="taxonomyDeleteText">
              {getStopBtnLabel(content) ? getStopBtnLabel(content) : "Delete"}
            </div>
          ),
          className: getIsDisableStopBtn(content) ? "run-vision-disable" : "",
        },
      ];
    }

    return items;
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "200px",
      render: (text: string, content: IContent) => (
        <>
          <span className="td-label">Title : </span>
          <strong
            className={`${getIsDisableEditContent(content) ? "disable-edit" : "cursor-pointer"} `}
            onClick={() => {
              handleViewEditContentNavigate(content?.asset_id);
            }}>
            {text}
          </strong>
        </>
      ),
    },
    {
      title: "Asset type",
      dataIndex: "linkedTags",
      key: "linkedTags",
      width: "130px",
      render: (linkedTags: ITagsByAssestId) => renderAssetType(linkedTags),
    },
    {
      title: "Summary",
      dataIndex: "summary",
      key: "summary",
      width: "345px",
      render: (text: string, content: IContent) => (
        <>
          <span className="td-label">Summary : </span>
          <span className="summary-span">
            {text?.length > ContentManage.InitSummaryLength && !content?.isShowFullSummary ? (
              <span className="content-manage-inner-span acn-flex  acn-flex-middle">
                <div className="content-manage-span">
                  {text?.slice(0, ContentManage.InitSummaryLength)}
                </div>
                <span
                  className="more"
                  onClick={() => {
                    setSummaryDescirption(content?.summary);
                    setIsSummaryModal(content?.asset_id);
                  }}>
                  +more
                </span>

                <SummaryManagement
                  isOpen={content?.asset_id === isSummaryModal}
                  description={summaryDescription}
                  setModalClose={handleSummaryModal}
                />
              </span>
            ) : (
              <span>{text}</span>
            )}
          </span>
        </>
      ),
    },
    {
      title: "Tags",
      dataIndex: "linkedTags",
      key: "linkedTags",
      width: "35%",
      render: (linkedTags: ITagsByAssestId, content: IContent) => renderTag(linkedTags, content),
    },
    {
      title: "Published/Draft",
      dataIndex: "isPublished",
      key: "isPublished",
      width: "130px",
      render: (isPublished: boolean | string, content: IContent, index: number) => (
        <>
          <span className="td-label">Published/Draft : </span>
          <span className="published-draft-label acn-flex acn-flex-middle acn-flex-between">
            {renderAssetStatus(isPublished, content, index)}
          </span>
        </>
      ),
    },

    {
      title: "Action",
      key: "action",
      width: "80px",
      render: (_: any, content: IContent, index: number) => {
        return (
          <>
            <span className="td-label">Action : </span>
            <div className="actions-icons-wrap">
              <Dropdown
                trigger={["click"]}
                menu={{
                  onClick: () => {
                    setSelectedContent(content);
                  },
                  items: getItems(content, index),
                }}
                overlayClassName="table-action-dropdown table-action-dropdown-user content-dropdown"
                placement="bottomRight">
                <Button className="action-bta" onClick={() => setSelectedContent(content)}>
                  <img src={IMAGES.dotsIcon} alt="add" className="dots-icon" />
                </Button>
              </Dropdown>
              <a className={`${getIsDisableEditContent(content) ? "disable-edit" : "arrow-link"} `}>
                <img
                  src={IMAGES.arrowIcon}
                  alt="arrowRightIcon"
                  onClick={() => {
                    handleViewEditContentNavigate(content?.asset_id);
                  }}
                />
              </a>
            </div>
          </>
        );
      },
    },
  ];

  const handleSummaryModal = () => {
    setIsSummaryModal(-1);
    setSummaryDescirption("");
  };

  const getContentsBySearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedSearchText = e.target.value.trim();
    if (trimmedSearchText.length >= 3) {
      getAllContent(trimmedSearchText, 1, publishedFilter);
    }
    if (e.target.value.length === 0) {
      getAllContent("", 1, publishedFilter);
    }
  };

  const handleSearchDebounce = useDebounce(getContentsBySearch, 500);

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedSearchText = e.target.value.trim();
    handleSearchDebounce(e);
    dispatch(
      setUpdateContentFilter({
        field: contentFilterField?.searchText,
        value: e.target.value,
      })
    );
    dispatch(setUpdateContentFilter({ field: contentFilterField?.currentPage, value: 1 }));
    handleSetSearchParams(String(1), trimmedSearchText);
  };

  const handlePagination = (page: number) => {
    dispatch(setUpdateContentFilter({ field: contentFilterField?.currentPage, value: page }));
    getAllContent(searchText, page, publishedFilter);
    handleSetSearchParams(String(page), searchText);
  };

  const handleUpdatePageOnDelete = () => {
    if (totalContentsCount % PageLimit.ContentManagement === 1) {
      handleSetSearchParams(String(currentPage - 1), searchText);
    }
  };

  const handleDeleteContent = async () => {
    if (selectedContent?.asset_id) {
      const res: any = await deleteAssetById(
        selectedContent?.asset_id,
        searchText,
        currentPage,
        publishedFilter,
        totalContentsCount,
        true
      );
      if (res?.data) {
        handleUpdatePageOnDelete();
      }
    }
    setIsShowAssetDeleteModal(false);
    setSelectedContent(null);
    setIsShowAssetDeleteModal(false);
    dispatch(
      setUpdateContentFilter({ field: contentFilterField?.currentPage, value: currentPage })
    );
  };

  const handlePublishedChange = (value: string) => {
    dispatch(setUpdateContentFilter({ field: contentFilterField?.publishedFilter, value }));
    getAllContent(searchText, 1, value);
    dispatch(setUpdateContentFilter({ field: contentFilterField?.currentPage, value: 1 }));
    handleSetSearchParams(String(1), searchText);
  };

  const handleSetSearchParams = (page: string, searchText: string) => {
    setSearchParams({ page, searchText });
  };

  const handleExportCSV = async () => {
    await getContentExportCSV();
  };

  const handleOnCancelDeleteLimit = () => {
    setIsEnableDeleteLimit(false);
    setDownloadLimitCount(downloadLimit);
  };

  const handleSaveChanges = async () => {
    await updateDownloadLimit(downloadLimitCount);
    setIsEnableDeleteLimit(false);
  };

  return (
    <div className="page-content top-container user-management-page content-management-page">
      <Row>
        <Col className="page-title-card" span={24}>
          <Card>
            <div className="page-title-box acn-flex acn-flex-middle acn-flex-between">
              <div className="page-title-left">
                <Typography className="card-heading">Content Management</Typography>
              </div>

              <div className="page-title-right acn-flex acn-flex-middle acn-flex-between both-btn">
                <div>
                  <Select
                    popupClassName="ui-dropdown-default"
                    value={publishedFilter ? publishedFilter : PublishedOption[0].value}
                    placeholder="Select published"
                    options={PublishedOption}
                    onChange={handlePublishedChange}
                  />
                </div>

                <div className="search-form">
                  <Input
                    className="search-field"
                    placeholder="Search assets…"
                    onChange={(e) => {
                      handleChangeSearch(e);
                    }}
                    defaultValue={searchText}
                    value={searchText}
                    allowClear
                    prefix={
                      <img
                        src={IMAGES.searchIcon}
                        alt="search"
                        width={15}
                        height={15}
                        className="search-icon"
                      />
                    }
                  />
                </div>

                {userRole === UserRoles.Admin && (
                  <div className="btn-link">
                    <Button
                      className="add-user-button acn-flex acn-flex-middle"
                      loading={isExportCSVLoading}
                      onClick={handleExportCSV}>
                      <span className="edit-icon acn-flex acn-flex-middle acn-flex-center">
                        <img src={IMAGES.exportIcon} alt="add" />
                      </span>
                      Export CSV
                    </Button>
                  </div>
                )}
                {userRole === UserRoles.Admin && (
                  <Button
                    className="add-user-button acn-flex acn-flex-middle upload-asset-btn"
                    onClick={() => setIsOpenUploadAsset(true)}>
                    <span className="edit-icon acn-flex acn-flex-middle acn-flex-center">
                      <img src={IMAGES.uploadAssetIcon} alt="Upload Icon" />
                    </span>
                    Upload Asset
                  </Button>
                )}
              </div>
            </div>
          </Card>
        </Col>

        <Col className="global-table-card table-ui" span={24}>
          <Card>
            {isContentTableLoading ? (
              <SkeletonTable />
            ) : (
              <Table
                className="global-table responsive-table"
                rowKey={"asset_id"}
                columns={columns}
                dataSource={contents?.length ? contents : []}
                tableLayout="fixed"
                scroll={{ y: "calc(100vh - 315px)" }}
                pagination={false}
              />
            )}
            <div
              className={
                userRole === UserRoles.Admin ? "global-table-footer-ui" : "acn-flex-right"
              }>
              {isFetchDownloadLimit ? (
                <>
                  <Skeleton.Input active />
                  <Skeleton.Button active />
                </>
              ) : userRole === UserRoles.Admin && contents?.length ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}>
                  <h4>Download Limit</h4>
                  <Input
                    type="number"
                    value={downloadLimitCount}
                    onChange={(e) => {
                      setDownloadLimitCount(Number(e.target.value));
                    }}
                    disabled={!isEnableDeleteLimit}
                  />
                  {isEnableDeleteLimit ? (
                    <>
                      <Button
                        className="add-user-button acn-flex acn-flex-middle"
                        onClick={handleOnCancelDeleteLimit}>
                        Cancel
                      </Button>
                      <Button
                        disabled={downloadLimitCount < 1}
                        className="add-user-button btn-fill acn-flex acn-flex-middle"
                        onClick={handleSaveChanges}
                        loading={isSaveDownloadLimit}>
                        Save
                      </Button>
                    </>
                  ) : (
                    <Button
                      className="add-user-button acn-flex acn-flex-middle"
                      onClick={() => {
                        setIsEnableDeleteLimit(true);
                      }}>
                      <span className="edit-icon acn-flex acn-flex-middle acn-flex-center">
                        <img src={IMAGES.editIcon} alt="add" />
                      </span>
                      Edit
                    </Button>
                  )}
                </div>
              ) : (
                <></>
              )}

              {contents && contents.length ? (
                <CustomPagination
                  currentPage={currentPage}
                  total={totalContentsCount}
                  handlePagination={handlePagination}
                  pageSize={PageLimit.ContentManagement}
                />
              ) : (
                <></>
              )}
            </div>
          </Card>
        </Col>
      </Row>

      <UploadAssets isModalOpen={isOpenUploadAsset} setIsModalOpen={setIsOpenUploadAsset} />

      {/* delete Content modal */}
      <DeleteModal
        isLoading={contentDeleteLoading}
        isModalOpen={isShowAssetDeleteModal}
        handleCancel={() => setIsShowAssetDeleteModal(false)}
        handleSubmit={handleDeleteContent}
        message="Are you sure you want to delete this asset?"
        title="Delete Asset"
      />
    </div>
  );
};

export default ContentManagement;
