import { LeftOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Radio,
  Row,
  Select,
  Typography,
  Skeleton,
  Image,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { BrowseCategoryLevel } from "../../components";
import { getAssetsDataByIds } from "../../services/assets";
import { RootState } from "../../store";
import { setLinkedAssetsData } from "../../store/assets/assets.slice";
import "./BrowseCategory.scss";
import {
  FromWhere,
  IMAGES,
  PATHS,
  getFunctionsList,
  getIndustryList,
  handleOpenPdfDoc,
} from "../../shared";
import { ICurrentLevel, TFiltersType } from "../../store/filtersFromTaxonomyData/filters.interface";
import {
  setBrowseCategoryCurrentLevel,
  setBrowseCategorySelectedCategory,
  setBrowseCategorySelectedFunctionValue,
  setBrowseCategorySelectedIndustryValue,
  setBrowseCategorySelectedRadioOption,
} from "../../store/filtersFromTaxonomyData/filters.slice";
import { toast } from "react-toastify";

export interface Industry {
  value: string;
  label: string;
  key: string;
  children: any;
  taxonomyNodeId: number;
}
export interface ITaxonomyNode {
  taxonomyNodeId: number;
  taxonomyNodeName: string;
  description: string;
  children: ITaxonomyNode[];
  linkedAsset: number[];
  taxonomyNodeLevel: number | null;
}

const BrowseCategory = () => {
  const { linkedAssetsData, isLinkedAssetLoading } = useSelector(
    (state: RootState) => state.assets
  );
  const {
    browseCategoryCurrentLevel,
    browseCategoryFiltersData,
    browseCategorySelectedCategory,
    browseCategorySelectedRadioOption,
    browseCategorySelectedFunctionValue,
    browseCategorySelectedIndustryValue,
  } = useSelector((state: RootState) => state.filtersData);
  const tempArr = [1, 2, 3, 4, 5];

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { data, filterType } = location?.state;
  const currentLevelFromRoute = location?.state?.browseCategoryCurrLevel;
  const [selectedCategoryFromRoute, _] = useState(location?.state?.browseSelectedCategory);
  const [showSelectIndustry, setShowSelectIndustry] = useState<string>(
    browseCategorySelectedIndustryValue
  );
  const [showSelectFunctions, setShowSelectFunctions] = useState<string>(
    browseCategorySelectedFunctionValue
  );
  const [applyFiltersData, setApplyFiltersData] = useState<ITaxonomyNode | null>(null);
  const [selectedRadioOption, setSelectedRadioOption] = useState<string>(
    filterType ? filterType : browseCategorySelectedRadioOption
  );

  const [currentLevel, setCurrentLevel] = useState<ICurrentLevel[]>(
    browseCategoryCurrentLevel?.length
      ? browseCategoryCurrentLevel
      : currentLevelFromRoute ?? [
          {
            title: data.taxonomyNodeName,
            id: data?.taxonomyNodeId,
            linkedAsset: [],
          },
        ]
  );
  const [selectedCategory, setSelectedCategory] = useState<ITaxonomyNode>(
    selectedCategoryFromRoute ?? data
  );

  const [visible, setVisible] = useState(false);
  const [selectedImageURL, setSelectedImageURL] = useState<string | null>(null);
  const [scaleStep] = useState(0.5);

  const setSelectValue = () => {
    setShowSelectIndustry(browseCategorySelectedIndustryValue);
    setShowSelectFunctions(browseCategorySelectedFunctionValue);
  };

  useEffect(() => {
    setSelectValue();
  }, [
    browseCategorySelectedFunctionValue,
    browseCategorySelectedIndustryValue,
    browseCategorySelectedRadioOption,
  ]);

  useEffect(() => {
    if (browseCategorySelectedCategory?.taxonomyNodeId != -1) {
      setSelectedCategory(browseCategorySelectedCategory);
    }

    // Get linked assets
    const linkedAssetIds: number[] = selectedCategoryFromRoute?.linkedAsset || [];
    if (linkedAssetIds && linkedAssetIds.length) {
      getAssetsDataByIds(linkedAssetIds);
    } else {
      dispatch(setLinkedAssetsData([]));
    }

    // check if linked assets data is in redux or not
    if (browseCategoryCurrentLevel[browseCategoryCurrentLevel.length - 1]?.linkedAsset?.length) {
      getAssetsDataByIds(
        browseCategoryCurrentLevel[browseCategoryCurrentLevel.length - 1].linkedAsset
      );
    }
  }, []);

  const handleCategoryClick = (index: number) => {
    const data = [
      ...currentLevel,
      {
        title: selectedCategory?.children[index].taxonomyNodeName,
        id: selectedCategory?.children[index].taxonomyNodeId,
        linkedAsset: selectedCategory?.children[index]?.linkedAsset?.length
          ? selectedCategory?.children[index]?.linkedAsset
          : [],
      },
    ];
    setCurrentLevel(data);
    dispatch(setBrowseCategoryCurrentLevel(data));
    const linkedAsset = selectedCategory?.children[index]?.linkedAsset || [];
    if (
      (selectedCategory?.children[index].children?.length === 0 ||
        !selectedCategory?.children[index].children) &&
      linkedAsset &&
      linkedAsset.length
    ) {
      getAssetsDataByIds(linkedAsset);
    }

    setSelectedCategory(selectedCategory?.children[index] || []);
    dispatch(setBrowseCategorySelectedCategory(selectedCategory?.children[index] || [])); // store selected category data in redux
    // clearCurrLevelSelectedCategoryFromRoute();
  };

  const onHandleBreadcrumbSelection = (item: any, index: number) => {
    const findTaxonomyNodeById = (node: ITaxonomyNode, id: number): ITaxonomyNode | null => {
      if (node.taxonomyNodeId === id) {
        return node;
      }

      for (const child of node.children) {
        const foundNode = findTaxonomyNodeById(child, id);
        if (foundNode) {
          return foundNode;
        }
      }

      return null;
    };
    const currentNode = findTaxonomyNodeById(data, item?.id);
    currentNode && setSelectedCategory(currentNode);
    currentNode && dispatch(setBrowseCategorySelectedCategory(currentNode)); // store selected category data in redux

    dispatch(setLinkedAssetsData([]));
    dispatch(
      setBrowseCategoryCurrentLevel(browseCategoryCurrentLevel.slice(0, index + 1)) // store current level in redux
    );
    setCurrentLevel(currentLevel.slice(0, index + 1));
    clearCurrLevelSelectedCategoryFromRoute();
  };

  const handleNavigateViewContent = (assetId: number) => {
    navigate(`${PATHS.viewContentManagementByUser}/${assetId}`, {
      state: {
        fromWhere: FromWhere.BrowseCategory,
        browseCategoryCurrLevel: browseCategoryCurrentLevel,
        browseSelectedCategory: browseCategorySelectedCategory,
        browseData: data,
      },
    });
  };

  const clearCurrLevelSelectedCategoryFromRoute = () => {
    if ((currentLevelFromRoute && currentLevelFromRoute.length) || selectedCategoryFromRoute) {
      navigate(PATHS.browseCategory, {
        state: {
          data,
          browseCategoryCurrLevel: null,
          browseSelectedCategory: null,
        },
      });
    }
  };
  //
  const { industryTaxonomy, functionTaxonomy } = useSelector((state: RootState) => state.taxonomy);
  const [industryList, setIndustryList] = useState<Industry[]>([]);
  const [functionsList, setFunctionsList] = useState<Industry[]>([]);
  useEffect(() => {
    if (industryTaxonomy.taxonomyTree.length > 0) {
      setIndustryList(getIndustryList(industryTaxonomy.taxonomyTree));
    }
    if (functionTaxonomy.taxonomyTree.length > 0) {
      setFunctionsList(getFunctionsList(functionTaxonomy.taxonomyTree));
    }
  }, [industryTaxonomy, functionTaxonomy]);

  const handleChange = (_: any, data: any, type: string) => {
    if (type === TFiltersType.Industry) {
      setShowSelectIndustry(data?.taxonomyNodeId);
      setShowSelectFunctions("Select function");
    }
    if (type === TFiltersType.Functions) {
      setShowSelectFunctions(data?.taxonomyNodeId);
      setShowSelectIndustry("Select industry");
    }
    navigate(PATHS.browseCategory, { state: { data, filterType: type } });
    setApplyFiltersData(data);

    // setSelectedCategory(data);
    // dispatch(setBrowseCategoryFiltersData(data)); // store apply filters data in redux
    // setSelectValue();
  };

  const onApplyFiltersAction = () => {
    if (selectedRadioOption === TFiltersType.Industry && showSelectIndustry === "Select Industry") {
      toast.error("Please select an option");
      return;
    }
    if (
      selectedRadioOption === TFiltersType.Functions &&
      showSelectFunctions === "Select Function"
    ) {
      toast.error("Please select an option");
      return;
    }
    dispatch(setLinkedAssetsData([]));
    applyFiltersData &&
      setCurrentLevel([
        {
          title: applyFiltersData.taxonomyNodeName,
          id: applyFiltersData?.taxonomyNodeId,
          linkedAsset: [],
        },
      ]);
    applyFiltersData && setSelectedCategory(applyFiltersData);

    //////// -----------------  redux store ----------------------\\\\\\\\\\\
    if (selectedRadioOption === TFiltersType.Industry) {
      dispatch(setBrowseCategorySelectedFunctionValue("Select Function"));
      dispatch(setBrowseCategorySelectedIndustryValue(showSelectIndustry));
    } else {
      dispatch(setBrowseCategorySelectedFunctionValue(showSelectFunctions));
      dispatch(setBrowseCategorySelectedIndustryValue("Select Industry"));
    }

    dispatch(setBrowseCategorySelectedRadioOption(selectedRadioOption));
    browseCategoryFiltersData && // store current level data in redux
      dispatch(
        setBrowseCategoryCurrentLevel([
          {
            title: browseCategoryFiltersData.taxonomyNodeName,
            id: browseCategoryFiltersData?.taxonomyNodeId,
            linkedAsset: [],
          },
        ])
      );
    browseCategoryFiltersData &&
      dispatch(setBrowseCategorySelectedCategory(browseCategoryFiltersData));
  };

  return (
    <div className="content conversational-search-page sidebar-with-content">
      <div className="content-right">
        <div className="browseCategory">
          <Row>
            <Col hidden className="page-title-card" span={24}>
              <Card>
                <div className="page-title-box acn-flex acn-flex-middle acn-flex-between">
                  <Typography className="card-heading">
                    <div className="acn-flex">
                      <Button
                        className="back-arrow mr-5"
                        onClick={() => navigate(`${PATHS.browseProject}`)}>
                        <LeftOutlined />
                      </Button>
                      <span className="back-text">Process flow</span>
                    </div>
                  </Typography>
                </div>
              </Card>
            </Col>
            <Col className="page-filter-card  flow-card" span={24}>
              <Card>
                <Radio.Group
                  defaultValue={selectedRadioOption}
                  buttonStyle="solid"
                  className="filter-card acn-flex"
                  onChange={(e) => {
                    setSelectedRadioOption(e.target.value);
                  }}>
                  <div className="choose-select">
                    <Radio value={TFiltersType.Industry}></Radio>
                    <div className="choose-filter">
                      <label>Choose your Industry</label>
                      <Select
                        disabled={selectedRadioOption != TFiltersType.Industry}
                        popupClassName="ui-dropdown-default"
                        value={showSelectIndustry}
                        onChange={(e, i) => handleChange(e, i, TFiltersType.Industry)}
                        options={industryList}
                      />
                    </div>
                  </div>
                  <div className="divider-or">or</div>
                  <div className="choose-select">
                    <Radio value={TFiltersType.Functions}></Radio>
                    <div className="choose-filter">
                      <label>Choose your Function</label>

                      <Select
                        disabled={selectedRadioOption != TFiltersType.Functions}
                        popupClassName="ui-dropdown-default"
                        value={showSelectFunctions}
                        onChange={(e, i) => handleChange(e, i, TFiltersType.Functions)}
                        options={functionsList}
                      />
                    </div>
                  </div>
                  <Button
                    className="add-user-button acn-flex acn-flex-middle apply-btn"
                    onClick={onApplyFiltersAction}>
                    Apply
                  </Button>
                </Radio.Group>
              </Card>
            </Col>

            <Col className="flow-card" span={24}>
              <Card>
                <Breadcrumb separator={<img src={IMAGES.breadcrumbDevider} />}>
                  {currentLevel?.length &&
                    currentLevel.map((item, index) => (
                      <Breadcrumb.Item key={index}>
                        <span
                          className="breadcrumb-list"
                          onClick={() => onHandleBreadcrumbSelection(item, index)}
                          style={{
                            textDecoration: index === currentLevel.length - 1 ? "underline" : "",
                            pointerEvents: index === currentLevel.length - 1 ? "none" : "auto",
                          }}>
                          {item.title}
                        </span>
                      </Breadcrumb.Item>
                    ))}
                </Breadcrumb>
                {selectedCategory?.children && selectedCategory?.children.length ? (
                  <h4 className="card-heading">Select to proceed</h4>
                ) : (
                  <h4 className="card-heading"></h4>
                )}

                <Row className="select-row">
                  {selectedCategory?.children &&
                    selectedCategory?.children.length > 0 &&
                    selectedCategory.children.map((_item: any, _index: number) => {
                      return (
                        <BrowseCategoryLevel
                          item={_item}
                          key={_index}
                          handleCategoryClick={handleCategoryClick}
                          index={_index}
                        />
                      );
                    })}
                </Row>

                <div className="assets-found p-0">
                  {linkedAssetsData.length > 0 && (
                    <h4 className="showing-results">{`Showing ${linkedAssetsData.length} results`}</h4>
                  )}
                  <div className="inner-assets browse-category-inner-assets">
                    <Row gutter={[18, 26]}>
                      {isLinkedAssetLoading
                        ? tempArr.map((_) => (
                            <Col span={6}>
                              <Card className="skeleton-style">
                                <Skeleton.Image active={true} style={{ width: "100%" }} />
                                <Skeleton.Input
                                  style={{ height: 11, lineHeight: "12px" }}
                                  className="card-title"
                                  active
                                  size="small"
                                />
                              </Card>
                            </Col>
                          ))
                        : linkedAssetsData.map((item) => (
                            <Col span={6}>
                              <Card className="cursor-pointer">
                                <div
                                  onClick={() => handleNavigateViewContent(item?.asset_id)}
                                  className="thumbnail"
                                  style={{
                                    backgroundImage: `url(${item?.thumbnail_pre_signed_url})`,
                                  }}></div>

                                <span className="card-content title-container">
                                  <h3
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (["png", "jpg", "jpeg"].includes(item?.file_type || "")) {
                                        setVisible(true);
                                        setSelectedImageURL(item.pre_signed_url);
                                      } else {
                                        if (item.file_type === "xls" || item.file_type === "xlsx") {
                                          toast.error("Cannot open preview of this file");
                                        } else {
                                          handleOpenPdfDoc(
                                            item?.asset_id,
                                            item.file_name,
                                            item?.pre_signed_url,
                                            item?.title,
                                            1,
                                            true
                                          );
                                        }
                                      }
                                    }}>
                                    {item.title}
                                  </h3>

                                  <span
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleNavigateViewContent(item?.asset_id);
                                    }}>
                                    <img
                                      src={IMAGES.iconLink}
                                      alt="Icon Link"
                                      className="icon-link"
                                    />
                                  </span>
                                  {/* )} */}
                                </span>
                              </Card>
                            </Col>
                          ))}
                    </Row>
                  </div>
                </div>

                {!selectedCategory?.children?.length && !linkedAssetsData?.length ? (
                  <p className="no-records-found">Record not found</p>
                ) : (
                  <></>
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {visible && (
        <div style={{ display: "none" }}>
          <Image
            preview={{
              visible,
              scaleStep,
              src: `${selectedImageURL}`,
              onVisibleChange: (value) => {
                setVisible(value);
              },
            }}
          />
        </div>
      )}
    </div>
  );
};

export default BrowseCategory;
