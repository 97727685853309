import { Col, Row, Select } from "antd";
import "./BrowseProject.scss";
import { WheelChart } from "../../components";
import { useNavigate } from "react-router-dom";
import { PATHS, TaxonomyTitle } from "../../shared/constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { useEffect, useState } from "react";
import { getTaxonomyByTitle } from "../../services/taxonomy";
import { getFunctionsList, getIndustryList } from "../../shared";
import { Industry } from "../home/Home";
import { TFiltersType } from "../../store/filtersFromTaxonomyData/filters.interface";
import {
  setBrowseCategoryCurrentLevel,
  setBrowseCategoryFiltersData,
  setBrowseCategorySelectedCategory,
  setBrowseCategorySelectedFunctionValue,
  setBrowseCategorySelectedIndustryValue,
  setBrowseCategorySelectedRadioOption,
  taxonomyByInitValue,
} from "../../store/filtersFromTaxonomyData/filters.slice";


const BrowseProject = () => {
  const navigate = useNavigate();
  const { industryTaxonomy, functionTaxonomy } = useSelector((state: RootState) => state.taxonomy);

  const [industryList, setIndustryList] = useState<Array<Industry>>([]);
  const [functionsList, setFunctionsList] = useState<string[]>([]);
  const dispatch = useDispatch();
  useEffect(() => {
    getTaxonomyByTitle(TaxonomyTitle.Industry);
    getTaxonomyByTitle(TaxonomyTitle.Function);
    dispatch(setBrowseCategoryCurrentLevel([]));
    dispatch(setBrowseCategoryFiltersData(null));
    dispatch(setBrowseCategorySelectedCategory(taxonomyByInitValue));
  }, []);

  useEffect(() => {
    if (industryTaxonomy.taxonomyTree.length > 0) {
      setIndustryList(getIndustryList(industryTaxonomy.taxonomyTree));
    }
    if (functionTaxonomy.taxonomyTree.length > 0) {
      setFunctionsList(getFunctionsList(functionTaxonomy.taxonomyTree));
    }
  }, [industryTaxonomy, functionTaxonomy]);

  const handleChange = (_: string, data: any) => {
    // setSelectedIndustryData(data);
    const body = {
      taxonomyNodeId: data.taxonomyNodeId,
      taxonomyNodeLevel: 0,
      taxonomyNodeName: data.label,
      children: data.children,
    };
    dispatch(setBrowseCategorySelectedIndustryValue(body.taxonomyNodeName))
    dispatch(setBrowseCategorySelectedFunctionValue("Select Function"));
    dispatch(setBrowseCategorySelectedRadioOption(TFiltersType.Industry))
    navigate(PATHS.browseCategory, { state: { data: body, filterType: TFiltersType.Industry } });
  };
  return (
    <>
      <div className="project-dragonfly">
        <div className="project-dragonfly-inner container">
          <Row>
            <Col span={12}>
              <div className="card-center">
                <div className="dragonfly-inner">
                  <h1 className="title">
                    PROJECT <span className="dark-purple">वृद्धि</span>
                    <span className="sub-title">प्रगति और परिवर्तन​</span>
                  </h1>
                  <p>
                    We believe that companies aiming for growth and transformation need to work
                    across business functions at an Enterprise level to achieve their true
                    potential. We work across business functions to drive high octane growth &
                    transformation for our clients. To know more chose your industry:
                  </p>
                  <div className="choose-industrys">
                    <Select
                      popupClassName="ui-dropdown-default"
                      placeholder="Select Industry"
                      onChange={(e, i) => handleChange(e, i)}
                      options={industryList}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="wheels-chart">
                <div className="card-center">
                  <div className="wheels-chart-inner">
                    <WheelChart data={functionsList} />
                    <h5 className="function-proceed">Select Function to proceed</h5>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default BrowseProject;
