import { Button, Input, Modal } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "../../../store";
import "./UserComments.scss";
import { IMessages } from "../../../store/conversationalSearch/conversationalSearch.interface";
import { setChatAction } from "../../../store/conversationalSearch/conversationalSearchSlice";
import { messageComment } from "../../../services/conversationalSearch";

interface IUserCpmmentModalProps {
  visible: boolean;
  handleCancel: () => void;
  message: IMessages | undefined;
}
const UserComments = ({ visible, message, handleCancel }: IUserCpmmentModalProps) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { selectedChatHistory } = useSelector((state: RootState) => state.conversationalSearch);

  const submitFeedback = async () => {
    try {
      setIsLoading(true);
      if (message) {
        dispatch(
          setChatAction({
            ...message,
            userComments: comment ? comment : "",
            thumbsUp: false,
            thumbsDown: true,
          })
        );
        const request = {
          chatId: selectedChatHistory.id,
          answerId: message.answerId,
          userComments: comment ? comment : "",
          thumbsUp: false,
          thumbsDown: true,
        };
        const response = await messageComment(request);
        if (response && response.status === 200) {
          setComment("");
          toast.success("Feedback sent.");
          handleCancel();
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("Error while message action", error);
    }
  };
  return (
    <Modal
      className="delete-modal"
      title="Feedback"
      open={visible}
      closeIcon={false}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          disabled={!comment.trim().length}
          loading={isLoading}
          key="submit"
          type="primary"
          onClick={() => {
            submitFeedback();
          }}>
          Submit
        </Button>,
      ]}>
      <div className="message-comment-container">
        <Input.TextArea
          rows={3}
          placeholder="Feedback"
          value={comment}
          onChange={(e) => {
            setComment(e.target.value);
          }}
        />
      </div>
    </Modal>
  );
};

export default UserComments;
