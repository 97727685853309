import { Button, Modal, Tooltip } from "antd";
import { GlobalWorkerOptions } from "pdfjs-dist";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import "./PdfViewer.scss";
import {
  LeftOutlined,
  DoubleLeftOutlined,
  RightOutlined,
  DoubleRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setIsPdfShow, setPDFData, setPdfDetails } from "../../store/pdfViewer/pdfViewer.slice";
import { IMAGES, PATHS } from "../../shared";
import { useNavigate } from "react-router-dom";
import { searchPlugin } from "@react-pdf-viewer/search";
import jumpToPagePlugin from "./jumpToPagePlugin";
import "@react-pdf-viewer/search/lib/styles/index.css";
import { getPDFPreSignedUrl } from "../../services/conversationalSearch";
import LoadingPdf from "./LoadingPdf";

GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@3.9.179/build/pdf.worker.min.js`;

const PDFViewer = () => {
  const [url, setUrl] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isShowPDF, pdfData, pdfDetails } = useSelector((state: RootState) => state.pdfViewer);
  const zoomPluginInstance = zoomPlugin();

  const toolbarPluginInstance = toolbarPlugin();

  const pageNavigationPluginInstance = pageNavigationPlugin();

  const { CurrentPageLabel, GoToFirstPage, GoToLastPage, GoToNextPage, GoToPreviousPage } =
    pageNavigationPluginInstance;

  const { ZoomIn, ZoomOut, CurrentScale } = zoomPluginInstance;
  const jumpToPagePluginInstance = jumpToPagePlugin();
  const searchPluginInstance = searchPlugin();
  const { jumpToPage } = jumpToPagePluginInstance;
  const { setTargetPages } = searchPluginInstance; // highlight need to add here
  const { page } = pdfDetails;

  useEffect(() => {
    setTargetPages((targetPage) => targetPage.pageIndex === page);
    handleJumpToPage();
  }, [page]);

  useEffect(() => {
    if (pdfDetails?.fileName && pdfData && Object.keys(pdfData).includes(pdfDetails?.fileName)) {
      if (url !== pdfData[pdfDetails?.fileName]) {
        setUrl(pdfData[pdfDetails?.fileName]);
        setIsError(false);
      }
    } else {
      fetchURL();
    }
  }, []);

  const fetchURL = async () => {
    try {
      setIsLoading(true);
      const res = await getPDFPreSignedUrl(pdfDetails.fileName);
      const response = await fetch(res);

      if (response?.status === 200) {
        setIsError(false);
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data: any = reader.result;
          dispatch(
            setPDFData({
              fileName: pdfDetails?.fileName,
              base64Data: base64data,
            })
          );
          setUrl(base64data);
        };
        reader.readAsDataURL(blob);
      } else {
        setIsError(true);
        setUrl("");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("Error while fetching url", error);
    }
  };
  const openLinksInNewTab = () => {
    const containers = document.querySelectorAll(".rpv-core__annotation--link");
    containers?.forEach((container) => {
      const links = container?.querySelectorAll("a");

      links?.forEach((link) => {
        link.addEventListener("click", (e) => {
          e.preventDefault();
          const url = link.getAttribute("href");
          if (url) {
            window.open(url, "_blank");
          }
        });
      });
    });
  };

  const handleJumpToPage = () => {
    jumpToPage(page === 0 ? 2 : page);
  };

  const handleClosePDF = () => {
    dispatch(setIsPdfShow(false));
    dispatch(
      setPdfDetails({
        assetId: -1,
        fileName: "",
        presignedUrl: "",
        title: "",
        page: 0,
        showOpenIcon: false,
      })
    );
  };

  return (
    <Modal
      centered
      className="pdf-file-viewer"
      closable={true}
      closeIcon={true}
      // size="large"
      open={isShowPDF}
      onCancel={handleClosePDF}
      footer={null}>
      <div className="pdfHeaderContainer">
        <div className="pdfHeaderLeft">
          <h3>{pdfDetails.title}</h3>
        </div>
        <div className="pdfHeaderCenter">
          <div className="pdfHeaderSubContainer">
            <GoToFirstPage>
              {(props) => (
                <Button
                  className="buttonCircle"
                  onClick={props.onClick}
                  disabled={props.isDisabled}
                  type="text"
                  shape="circle">
                  <DoubleLeftOutlined />
                </Button>
              )}
            </GoToFirstPage>
            <GoToPreviousPage>
              {(props) => (
                <Button
                  className="buttonCircle"
                  onClick={props.onClick}
                  disabled={props.isDisabled}
                  type="text"
                  shape="circle">
                  <LeftOutlined />
                </Button>
              )}
            </GoToPreviousPage>
            <CurrentPageLabel>
              {(props) => (
                <p className="pdfPageNumberText">
                  {`Page ${props.currentPage + 1} of ${props.numberOfPages}`}
                </p>
              )}
            </CurrentPageLabel>
            <GoToNextPage>
              {(props) => (
                <Button
                  className="buttonCircle"
                  onClick={props.onClick}
                  disabled={props.isDisabled}
                  type="text"
                  shape="circle">
                  <RightOutlined />
                </Button>
              )}
            </GoToNextPage>
            <GoToLastPage>
              {(props) => (
                <Button
                  className="buttonCircle"
                  onClick={props.onClick}
                  disabled={props.isDisabled}
                  type="text"
                  shape="circle">
                  <DoubleRightOutlined />
                </Button>
              )}
            </GoToLastPage>
          </div>
        </div>
        <div className="pdfHeaderright acn-flex acn-flex-middle">
          <div className="pdfHeaderSubContainer">
            <ZoomIn>
              {(props) => (
                <Button className="buttonCircle" onClick={props.onClick} type="text" shape="circle">
                  <ZoomInOutlined />
                </Button>
              )}
            </ZoomIn>
            <div className="pdfZoomScaleText">
              <CurrentScale />
            </div>
            <ZoomOut>
              {(props) => (
                <Button className="buttonCircle" onClick={props.onClick} type="text" shape="circle">
                  <ZoomOutOutlined />
                </Button>
              )}
            </ZoomOut>
          </div>
          {pdfDetails?.showOpenIcon && (
            <Tooltip
              placement="leftTop"
              overlayClassName="tooltip-text"
              color="#fff"
              title={"View asset"}>
              <span
                onClick={() => {
                  handleClosePDF();
                  navigate(`${PATHS.viewContentManagementByUser}/${pdfDetails?.assetId}`);
                }}>
                <img src={IMAGES.iconLink} alt="Icon Link" className="icon-link" />
              </span>
            </Tooltip>
          )}
        </div>
      </div>
      {isLoading && (
        <div className="loading-pdf">
          <LoadingPdf />
        </div>
      )}
      {isError ? <div className="error-document">Document not found. Please try again</div> : <></>}
      {url && !isError ? (
        <Worker workerUrl={GlobalWorkerOptions.workerSrc}>
          <Viewer
            fileUrl={url}
            defaultScale={1}
            onDocumentLoad={() => {
              setTimeout(() => {
                openLinksInNewTab();
              }, 1000);
              handleJumpToPage();
            }}
            plugins={[
              toolbarPluginInstance,
              pageNavigationPluginInstance,
              zoomPluginInstance,
              jumpToPagePluginInstance,
              searchPluginInstance,
            ]}
          />
        </Worker>
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default PDFViewer;
