import { Dispatch, SetStateAction } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Card, Table } from "antd";

import SkeletonTable from "./SkeletonTable";
import { RootState } from "../../../store";
import {
  IFields,
  IFunctionsTableData,
  ISubProcessTableData,
} from "../../../store/generation/kpis/kpiGeneration.interface";
import {
  generationFields,
  setUpdateFields,
  setUpdateIsDisable,
  setUpdateIsShow,
} from "../../../store/generation/kpis/kpiGenerationSlice";
import { fetchKpiDocBySubProcess } from "../../../services/generation";
import { getFormattedSubProcess, getIsProcessing } from "../../../helper/GenerationHelper";
import { IMAGES } from "../../../shared";

interface KpiSubProcess {
  localFields: IFields;
  setLocalFields: Dispatch<SetStateAction<IFields>>;
}

const KpiSubProcess = ({ localFields, setLocalFields }: KpiSubProcess) => {
  const dispatch = useDispatch();
  const { isDisable, isLoading, isAllLoading, kpiGenerations, isKpiDataLoading } = useSelector(
    (state: RootState) => state.kpiGeneration
  );
  const loading = isLoading?.subProcess || isAllLoading;

  const handleSelectSubProcess = (
    _: React.Key[],
    selectedRows: ISubProcessTableData[],
    selectedFunction: string
  ) => {
    let existingSelectedSubProcess = localFields?.subProcess || [];
    existingSelectedSubProcess = existingSelectedSubProcess.filter(
      (subProcess) => subProcess.function !== selectedFunction
    );

    const updatedSelectedSubProcess = [...existingSelectedSubProcess, ...selectedRows];
    const finalSelectedSubProcess = Array.from(
      new Set(updatedSelectedSubProcess.map((item) => JSON.stringify(item)))
    ).map((item) => JSON.parse(item));

    setLocalFields((field) => ({ ...field, subProcess: finalSelectedSubProcess }));
  };

  const handleNext = async () => {
    if (kpiGenerations?.kpi_id && localFields?.subProcess && localFields?.subProcess?.length) {
      const subProcessIds = localFields?.subProcess?.map((item) => item.id) || [];

      const res = await fetchKpiDocBySubProcess(kpiGenerations?.kpi_id, subProcessIds);
      if (res) {
        dispatch(
          setUpdateFields({ field: generationFields.subProcess, value: localFields?.subProcess })
        );

        dispatch(setUpdateIsShow({ field: generationFields.exportDoc, value: true }));
        dispatch(setUpdateIsDisable({ field: generationFields.topic, value: false }));
        dispatch(setUpdateIsDisable({ field: generationFields.functions, value: true }));
        dispatch(setUpdateIsDisable({ field: generationFields.process, value: true }));
        dispatch(setUpdateIsDisable({ field: generationFields.subProcess, value: true }));
      }
    } else {
      toast.error("Please select sub-process");
    }
  };

  const getSelectedFunction = () => {
    const functions = (localFields?.functions || []).map((item) => item.function);
    const formattedSsubProcess = getFormattedSubProcess(kpiGenerations?.sub_processes || []).map(
      (item) => item.function
    );

    const filteredFun = functions.filter((item) => formattedSsubProcess.includes(item));
    const finalFunctions = (localFields?.functions || []).filter((item) =>
      filteredFun.includes(item.function)
    );

    return finalFunctions;
  };

  const getTableDataLength = () => {
    let tableDataLengthArr: any[] = [];

    const selectedFunction = getSelectedFunction() || [];
    selectedFunction.map((fun: IFunctionsTableData) => {
      const formattedSsubProcess =
        getFormattedSubProcess(kpiGenerations?.sub_processes || []) || [];

      const tableData = formattedSsubProcess
        .filter((subProcess: ISubProcessTableData) => subProcess?.function === fun.function)
        .map((subProcess: ISubProcessTableData, subProcessIdx: number) => ({
          ...subProcess,
          index: subProcessIdx + 1,
        }));
      tableDataLengthArr.push(tableData.length);
    });

    return tableDataLengthArr;
  };

  const renderSubProcessTable = () => {
    const selectedFunction = getSelectedFunction() || [];
    const tableDataLengthArr = getTableDataLength() || [];

    return (
      <>
        {selectedFunction && selectedFunction.length ? (
          selectedFunction.map((fun: IFunctionsTableData, funIndex: number) => {
            const formattedSsubProcess =
              getFormattedSubProcess(kpiGenerations?.sub_processes || []) || [];

            const tableData = formattedSsubProcess
              .filter((subProcess: ISubProcessTableData) => subProcess?.function === fun.function)
              .map((subProcess: ISubProcessTableData, subProcessIdx: number) => ({
                ...subProcess,
                index: subProcessIdx + 1,
              }));

            const isEdit = !isDisable?.subProcess;
            let subProcessKeys: any[] = [];

            if (isEdit) {
              subProcessKeys =
                localFields?.subProcess && localFields?.subProcess?.length
                  ? localFields?.subProcess
                      ?.filter((item) => item?.function === fun.function)
                      .map((item) => item.key)
                  : [];
            } else {
              const selectedSubProcessIds: any[] = localFields?.subProcess?.map((item) => item.id);
              const filteredSubProcess =
                formattedSsubProcess && formattedSsubProcess?.length
                  ? formattedSsubProcess?.filter((item) => item?.function === fun.function)
                  : [];

              const result: any = [];
              let additionalIndexLength = 0;

              if (funIndex > 0 && tableDataLengthArr?.length >= funIndex) {
                for (let i = 0; i < funIndex; i++) {
                  additionalIndexLength += tableDataLengthArr[i] || 0;
                }
              }

              if (filteredSubProcess && filteredSubProcess?.length) {
                filteredSubProcess.forEach((item, index) => {
                  if (selectedSubProcessIds.includes(item.id)) {
                    result.push({ ...item, index: index + 1 + additionalIndexLength });
                  }
                });
              }
              subProcessKeys =
                result && result?.length ? result.map((item: any) => item?.index) : [];
            }

            if (tableData && tableData.length) {
              return (
                <Table
                  key={funIndex}
                  rowSelection={{
                    columnTitle: () => <div style={{ visibility: "hidden" }}></div>,
                    selectedRowKeys: subProcessKeys || [],
                    onChange: (keys, selectedRows) =>
                      handleSelectSubProcess(keys, selectedRows, fun.function),
                  }}
                  columns={[
                    {
                      title: "No.",
                      dataIndex: "index",
                      rowScope: "row",
                      width: "30px",
                    },
                    {
                      title: `Select the sub-process for "${fun.function}"`,
                      dataIndex: "name",
                    },
                  ]}
                  dataSource={tableData || []}
                  pagination={false}
                  rowClassName={isDisable?.subProcess || loading ? "disable-table-row" : ""}
                />
              );
            } else {
              return <></>;
            }
          })
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <>
      <Card className="card-item">
        <div className="card-header">
          <span className="card-number">4</span>
          <h2>Selected sub-process(s)</h2>
        </div>

        <div className="card-content">
          {isKpiDataLoading ? (
            <SkeletonTable />
          ) : kpiGenerations?.sub_processes && kpiGenerations?.sub_processes?.length ? (
            renderSubProcessTable()
          ) : (
            <Table
              columns={[
                {
                  title: "No.",
                  dataIndex: "key",
                  rowScope: "row",
                  width: "30px",
                },
                {
                  title: `Select the sub-process`,
                  dataIndex: "name",
                },
              ]}
              dataSource={[]}
              pagination={false}
            />
          )}
        </div>

        {!isKpiDataLoading &&
        kpiGenerations?.sub_processes &&
        kpiGenerations?.sub_processes?.length ? (
          <div className="card-footer acn-flex acn-flex-end">
            <div className="card-footer-right acn-flex acn-flex-middle">
              {(isLoading?.subProcess || getIsProcessing(kpiGenerations?.document_status)) && (
                <div className="processing-wrap">
                  <img src={IMAGES.loadingSpinner} alt="loading" className="spinnerSvg" />
                  Processing
                </div>
              )}
              <Button
                type="primary"
                className="outline-btn"
                onClick={handleNext}
                disabled={isDisable?.subProcess || loading}>
                Next
              </Button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
};

export default KpiSubProcess;
