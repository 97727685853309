export const IMAGES = {
  logo: "/assets/images/logo.png",

  siteLogo: "/assets/images/site-logo.svg",

  userProfile: "/assets/images/user-vector.svg",

  resetPassword: "/assets/images/reset-password-icon.svg",

  needHelp: "/assets/images/need-help-icon.svg",

  logout: "/assets/images/logout-icon.svg",

  menuToggle: "/assets/images/side-toggle.svg",

  closeToggle: "/assets/images/side-close.svg",

  uploadAssetIcon: "/assets/images/upload-asset-icon.svg",

  auSmallFinanceBank: "/assets/images/au-small-finance-bank.svg",

  castrol: "/assets/images/castrol.svg",

  cera: "/assets/images/cera.svg",

  dailyThanthi: "/assets/images/daily-thanthi.svg",

  dsGroup: "/assets/images/ds-group.svg",

  htMedia: "/assets/images/ht-media.svg",

  indofilIndustries: "/assets/images/indofil-industries.svg",

  jkPaper: "/assets/images/jk-paper.svg",

  jkcement: "/assets/images/jkcement.svg",

  loreal: "/assets/images/loreal.svg",

  natwestGroup: "/assets/images/natwest-group.svg",

  outlook: "/assets/images/outlook.svg",

  shapoorjiPallonji: "/assets/images/shapoorji-pallonji.svg",

  godfreyPhillips: "/assets/images/godfrey-phillips.svg",

  sleepwell: "/assets/images/sleepwell.svg",

  unionBank: "/assets/images/union-bank.svg",

  vGuard: "/assets/images/v-guard.svg",

  vini: "/assets/images/vini.svg",

  welspun: "/assets/images/welspun.svg",

  zydusWellness: "/assets/images/zydus-wellness.svg",

  iconOne: "/assets/images/icon-one.svg",

  iconTwo: "/assets/images/icon-two.svg",

  iconThree: "/assets/images/icon-three.svg",

  iconFour: "/assets/images/icon-four.svg",

  iconFive: "/assets/images/icon-five.svg",

  iconSix: "/assets/images/icon-six.svg",

  iconSeven: "/assets/images/icon-seven.svg",

  focusMmeImage: "/assets/images/focus-mme-img.jpg",

  midMarketExecutives: "/assets/images/mid-market-executives.svg",

  promotersAcross: "/assets/images/promotersAcross.png",

  uploadedAssetsDummy: "/assets/images/uploaded-assets-dummy.png",

  viewedAssetsDummy: "/assets/images/viewed-assets-dummy.png",

  searchIcon: "/assets/images/search-icon.svg",

  historyWhiteIcon: "/assets/images/watch-icon.svg",

  usreManagementIcon: "/assets/images/usre-management.svg",

  handWave: "/assets/images/hand-wave.png",

  aiIcon: "/assets/images/ai-icon.png",

  eyeIcon: "/assets/images/eye-icon.svg",

  linkIcon: "/assets/images/link-icon.svg",

  thumbnail: "/assets/images/thumbnail-blank.svg",

  stars: "/assets/images/stars.svg",

  avatar1: "/assets/images/avatar1.png",

  goodIcon: "/assets/images/good-icon.svg",

  badIcon: "/assets/images/bad-icon.svg",

  copyIcon: "/assets/images/copy-icon.svg",

  taxonomyManagement: "/assets/admin/images/taxonomy-managment.svg",

  contentManagement: "/assets/admin/images/content-management.svg",

  logos: "/assets/admin/images/logos.svg",

  dotsIcon: "/assets/admin/images/dots-icon.svg",

  exportIcon: "/assets/admin/images/export-icon.svg",

  uploadIcon: "/assets/admin/images/upload-icon.svg",

  editIcon: "/assets/admin/images/edit-icon.svg",

  arrowIcon: "/assets/admin/images/arrow-right-icon.svg",

  keywordIcon: "/assets/admin/images/keyword-icon.svg",

  addIcon: "/assets/admin/images/add-icon.svg",

  lineDownloadIcon: "/assets/admin/images/line-download-icon.svg",

  manageIcon: "/assets/admin/images/manage-icon.svg",

  backIcon: "/assets/admin/images/back-icon.svg",

  backIconArrow: "/assets/images/back-icon.svg",

  organogramIconPng: "/assets/admin/images/organogram-icon.svg",

  flatKeywordIconPng: "/assets/admin/images/flat-keyword-icon.svg",

  // organogramIconPng: "/assets/admin/images/organogram-icon.png",

  // flatKeywordIconPng: "/assets/admin/images/flat-keyword-icon.png",

  uploadIconWhite: "/assets/admin/images/upload-icon-white.svg",

  logsFrame: "/assets/admin/images/logs-frame.svg",

  iconLink: "/assets/admin/images/link-icon.svg",

  avatarDefault: "/assets/images/avatar-default.jpg",

  avatar2: "/assets/admin/images/avatar2.png",

  avatar3: "/assets/admin/images/avatar3.png",

  avatar4: "/assets/admin/images/avatar4.png",

  emailIcon: "/assets/admin/images/email-icon.svg",

  plusIcon: "/assets/admin/images/plus-icon.svg",

  roundPlusIcon: "/assets/admin/images/round-plus-icon.svg",

  stopIcon: "/assets/images/stop-icon.svg",

  closeIcon: "/assets/admin/images/close-icon.png",

  browseIcon: "/assets/admin/images/browse-icon.svg",

  deleteIcon: "/assets/admin/images/delete-icon.svg",

  arrowMenu: "/assets/images/mobile-arrow.svg",

  filterIcon: "/assets/images/filter-icon.svg",

  brandThumbnail: "/assets/images/brand-thumbnail.svg",

  askFurtherIcon: "/assets/images/curved-left-down-arrow.svg",

  userThumbnail: "/assets/images/user-thumbnail.svg",

  breadcrumbDevider: "/assets/images/breadcrumb-devider.svg",

  innerBanner: "/assets/images/inner-banner.jpg", // About page start

  competitiveAdvantage: "/assets/images/competitive-advantage.svg",

  compressedTransformation: "/assets/images/compressed-transformation.svg",

  speedMarket: "/assets/images/speed-market.svg",

  supplyOptimization: "/assets/images/supply-optimization.svg",

  intelligentMarketing: "/assets/images/intelligent-marketing.svg",

  businessAvenues: "/assets/images/business-avenues.svg",

  agileDecisionMaking: "/assets/images/agile-decision-making.svg",

  talentReention: "/assets/images/talent-reention.svg",

  digitalPioneer: "/assets/images/digital-pioneer.svg",

  distinguishedTalent: "/assets/images/distinguished-talent.svg",

  disruptiveGrowth: "/assets/images/disruptive-growth.svg",

  dynamicProcesses: "/assets/images/dynamic-processes.svg",

  definitiveProfits: "/assets/images/definitive-profits.svg",

  distinGuishedTalenth: "/assets/images/distinguished-talent.svg", // About page start

  loadingSpinner: "/assets/images/loading-spinner.svg",

  imageIcon: "/assets/images/image-icon.png",

  alertIcon: "/assets/images/alert-icon.png",

  docsIcon: "/assets/images/docs.png",

  xlsIcon: "/assets/images/xls.png",

  pdfIcon: "/assets/images/pdf.png",

  pptIcon: "/assets/images/ppt.png",

  fileUploadedIcon: "/assets/admin/images/file-uploaded-icon.svg",

  generateKpis: "/assets/images/generateKpis.svg",

  designProcessFlows: "/assets/images/designProcessFlows.svg",

  generateDigitalUseCases: "/assets/images/generateDigitalUseCases.svg",

  generateGenAIUseCases: "/assets/images/generateGenAIUseCases.svg",

  generateForMultipleAreas: "/assets/images/generateForMultipleAreas.svg",

  getStartedIcon: "/assets/images/getStartedIcon.png",
};
