import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { persistStore } from "redux-persist";
import { Button, Dropdown, Menu, MenuProps, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";

import { store } from "../../store";
import { clearAllData } from "../../store/generation/kpis/kpiGenerationSlice";
import { clearAllDataBusProp } from "../../store/generation/businessProposal/businessProposalSlice";
import {
  IMAGES,
  PATHS,
  getUserRole,
  UserRoles,
  getUserName,
  removeCookie,
  NeedHelpEmail,
  removeLocalstorage,
} from "../../shared";
import "./Header.scss";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = () => {
    toast.success("Logout Successfully");
    persistStore(store).purge();
    removeLocalstorage();
    removeCookie();
    indexedDB.deleteDatabase("localforage");
    indexedDB.deleteDatabase("knowledge-management");
    navigate(PATHS.login);
  };

  const selectedMenu = () => {
    if (pathname.includes(PATHS.admin)) return PATHS.admin;
    if ([String(PATHS.browseProject), PATHS.browseCategory].includes(pathname)) {
      return PATHS.browseProject;
    } else if (
      [String(PATHS.generation), PATHS.generateKPIs, PATHS.generateBusinessProposal].includes(
        pathname
      )
    ) {
      return PATHS.generation;
    }

    return pathname;
  };

  const itemsdown: MenuProps["items"] = [
    {
      key: "1",
      label: <a>Reset password</a>,
      icon: <img className="logo-img" src={IMAGES.resetPassword} alt="resetPassword" />,
      onClick: () => navigate(PATHS.resetPassword),
    },
    {
      key: "2",
      label: <a>Need help</a>,
      icon: <img className="logo-img" src={IMAGES.needHelp} alt="help" />,
      onClick: () => window.open(`mailto:${NeedHelpEmail}`),
    },
    {
      key: "3",
      label: <a>Logout</a>,
      icon: <img className="logo-img" src={IMAGES.logout} alt="logout" />,
      onClick: () => handleLogout(),
    },
  ];

  const handleNaviagteAdmin = () => {
    if (getUserRole() === UserRoles.ContentEditor) {
      navigate(PATHS.adminContentManagement);
    } else {
      navigate(PATHS.adminUserManagement);
    }
  };

  const handleNaviagteGeneration = () => {
    if (!pathname.includes(PATHS.generateKPIs)) {
      dispatch(clearAllData());
    } else if (!pathname.includes(PATHS.generateBusinessProposal)) {
      dispatch(clearAllDataBusProp());
    }
    navigate(PATHS.generation);
  };

  const items: MenuProps["items"] = [
    {
      key: PATHS.home,
      label: <a>Home</a>,
      onClick: () => navigate(PATHS.home),
    },
    {
      key: PATHS.conversationalSearch,
      label: <a>Conversational Search</a>,
      onClick: () => navigate(PATHS.conversationalSearch),
    },
    {
      key: PATHS.browseProject,
      label: <a>Browse Content</a>,
      onClick: () => navigate(PATHS.browseProject),
    },
    {
      key: PATHS.generation,
      label: <a>Generation</a>,
      onClick: () => handleNaviagteGeneration(),
    },
    {
      key: PATHS.about,
      label: <a>About</a>,
      onClick: () => navigate(PATHS.about),
    },
    {
      key: PATHS.admin,
      label: <a>Admin</a>,
      onClick: () => handleNaviagteAdmin(),
    },
  ];

  const filteredItems: MenuProps["items"] = items.filter(
    (item) =>
      !(
        item?.key === PATHS.admin &&
        ![UserRoles.Admin, UserRoles.ContentEditor].includes(getUserRole())
      )
  );

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.classList.toggle("sidebar-menu-open"); // Toggle the class on body tag
  };

  // Add class header based on the current page URL Start
  const location = useLocation();
  useEffect(() => {
    // Add a class to the body tag based on the current URL
    const body = document.querySelector("body");
    if (body) {
      body.className = location.pathname.replace("/", "home-page");
    }
    // Clean up function to remove the class when the component unmounts
    return () => {
      if (body) {
        body.className = "";
      }
    };
  }, [location.pathname]);
  // Add class header based on the current page URL End

  const getUserFirstName = () => {
    const name = getUserName();
    return name ? name.split(" ")[0] : "";
  };

  return (
    <>
      <div className="header-main ant-layout-header">
        <div className="container acn-flex acn-flex-between acn-flex-middle">
          <div className="left-bar main-menu acn-flex acn-flex-middle">
            <div className="logo acn-flex acn-flex-middle">
              <img className="logo-img" src={IMAGES.siteLogo} alt="logo" />
            </div>
            <div className="menu-nav-list">
              <Button onClick={toggleMenu} type="text" className="menu-toggle menu-toggle-close">
                <img src={IMAGES.closeToggle} alt="Close Toggle" />
              </Button>
              <Menu
                mode="horizontal"
                defaultSelectedKeys={[PATHS.home]}
                selectedKeys={[selectedMenu()]}
                items={filteredItems}
              />
            </div>
          </div>
          <div className="main-menu acn-flex acn-flex-right acn-flex-middle ">
            <span className="sidebar-overlay" onClick={toggleMenu}></span>
            <Button onClick={toggleMenu} type="text" className="menu-toggle">
              <img src={IMAGES.menuToggle} alt="Menu Toggle" />
            </Button>
            <p>{getUserFirstName()}</p>
            <Dropdown
              placement="bottomRight"
              overlayClassName="user-profile-dropdown"
              menu={{ items: itemsdown }}>
              <Space className="user-profile">
                <img className="logo-img" src={IMAGES.userProfile} alt="logo" />
                <DownOutlined />
              </Space>
            </Dropdown>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
