import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { AppLayout } from "./layout";
import { PATHS } from "./shared";
import { PersistLogin, ProtectedRoute } from "./routes";
import {
  ContentManagement,
  TaxonomyManagement,
  AdminLogs,
  ViewEditContentManagement,
  TaxonomyOverview,
  ViewContentManagementByUser,
} from "./admin";
import {
  Home,
  Login,
  ResetPassword,
  BrowseCategory,
  ForgotPassword,
  ConversationalSearch,
} from "./pages";
import { OTP } from "./pages/auth/otp";
import { ChangePassword } from "./pages/auth/changePassword";
import { UserManagement } from "./admin";
import { BrowseProject } from "./pages/browseProject";
import { ManageTaxonomyTags } from "./admin/components";
import { About } from "./pages/about";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setIsNetworkOpen } from "./store/conversationalSearch/conversationalSearchSlice";
import { Generation, GenerateKPIs, GenerateBusinessProposal } from "./pages/generation";

const App = () => {
  const dispatch = useDispatch();

  function offlineEventListener() {
    dispatch(setIsNetworkOpen(false));
  }
  function onlineEventListener() {
    dispatch(setIsNetworkOpen(true));
  }
  useEffect(() => {
    window.addEventListener("offline", offlineEventListener);
    window.addEventListener("online", onlineEventListener);
    return () => {
      window.removeEventListener("offline", offlineEventListener);
      window.removeEventListener("online", onlineEventListener);
    };
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route element={<PersistLogin />}>
          <Route index path={PATHS.login} element={<Login />} />
          <Route index path={PATHS.forgotPassword} element={<ForgotPassword />} />
          <Route index path={PATHS.resetPassword} element={<ResetPassword />} />
          <Route index path={PATHS.verifyOtp} element={<OTP />} />
          <Route index path={PATHS.changePassword} element={<ChangePassword />} />
          <Route element={<ProtectedRoute />}>
            <Route element={<AppLayout />}>
              <Route index path={PATHS.home} element={<Home />} />
              <Route path={PATHS.conversationalSearch} element={<ConversationalSearch />} />
              <Route path={PATHS.browseCategory} element={<BrowseCategory />} />
              <Route path={PATHS.about} element={<About />} />
              <Route path={PATHS.adminUserManagement} element={<UserManagement />} />
              <Route
                path={`${PATHS.viewContentManagementByUser}/:assetId`}
                element={<ViewContentManagementByUser />}
              />
              <Route path={PATHS.generation} element={<Generation />} />
              <Route path={PATHS.generateKPIs} element={<GenerateKPIs />} />
              <Route path={PATHS.generateBusinessProposal} element={<GenerateBusinessProposal />} />
              {/* <Route path={PATHS.adminLogos} element={<div>logos </div>} /> */}
              {/**********************************Admin Routes****************************/}
              <Route path={PATHS.adminTaxonomyManagement} element={<TaxonomyManagement />} />
              <Route path={PATHS.adminContentManagement} element={<ContentManagement />} />
              <Route path={PATHS.adminLogs} element={<AdminLogs />} />
              <Route
                path={`${PATHS.viewEditContentManagement}/:assetId`}
                element={<ViewEditContentManagement />}
              />
              <Route path={`${PATHS.adminTaxonomyOverview}/:id`} element={<TaxonomyOverview />} />
              <Route path={PATHS.manageTaxonomyTags} element={<ManageTaxonomyTags />} />
              <Route path={PATHS.browseProject} element={<BrowseProject />} />
            </Route>
          </Route>
        </Route>
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};

export default App;
