import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Row } from "antd";
import { useDispatch } from "react-redux";
import { clearAllData } from "../../store/generation/kpis/kpiGenerationSlice";
import { clearAllDataBusProp } from "../../store/generation/businessProposal/businessProposalSlice";
import { IMAGES } from "../../shared";
import { generationAreaType } from "../../helper/GenerationHelper";
import "./Generation.scss";

const GenerationHome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAreaTypeClick = (path: string) => {
    dispatch(clearAllData());
    dispatch(clearAllDataBusProp());
    path && navigate(path);
  };

  return (
    <>
      <div className="generation-home-page">
        <div className="generation-inner-banner">
          <div className="container">
            <div className="generation-inner-banner-text">
              <h1>Create Your Own Document</h1>
              <p>Select the area for document generation</p>
              <Button type="text" className="get-started-btn">
                Get Started
              </Button>
            </div>
          </div>
          <Button type="text" className="get-started-icon">
            <img src={IMAGES.getStartedIcon} />
          </Button>
        </div>

        <div className="select-area-type">
          <div className="container">
            <h2 className="sectionTitle">Select Area Type</h2>
            <div className="key-areas-inner">
              <Row gutter={[30, 30]}>
                {generationAreaType.map((type) => (
                  <Col
                    span={8}
                    onClick={() => handleAreaTypeClick(type.href)}
                    className={`${type.href && "cursor-pointer"}`}>
                    <Card>
                      <img src={type.icon} />
                      <h3>{type.title}</h3>
                    </Card>
                  </Col>
                ))}
                ;
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GenerationHome;
