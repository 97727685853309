import { TableColumnsType } from "antd";
import {
  IFunctions,
  IFunctionsTableData,
  IProcess,
  IProcessTableData,
  ISubProcess,
  ISubProcessTableData,
  TableDataType,
} from "../store/generation/kpis/kpiGeneration.interface";
import { AssetUploadStatus, IMAGES, PATHS } from "../shared";

const generationAreaType = [
  {
    href: PATHS.generateKPIs,
    icon: IMAGES.generateKpis,
    title: "Generate KPIs",
  },
  {
    href: "",
    icon: IMAGES.designProcessFlows,
    title: "Design Process Flows",
  },
  {
    href: "",
    icon: IMAGES.generateDigitalUseCases,
    title: "Generate Digital Use Cases",
  },
  {
    href: "",
    icon: IMAGES.generateGenAIUseCases,
    title: "Generate Gen AI Use Cases",
  },
  {
    href: "",
    icon: IMAGES.generateForMultipleAreas,
    title: "Generate for Multiple Areas",
  },
  {
    href: "",
    // href: PATHS.generateBusinessProposal,
    icon: IMAGES.generateDigitalUseCases,
    title: "Generate Business Proposal",
  },
];

const tagsColumn: TableColumnsType<TableDataType> = [
  {
    title: "No.",
    dataIndex: "key",
    rowScope: "row",
    width: "30px",
  },
  {
    title: "Tags",
    dataIndex: "name",
  },
];

const functionsColumn: TableColumnsType<TableDataType> = [
  {
    title: "No.",
    dataIndex: "index",
    rowScope: "row",
    width: "30px",
  },
  {
    title: "Functions",
    dataIndex: "name",
  },
];

const exportDocColumns: TableColumnsType<TableDataType> = [
  {
    title: "Kindly select the format of the document you wish to generate the file in",
    dataIndex: "name",
  },
];

const exportDocData: TableDataType[] = [
  {
    key: 1,
    name: "CSV",
  },
];

const agendaColumn: TableColumnsType<TableDataType> = [
  {
    title: "No.",
    dataIndex: "index",
    rowScope: "row",
    width: "30px",
  },
  {
    title: "Agenda",
    dataIndex: "name",
  },
];

const getFormattedFunction = (list: IFunctions[]) => {
  let formattedList: IFunctionsTableData[] = [];
  if (list && list.length) {
    list.forEach((item, index) => {
      formattedList.push({ ...item, key: item.id, name: item.function, index: index + 1 });
    });
  }
  return formattedList;
};

const getFormattedProcess = (list: IProcess[]) => {
  let formattedList: IProcessTableData[] = [];
  if (list && list.length) {
    list.forEach((item, index) => {
      formattedList.push({ ...item, key: index + 1, name: item.process, index: index + 1 });
    });
  }
  return formattedList;
};

const getFormattedSubProcess = (list: ISubProcess[]) => {
  let formattedList: ISubProcessTableData[] = [];
  if (list && list.length) {
    list.forEach((item, index) => {
      formattedList.push({ ...item, key: index + 1, name: item.sub_process, index: index + 1 });
    });
  }
  return formattedList;
};

const getIsProcessing = (status: string) =>
  [String(AssetUploadStatus.SENT_TO_LLM), AssetUploadStatus.PROCESSING].includes(status);

const getIsDisable = (status: string) =>
  [String(AssetUploadStatus.COMPLETED), AssetUploadStatus.FAILED].includes(status);

const getIsEnable = (status: string) =>
  [String(AssetUploadStatus.COMPLETED), AssetUploadStatus.FAILED].includes(status);

const getSelectedFunction = (functions: any[]) => {
  const selectedFunction =
    functions && functions?.length ? functions?.filter((fun) => fun.is_selected) : [];
  return getFormattedFunction(selectedFunction);
};

const getSelectedProcess = (process: any[]) => {
  const selectedProcess =
    process && process?.length ? process?.filter((item) => item.is_selected) : [];
  return getFormattedProcess(selectedProcess);
};

const getSelectedSubProcess = (subProcess: any[]) => {
  const selectedSubProcess =
    subProcess && subProcess?.length ? subProcess?.filter((item) => item.is_selected) : [];
  return getFormattedSubProcess(selectedSubProcess);
};

const getSelectedFunKeys = (list: any[]) => {
  const keys: number[] = [];
  if (list && list?.length) {
    list.forEach((item) => {
      if (item.is_selected) {
        keys.push(item.id);
      }
    });
  }

  return keys;
};

const getFormattedAgenda = (list: any[]) => {
  let formattedList: any[] = [];
  if (list && list.length) {
    list.forEach((item, index) => {
      formattedList.push({ ...item, key: item.id, name: item.agenda, index: index + 1 });
    });
  }
  return formattedList;
};

const getListKeys = (list: any[]) => {
  let keys: number[] = [];
  if (list && list.length) {
    keys = list.map((item) => item?.id);
  }

  return keys;
};

export {
  generationAreaType,
  tagsColumn,
  functionsColumn,
  exportDocColumns,
  exportDocData,
  agendaColumn,
  getFormattedFunction,
  getFormattedProcess,
  getFormattedSubProcess,
  getIsProcessing,
  getIsDisable,
  getIsEnable,
  getSelectedFunction,
  getSelectedProcess,
  getSelectedSubProcess,
  getSelectedFunKeys,
  getFormattedAgenda,
  getListKeys,
};
