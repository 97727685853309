import { toast } from "react-toastify";
import { get, post, put } from "./apiClients";
import { store } from "../store";
import {
  generationFields,
  kpiGenerationsInitValue,
  setKpiGenerations,
  setUpdateIsLoading,
} from "../store/generation/kpis/kpiGenerationSlice";
import {
  generationFields as generationFieldsBusProp,
  setUpdateIsLoading as setUpdateIsLoadingBusProp,
} from "../store/generation/businessProposal/businessProposalSlice";
import { AssetUploadStatus } from "../shared";

export const fetchKpiFunctionsByTopic = async (prompt: string) => {
  try {
    store.dispatch(setUpdateIsLoading({ field: generationFields.topic, value: true }));
    const { data } = await post(`/core/kpi`, {
      prompt,
    });

    if (data) {
      store.dispatch(setKpiGenerations(data));
    } else {
      store.dispatch(setKpiGenerations(kpiGenerationsInitValue));
    }
    return data;
  } catch (error: any) {
    toast.error(error?.errorMessage ?? "Failed to create KPI");
  } finally {
    store.dispatch(setUpdateIsLoading({ field: generationFields.topic, value: false }));
  }
};

export const updateKpiFunctionsByTopic = async (kpiId: number, prompt: string) => {
  try {
    store.dispatch(setUpdateIsLoading({ field: generationFields.topic, value: true }));
    const { data } = await put(`/core/kpi/${kpiId}`, {
      prompt,
    });

    if (data) {
      store.dispatch(setKpiGenerations(data));
    } else {
      store.dispatch(setKpiGenerations(kpiGenerationsInitValue));
    }
    return data;
  } catch (error: any) {
    toast.error(error?.errorMessage ?? "Failed to create KPI");
  } finally {
    store.dispatch(setUpdateIsLoading({ field: generationFields.topic, value: false }));
  }
};

export const fetchKpiProcessByFunction = async (kpiId: number, functionsIds: React.Key[]) => {
  try {
    store.dispatch(setUpdateIsLoading({ field: generationFields.functions, value: true }));
    const res = await get(`/core/kpi/generate-processes`, {
      params: { kpi_id: kpiId, function_ids: String(functionsIds) },
    });

    if (res) {
      store.dispatch(setKpiGenerations(res));
    } else {
      store.dispatch(setKpiGenerations(kpiGenerationsInitValue));
    }
    return res;
  } catch (error: any) {
    toast.error(error?.errorMessage ?? "Failed to generate process");
  } finally {
    store.dispatch(setUpdateIsLoading({ field: generationFields.functions, value: false }));
  }
};

export const fetchKpiSubProcessByProcess = async (kpiId: number, processIds: React.Key[]) => {
  try {
    store.dispatch(setUpdateIsLoading({ field: generationFields.process, value: true }));
    const res = await get(`/core/kpi/generate-sub-processes`, {
      params: { kpi_id: kpiId, process_ids: String(processIds) },
    });

    if (res) {
      store.dispatch(setKpiGenerations(res));
    } else {
      store.dispatch(setKpiGenerations(kpiGenerationsInitValue));
    }
    return res;
  } catch (error: any) {
    toast.error(error?.errorMessage ?? "Failed to generate sub-process");
  } finally {
    store.dispatch(setUpdateIsLoading({ field: generationFields.process, value: false }));
  }
};

export const fetchKpiDocBySubProcess = async (kpiId: number, subProcessIds: React.Key[]) => {
  try {
    store.dispatch(setUpdateIsLoading({ field: generationFields.exportDoc, value: true }));
    const res = await get(`/core/kpi/generate-document`, {
      params: { kpi_id: kpiId, sub_process_ids: String(subProcessIds) },
    });

    if (res) {
      store.dispatch(setKpiGenerations(res));
    } else {
      store.dispatch(setKpiGenerations(kpiGenerationsInitValue));
    }
    return res;
  } catch (error: any) {
    toast.error(error?.errorMessage ?? "Failed to export document");
  } finally {
    store.dispatch(setUpdateIsLoading({ field: generationFields.exportDoc, value: false }));
  }
};

export const fetchPendingKpis = async (kpiId: number | null) => {
  try {
    let res;
    if (kpiId) {
      res = await get(`/core/kpi/${kpiId}`);
    } else {
      res = await get(`/core/kpi/latest`);
    }

    if (res.process_status === AssetUploadStatus.FAILED) {
      toast.error("Process generation failed. Please restart the generation.");
    } else if (res.sub_process_status === AssetUploadStatus.FAILED) {
      toast.error("Sub-process generation failed. Please restart the generation.");
    } else if (res.document_status === AssetUploadStatus.FAILED) {
      toast.error("Document generation failed. Please restart the generation.");
    }

    if (res) {
      store.dispatch(setKpiGenerations(res));
    } else {
      store.dispatch(setKpiGenerations(kpiGenerationsInitValue));
    }
    return res;
  } catch (error: any) {
    if (error?.errorMessage) {
      toast.error(error?.errorMessage);
    }
    store.dispatch(setKpiGenerations(kpiGenerationsInitValue));
  }
};

export const fetchIndustryFunByTopic = async (prompt: string) => {
  try {
    store.dispatch(
      setUpdateIsLoadingBusProp({ field: generationFieldsBusProp.topic, value: true })
    );
    const res = await get(`/core/business-proposal/industryFun`, { params: { prompt } });

    return res;
  } catch (error: any) {
    toast.error(error?.errorMessage ?? "Failed to start topic");
  } finally {
    store.dispatch(
      setUpdateIsLoadingBusProp({ field: generationFieldsBusProp.topic, value: false })
    );
  }
};
