import { toast } from "react-toastify";
import { store } from "../store";
import { get, post } from "./apiClients";
import {
  setAllUsersDetails,
  setUserTableLoading,
  setUserDeleteLoading,
  setTotalUsersCount,
  setUserCurrPage,
} from "../store/userManagement/usersSlice";
import { PageLimit } from "../shared";

interface IAddUserRequest {
  name: string;
  email: string;
  password: string;
  userPermission: [
    {
      roleName: string | undefined;
      configSettingId: number;
    }
  ];
}

export const fetchAllUsers = async (searchText = "", page = 1) => {
  try {
    store.dispatch(setUserTableLoading(true));
    const res = await get(
      `core/token/user-taxonomy?nameOrEmail=${searchText}&page=${page - 1}&limit=${
        PageLimit.UserManagement
      }`
    );

    const userRes = res.content ?? [];
    const totalElementsRes = res.totalElements ?? 0;
    const currPageRes = res.number ? res.number + 1 : 1;

    store.dispatch(setAllUsersDetails(userRes));
    store.dispatch(setTotalUsersCount(totalElementsRes));
    store.dispatch(setUserCurrPage(currPageRes));
    return userRes;
  } catch (error) {
    store.dispatch(setAllUsersDetails([]));
    console.log(error);
  } finally {
    store.dispatch(setUserTableLoading(false));
  }
};

export const addUser = async (
  request: IAddUserRequest,
  searchText: string,
  currPage: number,
  taxonomyNodes: any,
  isTaxonomyMappingChanged: boolean
) => {
  try {
    const res = await post(`/core/token/adduser`, request);
    if (isTaxonomyMappingChanged) {
      await updateUserTaxonomy(res.data.id, taxonomyNodes);
    }
    await fetchAllUsers(searchText, currPage);
    toast.success("User added successfully");
    return res;
  } catch (error: any) {
    console.log("Error while add user", error);
    toast.error(error ?? "Failed to add user");
  }
};

export const updateUser = async (
  request: any,
  searchText: string,
  currPage: number,
  taxonomyNodes: any,
  isFormValueChanged: boolean,
  isTaxonomyMappingChanged: boolean
) => {
  try {
    if (isFormValueChanged && isTaxonomyMappingChanged) {
      const res = await post(`/core/token/editUser`, request);
      await updateUserTaxonomy(request?.userId, taxonomyNodes);
      await fetchAllUsers(searchText, currPage);
      toast.success("User updated successfully");
      return res;
    } else if (isFormValueChanged) {
      const res = await post(`/core/token/editUser`, request);
      await fetchAllUsers(searchText, currPage);
      toast.success("User updated successfully");
      return res;
    } else if (isTaxonomyMappingChanged) {
      await updateUserTaxonomy(request?.userId, taxonomyNodes);
      await fetchAllUsers(searchText, currPage);
      toast.success("Tags updated successfully");
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteUser = async (id: number, searchText: string, currPage: number) => {
  store.dispatch(setUserDeleteLoading(true));
  try {
    const res = await get(`/core/token/remove/${id}`);
    await fetchAllUsers(searchText, currPage);
    toast.success("User deleted successfully");
    return res;
  } catch (error) {
    console.log(error);
    toast.error("User failed to delete");
    return error;
  } finally {
    store.dispatch(setUserDeleteLoading(false));
  }
};

export const updateUserStatus = async (typeofStatus: string, userId: number | undefined) => {
  try {
    const res = await get(`/core/token/${typeofStatus}/${userId}`);
    await fetchAllUsers("");
    toast.success("Status updated successfully");
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};
//

export const updateUserTaxonomy = async (
  userId: number,
  taxonomyMapping: [{ taxonomyId: number; taxonomyNodeIds: [number] }]
) => {
  try {
    const res = await post(`/core/user-taxonomy`, { userId, ...taxonomyMapping });
    return res;
  } catch (error) {
    console.log(error);
    toast.error("Failed to add taxonomy nodes");
    return error;
  }
};
