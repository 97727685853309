import "./About.scss";
import { IMAGES } from "../../shared";
import { Card, Col, Progress, Row } from "antd";

const About = () => {
  return (
    <div className="about-page">
      <div className="inner-banner acn-flex  acn-flex-middle">
        <div className="container">
          <div className="inner-banner-text">
            <h1>Key areas of concern identified</h1>
            <p>
              The Accenture CXO Survey* uncovered similar concerns as voiced by the promoters,
              resonating both within India and globally
            </p>
          </div>
        </div>
      </div>
      <div className="concern-identified">
        <div className="container">
          <div className="key-areas-inner">
            <Row>
              <Col span={6}>
                <Card>
                  <h3>Transformation</h3>
                  <Progress percent={82} />
                  <p>of CxOs say their organizations are currently undergoing transformation​</p>
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <h3>Growth Mindset</h3>
                  <Progress percent={65} />
                  <p>
                    of CxOs are primarily focusing their 2023 investments on revenue growth as
                    opposed to cutting costs​
                  </p>
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <h3>Digitization</h3>
                  <Progress percent={54} />
                  <p>of CxOs are planning to increase investment in developing a digital core</p>
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <h3>Profitability</h3>
                  <Progress percent={82} />
                  <p>of CxOs are focusing on cost optimization coupled with top line growth​​</p>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="cxo-priority">
        <div className="container">
          <h2 className="investment-title">CXO priority areas for transformation and investment</h2>
          <div className="inner-investment">
            <span className="line"></span>
            <div className="investment-list">
              <div className="investment-item">
                <span
                  className="present"
                  style={{ background: "conic-gradient(#fff 300deg, #B68FD0 48deg)" }}>
                  <span className="present-number">91%</span>
                </span>
                <span className="icon-text">
                  <img src={IMAGES.competitiveAdvantage} />
                  <h6>
                    Competitive <br></br>Advantage
                  </h6>
                </span>
              </div>
              <div className="investment-item">
                <span
                  className="present"
                  style={{ background: "conic-gradient(#fff 275deg, #B68FD0 48deg)" }}>
                  <span className="present-number">88%</span>
                </span>
                <span className="icon-text">
                  <img src={IMAGES.compressedTransformation} />
                  <h6>
                    Compressed<br></br>
                    Transformation
                  </h6>
                </span>
              </div>
              <div className="investment-item">
                <span
                  className="present"
                  style={{ background: "conic-gradient(#fff 158deg, #B68FD0 48deg)" }}>
                  <span className="present-number">57%</span>
                </span>
                <span className="icon-text">
                  <img src={IMAGES.speedMarket} />
                  <h6>
                    Speed To<br></br>
                    Market
                  </h6>
                </span>
              </div>
              <div className="investment-item">
                <span
                  className="present"
                  style={{ background: "conic-gradient(#fff 162deg, #B68FD0 48deg)" }}>
                  <span className="present-number">49%</span>
                </span>
                <span className="icon-text">
                  <img src={IMAGES.supplyOptimization} />
                  <h6>
                    Supply Chain<br></br>
                    Optimization
                  </h6>
                </span>
              </div>
              <div className="investment-item">
                <span
                  className="present"
                  style={{ background: "conic-gradient(#fff 120deg, #B68FD0 48deg)" }}>
                  <span className="present-number">47%</span>
                </span>
                <span className="icon-text">
                  <img src={IMAGES.intelligentMarketing} />
                  <h6>
                    Intelligent Sales<br></br>& Marketing
                  </h6>
                </span>
              </div>
              <div className="investment-item">
                <span
                  className="present"
                  style={{ background: "conic-gradient(#fff 115deg, #B68FD0 48deg)" }}>
                  <span className="present-number">46%</span>
                </span>
                <span className="icon-text">
                  <img src={IMAGES.businessAvenues} />
                  <h6>
                    New Business<br></br>
                    Avenues
                  </h6>
                </span>
              </div>
              <div className="investment-item">
                <span
                  className="present"
                  style={{ background: "conic-gradient(#fff 110deg, #B68FD0 48deg)" }}>
                  <span className="present-number">45%</span>
                </span>
                <span className="icon-text">
                  <img src={IMAGES.agileDecisionMaking} />
                  <h6>
                    Agile Decision<br></br>
                    Making
                  </h6>
                </span>
              </div>
              <div className="investment-item">
                <span
                  className="present"
                  style={{ background: "conic-gradient(#fff 100deg, #B68FD0 48deg)" }}>
                  <span className="present-number">39%</span>
                </span>
                <span className="icon-text">
                  <img src={IMAGES.talentReention} />
                  <h6>
                    Talent<br></br>Reention
                  </h6>
                </span>
              </div>
            </div>
          </div>
          <p className="survey-label">
            *Accenture CXO Pulse Survey 2023 of 2300+ CXOs covering CEOs, CMOs, CTOs, CFOs, COOs,
            CDOs, CSOs, and CHROs
          </p>
        </div>
      </div>
      <div className="term-program">
        <div className="container">
          <div className="term-program-inner">
            <h2>
              These data resulted in developing a platform that fosters advanced growth &
              transformation a cost-neutral, value accretive, long term program.
            </h2>
          </div>
          <div className="term-program-list">
            <div className="program-item">
              <div className="icon-div">
                <img src={IMAGES.disruptiveGrowth}></img>
              </div>
              <div className="text-inner">
                <h4>Disruptive growth</h4>
                <p>Business growth at scale & pace</p>
              </div>
            </div>
            <div className="program-item">
              <div className="icon-div">
                <img src={IMAGES.dynamicProcesses}></img>
              </div>
              <div className="text-inner">
                <h4>Dynamic processes</h4>
                <p>Industry best practices</p>
              </div>
            </div>
            <div className="program-item">
              <div className="icon-div">
                <img src={IMAGES.definitiveProfits}></img>
              </div>
              <div className="text-inner">
                <h4>Definitive profits</h4>
                <p>EBITDA improvement</p>
              </div>
            </div>
            <div className="program-item">
              <div className="icon-div">
                <img src={IMAGES.digitalPioneer}></img>
              </div>
              <div className="text-inner">
                <h4>Digital pioneer</h4>
                <p>Business relevant technology & AI backbone</p>
              </div>
            </div>
            <div className="program-item">
              <div className="icon-div">
                <img src={IMAGES.distinGuishedTalenth}></img>
              </div>
              <div className="text-inner">
                <h4>Distinguished talent</h4>
                <p>Organization and people transformation</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
