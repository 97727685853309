import { ITaxonomyTree } from "../store/taxonomy/taxonomy.interface";

export function findDescendantIds(data: ITaxonomyTree[], targetId: number) {
  const result: number[] = [];

  function findNodeAndChildren(node: ITaxonomyTree) {
    if (node?.taxonomyNodeId === targetId) {
      (function collectIds(node) {
        node?.children &&
          node?.children.forEach((child: ITaxonomyTree) => {
            result.push(child.taxonomyNodeId);
            collectIds(child);
          });
      })(node);
    } else {
      node?.children &&
        node?.children.forEach((child: ITaxonomyTree) => findNodeAndChildren(child));
    }
  }

  data.forEach((rootNode: any) => findNodeAndChildren(rootNode));
  return result || [];
}
