import { Button, Flex, Form, Input } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { IMAGES, PATHS } from "../../../shared";
import { CreateNewPassword } from "../../../services/authApi";
import { resetOtpPassword } from "../../../store/auth/auth.slice";

const ChangePassword = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { userEmailAtForgetPassword, otpAtForgetPassword, isCreatePasswordLoading } = useSelector(
    (state: RootState) => state.auth
  );

  const onFinish = (values: { password: string; confirmPassword: string }) => {
    const { confirmPassword } = values;

    CreateNewPassword(
      {
        email: userEmailAtForgetPassword,
        otp: otpAtForgetPassword,
        newPassword: confirmPassword,
      },
      navigate
    );
  };
  return (
    <>
      <Flex id="login-page" className="login-page">
        <div className="authentication-bg">
          <img className="sitelogo" src={IMAGES.logo} alt="Knowledge Management Portal" />
          <div className="top-effect"></div>
          <Flex className="authenticationTitle" align="center" justify="center">
            <h1>Transform your Knowledge Management using Generative AI</h1>
          </Flex>
        </div>
        <Flex className="loginForm" align="center" justify="center">
          <div className="loginFormInner">
            <h2>
              Knowledge Management Portal <img className="aiIcon" src={IMAGES.aiIcon} alt="logo" />
            </h2>
            <div id="loginView" className="card-inner">
              <h3>Set a new password</h3>
              <span className="labelLogin">
                Your new password must be different to previously used passwords.
              </span>
              <Form name="resetPassword" layout="vertical" onFinish={onFinish} requiredMark={false}>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[{ required: true, message: "Please input your new password" }]}>
                  <Input.Password placeholder="" />
                </Form.Item>
                <Form.Item
                  label="Confirm Password"
                  name="confirmPassword"
                  className="InputPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Your Confirm Password",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("The Confirm Password That You Entered Does Not Match!")
                        );
                      },
                    }),
                  ]}>
                  <Input.Password placeholder="" />
                </Form.Item>

                <Form.Item className="loginBtn">
                  <Button
                    loading={isCreatePasswordLoading}
                    className="primary-btn mt-20 mb-20"
                    type="primary"
                    htmlType="submit"
                    block>
                    Update Password
                  </Button>
                </Form.Item>
              </Form>
              <div className="back-btn acn-flex acn-flex-middle acn-flex-center">
                <Button
                  loading={false}
                  className="back-to-log acn-flex acn-flex-middle"
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    navigate(PATHS.login);
                    dispatch(resetOtpPassword([]));
                  }}
                  block>
                  <img className="back-icon" src={IMAGES.backIconArrow} alt="Back Icon" />
                  <Link to={PATHS.login}> Back to log in</Link>
                </Button>
              </div>
            </div>
          </div>
        </Flex>
      </Flex>
    </>
  );
};

export default ChangePassword;
