import { Button, Modal } from "antd";

interface IModal {
  title: string;
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

const DeleteModal: React.FC<IModal> = (props) => {
  const { title, isModalOpen, handleOk, handleCancel } = props;

  return (
    <Modal
      className="warning-modal"
      title={title}
      centered={true}
      onCancel={handleCancel}
      footer={
        <>
          <Button className="btn-type2" danger onClick={handleOk}>
            Ok
          </Button>
        </>
      }
      open={isModalOpen}></Modal>
  );
};

export default DeleteModal;
