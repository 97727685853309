import { Input, Space, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import historyIcon from "../../assets/images/icons/history-icon.png";
import { RecentChatHistoryPopUp } from "../../components";
import Chat from "../../components/conversationalSearch/chat/Chat";
import { FromWhere, IMAGES, PATHS, convertToString, getAllIds } from "../../shared";
import { RootState } from "../../store";
import {
  setIsOpenChatHistory,
  setSelectedChatHistory,
  setUserQuestion,
} from "../../store/conversationalSearch/conversationalSearchSlice";
import "./ConversationalSearch.scss";
import { useChats } from "../../shared/hooks/useChat";
import { useEffect, useRef, useState } from "react";
import {
  removeFilterData,
  setAllAssetTypesSelected,
  setAllFileTypesSelected,
  setFilterDateRange,
  setFiltersData,
} from "../../store/filtersFromTaxonomyData/filters.slice";
import { fileType } from "../../components/sideBar/FileTypeData";
import { useLocation, useNavigate } from "react-router-dom";
import { ITags } from "../../store/conversationalSearch/conversationalSearch.interface";
import { TFiltersType } from "../../store/filtersFromTaxonomyData/filters.interface";

const ConversationalSearch = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const chatHistoryRef = useRef<HTMLDivElement>(null);
  const { startNewChat, clearAllChatStates, defaultConfigureState } = useChats();
  const {
    isOpenChatHistory,
    showStartNewTopicMessage,
    isStreamingStart,
    chatHistory,
    selectedChatHistory,
  } = useSelector((state: RootState) => state.conversationalSearch);
  const { assetTypeTaxonomy } = useSelector((state: RootState) => state.taxonomy);
  const [question, setQuestion] = useState("");

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (chatHistoryRef.current && !chatHistoryRef.current.contains(event.target as Node)) {
        if (isOpenChatHistory) {
          dispatch(setIsOpenChatHistory(false));
        }
      }
    };
    document.body.addEventListener("click", handleClickOutside);
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [isOpenChatHistory]);

  useEffect(() => {
    if (
      location?.state?.fromWhere === FromWhere.viewEditContentManagement &&
      location?.state?.selectedChatHistoryId
    ) {
      handleSelectChatHistory();
    }
    return () => {
      if (!isStreamingStart) {
        clearAllChatStates();
        defaultConfigureState();
        startNewChat();
      }
    };
  }, []);

  const handleSelectChatHistory = () => {
    const findChatHistory = chatHistory?.find(
      (chatHistory) => chatHistory.id === location?.state?.selectedChatHistoryId
    );
    if (findChatHistory) {
      dispatch(setSelectedChatHistory(findChatHistory));
      const { chatTaxonomies } = findChatHistory;
      const selectedTaxonomies = chatTaxonomies[0];

      const industryFilter = selectedTaxonomies.tags.find(
        (filter: ITags) => filter.taxonomyName === TFiltersType.Industry
      );
      const functionFilter = selectedTaxonomies.tags.find(
        (filter: ITags) => filter.taxonomyName === TFiltersType.Functions
      );
      const assetTypeFilter = selectedTaxonomies.tags.find(
        (filter: ITags) => filter.taxonomyName === TFiltersType.AssetType
      );

      dispatch(
        setFiltersData([
          {
            type: TFiltersType.Industry,
            data: convertToString(industryFilter?.taxonomyNodeIds) || [],
          },
          {
            type: TFiltersType.Functions,
            data: convertToString(functionFilter?.taxonomyNodeIds) || [],
          },
          {
            type: TFiltersType.AssetType,
            data: convertToString(assetTypeFilter?.taxonomyNodeIds) || [],
          },
          { type: TFiltersType.FileType, data: selectedTaxonomies.fileType },
        ])
      );
      dispatch(setFilterDateRange([selectedTaxonomies.startDate, selectedTaxonomies.endDate]));
    }
    navigate(PATHS.conversationalSearch);
  };

  const handleEnterKeyAction = (e: any) => {
    if (e.key === "Enter" && question.trim()) {
      if (selectedChatHistory.id !== -1) {
        dispatch(removeFilterData([]));
        dispatch(setAllFileTypesSelected(fileType));
        dispatch(setAllAssetTypesSelected(getAllIds(assetTypeTaxonomy)));
      }
      clearAllChatStates();
      defaultConfigureState();
      dispatch(setUserQuestion(question.trim()));
      setQuestion("");
    }
  };

  return (
    <div className="content conversational-search-page sidebar-with-content">
      <div className="content-right">
        {showStartNewTopicMessage === true && (
          <div className="new-topic-alert">
            <img src={IMAGES.alertIcon} />
            <span>Start a new topic to apply more filters</span>
          </div>
        )}
        <div className="ai-search-bar">
          <h1>
            Discover <b>insights</b> with Conversational AI Search
          </h1>
          <div className="ai-search-input position-relative">
            <Space direction="vertical">
              {/* <Search placeholder=" Write your query in natural language, include all the relevant information you need" /> */}
            </Space>
            <img
              className="search-icon position-absolute"
              src={IMAGES.searchIcon}
              alt="Search Icon"
            />
            <Input
              type="text"
              value={question}
              placeholder="Write your query in natural language, include all the relevant information you need"
              onKeyDownCapture={handleEnterKeyAction}
              onChange={(e) => {
                setQuestion(e.target.value);
              }}
            />
          </div>
          <div className="new-topic acn-flex acn-flex-middle acn-flex-right">
            <div className="recent-history" ref={chatHistoryRef}>
              <Tooltip overlayClassName="tooltip-text" color="#fff" title={"Chat history"}>
                <img
                  className="historyIcon"
                  src={historyIcon}
                  alt="Recent history"
                  onClick={() => dispatch(setIsOpenChatHistory(true))}
                />
              </Tooltip>

              {isOpenChatHistory && <RecentChatHistoryPopUp />}
            </div>
            <span
              className="topic-text"
              onClick={() => {
                clearAllChatStates();
                defaultConfigureState();
                startNewChat();
                dispatch(removeFilterData([]));
                dispatch(setAllFileTypesSelected(fileType));
                dispatch(setAllAssetTypesSelected(getAllIds(assetTypeTaxonomy)));
              }}>
              New topic
            </span>
          </div>
        </div>
        <div className="assets-found">
          <Chat />
        </div>
      </div>
    </div>
  );
};

export default ConversationalSearch;
