import { Button, Card, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  generationFields,
  setUpdateFields,
  setUpdateIsDisable,
  setUpdateIsShow,
} from "../../../store/generation/businessProposal/businessProposalSlice";
import { IMAGES } from "../../../shared";
import { agendaColumn, getFormattedAgenda } from "../../../helper/GenerationHelper";
import { toast } from "react-toastify";

const agendaRes = [
  {
    id: 111,
    agenda: "agenda1",
    is_selected: false,
  },
  {
    id: 222,
    agenda: "agenda2",
    is_selected: false,
  },
  { id: 333, agenda: "agenda3", is_selected: false },
  {
    id: 444,
    agenda: "agenda4",
    is_selected: false,
  },
];

const Agenda = () => {
  const dispatch = useDispatch();
  const { isLoading, isDisable, fields } = useSelector(
    (state: RootState) => state.busProposalSlice
  );

  const handleSelectAgenda = (keys: React.Key[]) => {
    dispatch(
      setUpdateFields({
        field: generationFields.agenda,
        value: keys,
      })
    );
  };

  const rowSelection = {
    columnTitle: () => <div style={{ visibility: "hidden" }}></div>,
    selectedRowKeys: fields?.agenda || [],
    onChange: handleSelectAgenda,
  };

  const handleNext = async () => {
    if (fields?.agenda && fields.agenda?.length) {
      dispatch(setUpdateIsShow({ field: generationFields.competitors, value: true }));
      dispatch(setUpdateIsDisable({ field: generationFields.industryFun, value: true }));
      dispatch(setUpdateIsDisable({ field: generationFields.agenda, value: true }));
    } else {
      toast.error("Please select agenda");
    }
  };

  return (
    <>
      <Card className="card-item">
        <div className="card-header">
          <span className="card-number">3</span>
          <h2>Selected Agenda</h2>
        </div>

        <div className="card-content">
          <Table
            rowSelection={rowSelection}
            columns={agendaColumn}
            dataSource={getFormattedAgenda(agendaRes)}
            pagination={false}
            rowClassName={isDisable?.agenda || isLoading?.agenda ? "disable-table-row" : ""}
          />
        </div>

        <div className="card-footer acn-flex acn-flex-end">
          <div className="card-footer-right acn-flex acn-flex-middle">
            {isLoading?.agenda && (
              <div className="processing-wrap">
                <img src={IMAGES.loadingSpinner} alt="loading" className="spinnerSvg" />
                Processing
              </div>
            )}
            <Button
              type="primary"
              className="outline-btn"
              disabled={isDisable?.agenda || isLoading?.agenda}
              onClick={handleNext}>
              Next
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
};

export default Agenda;
