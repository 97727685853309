import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextArea from "antd/es/input/TextArea";
import { LeftOutlined, LoadingOutlined } from "@ant-design/icons";
import { useLocation, useNavigate, useParams, useBlocker } from "react-router-dom";
import { toast } from "react-toastify";
import { persistStore } from "redux-persist";
import {
  Button,
  Card,
  Col,
  Row,
  Space,
  Typography,
  Tag,
  Input,
  Select,
  Empty,
  Spin,
  Tooltip,
  Switch,
  Skeleton,
  Modal,
  Image,
} from "antd";

import EditContentTags from "./EditContentTags/EditContentTags";
import { DeleteModal } from "../../components";
import { RootState, store } from "../../../store";
import { IUser } from "../../../store/userManagement/user.interface";
import { ITaxonomyTree } from "../../../store/taxonomy/taxonomy.interface";

import {
  IMAGES,
  uniqueId,
  ShowTaxonomyLimit,
  TaxonomyTitle,
  getFormattedDate,
  getUserRole,
  UserRoles,
  FromWhere,
  PATHS,
  AssetUploadStatus,
  handleOpenPdfDoc,
  removeLocalstorage,
  Images,
  SummaryStaus,
} from "../../../shared";
import {
  IContent,
  IContentUser,
  IContributor,
  IMappedIndustry,
  IFormattedIndustry,
  IMappedIndustryChildren,
  IDropdownOption,
} from "../../../store/contentManagement/content.interface";
import {
  editAssetById,
  getAssetById,
  getAllTagsByAssestId,
  updateTagsByAssestId,
  saveTagsByAssestId,
  enablePublish,
  disablePublish,
  deleteAssetById,
  updateDownloadLogs,
  generateSummary,
  getAssetDetailsByAssetId,
} from "../../../services/contentManagement";
import { getTaxonomyByTitle } from "../../../services/taxonomy";
import {
  contentByIdInitialValue,
  setContentById,
  setIsSummaryGenerationLoading,
  setIsUserListLoading,
  setUserListPageCount,
} from "../../../store/contentManagement/contentSlice";

import { getPDFPreSignedUrl } from "../../../services/conversationalSearch";
import { fetchAllUsers } from "../../../services/userManagement";
import "./ViewEditContentManagement.scss";
import Markdown from "react-markdown";

const ViewEditContentManagement = () => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [selectedOwner, setSelectedOwner] = useState({ value: "", key: "-1" });
  const [content, setContent] = useState<IContent>(contentByIdInitialValue);
  const [selectedContributorId, setSelectedContributorId] = useState<number>(-1);
  const [addContributors, setAddContributors] = useState({ value: "", key: "-1" });
  const [isOpenOwnerRemoveModal, setIsOpenOwnerRemoveModal] = useState<boolean>(false);
  const [isOpenPeopleRemoveModal, setIsOpenPeopleRemoveModal] = useState<boolean>(false);
  const [isAddContributorVisible, setIsAddContributorVisible] = useState<boolean>(false);
  const [isPdfDownloading, setIsPdfDownloading] = useState<boolean>(false);
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
  const [selectedFunction, setSelectedFunction] = useState<string[]>([]);
  const [isTagsEdited, setIsTagsEdited] = useState<boolean>(false);
  const [showIndustryLimit, setShowIndustryLimit] = useState<number>(ShowTaxonomyLimit.Industry);
  const [showFunctionLimit, setShowFunctionLimit] = useState<number>(ShowTaxonomyLimit.Function);
  const [selectedAssetType, setSelectedAssetType] = useState<null | string>(null);
  const [selectedReferenceKeywords, setSelectedReferenceKeywords] = useState<string[]>([]);
  const [isShowUnsavedChangesModal, setIsShowUnsavedChangesModal] = useState<boolean>(false);
  const [isShowContributorSearch, setIsShowContributorSearch] = useState<boolean>(false);
  const [isShowCancelWarnModal, setIsShowCancelWarnModal] = useState<boolean>(false);
  const [isShowAssetDeleteModal, setIsShowAssetDeleteModal] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const [scaleStep] = useState(0.5);
  const {
    isEditContentLoading,
    contentById,
    selectedAllTagByAssestId,
    isContentByIdLoading,
    isTagsByAssestIdLoading,
    contentDeleteLoading,
    contentFilter,
    downloadLogError,
    userPageCount,
    isUserListLoading,
    isSummaryGenerationLoading,
    totalContentsCount,
  } = useSelector((state: RootState) => state.content);
  const { industryTaxonomy, functionTaxonomy, assetTypeTaxonomy, referenceKeywordsTaxonomy } =
    useSelector((state: RootState) => state.taxonomy);
  const { allUsers, totalUsersCount } = useSelector((state: RootState) => state.user);
  const [userList, setUserList] = useState<IUser[]>(allUsers);
  const [adminsList, setAdminsList] = useState<IUser[]>(allUsers);
  const [filteredContributors, setfilteredContributors] = useState<IUser[] | []>([]);

  useEffect(() => {
    dispatch(setUserListPageCount(2));
    const data = getFilteredContributors(userList);
    setfilteredContributors(data);
  }, []);

  const getFilteredContributors = (list: IUser[]) => {
    const filteredContributors = list.filter(
      (user) => !content.contributors.map((contributor) => contributor.id).includes(user.id)
    );
    return filteredContributors;
    // setfilteredContributors(filteredContributors);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isNewAssetUpload = location?.state?.isNewAssetUpload ?? false;

  const { assetId } = useParams();
  const currentAssetId = !!assetId ? parseInt(assetId) : -1;
  const loading = isContentByIdLoading || isTagsByAssestIdLoading;

  const mapDataToTreeSelect = (data: ITaxonomyTree[]): IMappedIndustryChildren[] => {
    return data.map((item) => {
      const node: IMappedIndustryChildren = {
        value: String(item.taxonomyNodeId),
        title: item.taxonomyNodeName,
        id: item.taxonomyNodeId,
      };

      if (item.children && item.children.length > 0) {
        node.children = mapDataToTreeSelect(item.children);
      }

      return node;
    });
  };

  const mappedContentIndustry = mapDataToTreeSelect(industryTaxonomy?.taxonomyTree ?? []);
  const mappedContentFunction = mapDataToTreeSelect(functionTaxonomy?.taxonomyTree ?? []);
  const mappedAssetType = assetTypeTaxonomy?.taxonomyTree
    ?.filter((item) => item.taxonomyNodeName)
    ?.map((item) => ({
      label: item.taxonomyNodeName,
      value: String(item.taxonomyNodeId),
    }));
  const mappedReferenceKeyword = getMappedReferenceKeyword();

  const assetTypeSelectedLabel = getAssetTypeSelectedLabel(selectedAssetType);
  const ReferenceKeywordSelectedLabel = getReferenceKeywordSelectedLabel(selectedReferenceKeywords);

  function getMappedReferenceKeyword() {
    let res: IDropdownOption[] = [];
    if (
      referenceKeywordsTaxonomy?.taxonomyTree &&
      referenceKeywordsTaxonomy?.taxonomyTree?.length
    ) {
      res = referenceKeywordsTaxonomy?.taxonomyTree
        .filter((item) => item.taxonomyNodeName)
        .map((item) => ({
          label: item.taxonomyNodeName,
          value: String(item.taxonomyNodeId),
        }));
    }

    return res;
  }

  function getReferenceKeywordSelectedLabel(ReferenceKeywords: string[]) {
    let labels: string[] = [];

    if (
      referenceKeywordsTaxonomy?.taxonomyTree &&
      referenceKeywordsTaxonomy?.taxonomyTree?.length
    ) {
      referenceKeywordsTaxonomy?.taxonomyTree.forEach((taxonomy) => {
        if (ReferenceKeywords.includes(String(taxonomy?.taxonomyNodeId))) {
          labels.push(taxonomy?.taxonomyNodeName);
        }
      });
    }

    return labels;
  }

  function getAssetTypeSelectedLabel(value: string | null) {
    let label = "";
    if (value) {
      assetTypeTaxonomy?.taxonomyTree?.forEach((assetType: any) => {
        if (assetType?.taxonomyNodeId === Number(value)) {
          label = assetType?.taxonomyNodeName;
          return label;
        }
      });
    }
    return label;
  }

  const findNodeNameByValue = (data: IMappedIndustry[], value: string): string => {
    for (const item of data) {
      if (item.value === value) {
        return item.title;
      }
      if (item.children) {
        const result = findNodeNameByValue(item.children, value);
        if (result) {
          return result;
        }
      }
    }
    return "";
  };

  function findParentNodesByValue(value: string, mappedTags: IMappedIndustry[]) {
    let parents: any[] = [];

    function traverse(node: IMappedIndustry, parentPath: IMappedIndustry[]) {
      if (node.value === value) {
        parents.push(parentPath);
      }
      if (node.children) {
        node.children.forEach((child: IMappedIndustry) => {
          traverse(child, [...parentPath, node]);
        });
      }
    }

    for (let item of mappedTags) {
      traverse(item, []);
    }
    if (parents && parents.length) {
      parents = parents[0];
    }

    return parents;
  }

  const getFormattedSelectedTags = (selectedTags: string[], mappedTags: IMappedIndustry[]) => {
    const formattedTags: IFormattedIndustry[] = [];

    if (selectedTags && selectedTags.length) {
      selectedTags.forEach((value: string) => {
        formattedTags.push({
          parents: findParentNodesByValue(value, mappedTags),
          value,
          id: value,
          title: findNodeNameByValue(mappedTags, value),
        });
      });
    }
    return formattedTags;
  };

  const formattedSelectedIndustryTag = getFormattedSelectedTags(
    selectedIndustries,
    mappedContentIndustry
  );
  const formattedSelectedFunctionTag = getFormattedSelectedTags(
    selectedFunction,
    mappedContentFunction
  );

  useEffect(() => {
    if (currentAssetId) getAssetById(currentAssetId, setContent, navigate);
    location?.state?.isEditMode ? setIsEditMode(true) : null;
  }, []);

  useEffect(() => {
    getTaxonomyByTitle(TaxonomyTitle.Industry);
    getTaxonomyByTitle(TaxonomyTitle.Function);
    getTaxonomyByTitle(TaxonomyTitle.AssetType);
    getTaxonomyByTitle(TaxonomyTitle.ReferenceKeywords);
    if (currentAssetId) getAllTagsByAssestId(currentAssetId);
  }, []);

  const handleSetTagsInDropdown = () => {
    const selectedIndustry = selectedAllTagByAssestId?.linkedTaxonomyNodes?.Industry || [];
    const selectedFunction = selectedAllTagByAssestId?.linkedTaxonomyNodes?.Function || [];

    if (selectedIndustry && selectedIndustry.length) {
      const values = selectedIndustry.map((id: number) => id.toString());
      setSelectedIndustries(values);
    }
    if (selectedFunction && selectedFunction.length) {
      const values = selectedFunction.map((id: number) => id.toString());
      setSelectedFunction(values);
    }

    const assetTypeSelected = selectedAllTagByAssestId?.linkedTaxonomyNodes?.["Asset Type"] ?? [];
    if (assetTypeSelected && assetTypeSelected.length) {
      setSelectedAssetType(String(assetTypeSelected[0]));
    }
  };

  const handleSetReferenceKeywordsDropdown = () => {
    const selectedReferenceKeywords =
      selectedAllTagByAssestId?.linkedTaxonomyNodes?.["Reference Keywords"] || [];

    if (selectedReferenceKeywords && selectedReferenceKeywords.length) {
      const values = selectedReferenceKeywords.map((id: number) => id.toString());
      setSelectedReferenceKeywords(values);
    }
  };

  useEffect(() => {
    handleSetTagsInDropdown();
    handleSetReferenceKeywordsDropdown();
  }, [selectedAllTagByAssestId]);

  const checkSaveChangesInputs = () => {
    if (!content?.title || content?.title.trim() === "") {
      toast.error("Title input cannot be empty");
      return true;
    }
  };

  const handleNavigateToContent = () => {
    const contentCurrPage = contentFilter?.currentPage ?? 1;
    const contentSearchText = contentFilter?.searchText ?? "";
    navigate(
      `${PATHS.adminContentManagement}?page=${contentCurrPage}&searchText=${contentSearchText}`
    );
  };

  const handleCancel = () => {
    if (getIsAnyAssestEdited()) {
      setIsShowCancelWarnModal(true);
    } else {
      handleResetChanges();
    }
  };

  const handleResetChanges = () => {
    if (content?.summaryStatus === SummaryStaus.PROCESSING) {
      handleUpdateSummaryStatus();
    }

    setIsEditMode(false);
    setIsAddContributorVisible(false);
    setContent(contentById);

    const selectedIndustry = (selectedAllTagByAssestId?.linkedTaxonomyNodes?.Industry || []).map(
      (id: number) => id.toString()
    );
    const selectedFunction = (selectedAllTagByAssestId?.linkedTaxonomyNodes?.Function || []).map(
      (id: number) => id.toString()
    );
    setSelectedIndustries(selectedIndustry);
    setSelectedFunction(selectedFunction);
    setIsShowContributorSearch(false);
    setIsShowCancelWarnModal(false);
    setSelectedOwner({ value: "", key: "-1" });
  };

  const getFormmatedAssetIds = () => {
    const prevSelectedIndustry = selectedAllTagByAssestId?.linkedTaxonomyNodes?.Industry || [];
    const prevSelectedFunction = selectedAllTagByAssestId?.linkedTaxonomyNodes?.Function || [];
    const prevSelectedAssetType =
      selectedAllTagByAssestId?.linkedTaxonomyNodes?.["Asset Type"] || [];
    const prevReferenceKeywordsIds =
      selectedAllTagByAssestId?.linkedTaxonomyNodes?.["Reference Keywords"] || [];
    const prevTaxonomyNodeIds = [
      ...prevSelectedIndustry,
      ...prevSelectedFunction,
      ...prevSelectedAssetType,
      ...prevReferenceKeywordsIds,
    ];

    const currSelectedIndustriesIds =
      selectedIndustries.map((industry: string) => Number(industry)) || [];
    const currSelectedFunctionIds =
      selectedFunction.map((industry: string) => Number(industry)) || [];
    const currAssetTypeId = selectedAssetType ? [Number(selectedAssetType)] : [];
    const currReferenceKeywordsIds =
      selectedReferenceKeywords.map((id: string) => Number(id)) || [];
    const currTaxonomyNodeIds = [
      ...currSelectedIndustriesIds,
      ...currSelectedFunctionIds,
      ...currAssetTypeId,
      ...currReferenceKeywordsIds,
    ];

    return { prevTaxonomyNodeIds, currTaxonomyNodeIds };
  };

  const handleUpdateContentTags = async (currentAssetId: number) => {
    const { prevTaxonomyNodeIds, currTaxonomyNodeIds } = getFormmatedAssetIds();

    if (isTagsEdited) {
      if (prevTaxonomyNodeIds && prevTaxonomyNodeIds.length) {
        await updateTagsByAssestId(currentAssetId, currTaxonomyNodeIds);
      } else {
        await saveTagsByAssestId(currentAssetId, currTaxonomyNodeIds);
      }
    }
    setShowIndustryLimit(ShowTaxonomyLimit.Industry);
    setShowFunctionLimit(ShowTaxonomyLimit.Function);
  };

  const handleResetRouteLocation = (currentAssetId: number) => {
    navigate(`${PATHS.viewEditContentManagement}/${currentAssetId}`, {
      state: { ...location?.state, isEditMode: false, isNewAssetUpload: false },
    });
  };

  const handleUpdateSummaryStatus = async () => {
    const contributors = contentById?.contributors.map((v) => ({ id: v?.id }));

    await editAssetById(
      currentAssetId,
      contentById?.title,
      contentById?.summary,
      contentById?.assetType,
      contentById?.user?.id,
      contributors,
      SummaryStaus.CANCELLED
    );
  };

  const handleSaveAssetFieldValue = async (currentAssetId: number) => {
    setIsEditMode(false);
    const contributors = content?.contributors.map((v) => ({ id: v?.id }));
    const prevTitle = contentById?.title ?? "";
    const prevSummary = contentById?.summary ?? "";
    const prevContentUserId = contentById?.user?.id ?? -1;

    const currTitle = content?.title ?? "";
    const currSummary = content?.summary ?? "";
    const currContentUserId = content?.user?.id ?? -1;

    if (
      prevTitle !== currTitle ||
      prevSummary !== currSummary ||
      getIsContributorsEdited() ||
      prevContentUserId !== currContentUserId
    ) {
      await editAssetById(
        currentAssetId,
        content?.title,
        content?.summary,
        content?.assetType,
        content?.user?.id,
        contributors,
        content?.summaryStatus || null
      );
      setContent(content);
      dispatch(setContentById(content));
    }

    handleUpdateContentTags(currentAssetId);
    setIsEditMode(false);
    setIsAddContributorVisible(false);
    setIsTagsEdited(false);
    setIsShowContributorSearch(false);
    handleResetRouteLocation(currentAssetId);
  };

  const handleSaveChanges = async () => {
    if (checkSaveChangesInputs()) return;
    handleSaveAssetFieldValue(currentAssetId);
  };

  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setContent((prev) => ({ ...prev, title: e.target.value }));
  };

  const handleChangeSummary = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setContent((prev) => ({ ...prev, summary: e.target.value }));
  };

  const onContributorsChange = (value: { key: string; value: string }) => {
    setAddContributors({ key: value?.key, value: value?.value });
  };

  const handleAddContributors = () => {
    const addedContributor: IContributor = {
      status: "",
      id: parseInt(addContributors?.key),
      username: addContributors?.value,
      name: addContributors?.value,
      email: "string,",
      userPermissions: [],
    };

    setContent((v) => ({
      ...v,
      contributors: [...v.contributors, addedContributor],
    }));
    setAddContributors({ key: "-1", value: "" });
  };

  const onSearch = (val: any) => {
    console.log("search:", val);
  };

  const handleRemoveContributor = () => {
    setIsOpenPeopleRemoveModal(false);

    const contributors = content?.contributors.filter((v) => v.id !== selectedContributorId);
    setContent({
      ...content,
      contributors,
    });
  };

  const handleRemoveContributorModal = (id: number) => {
    setSelectedContributorId(id);
    setIsOpenPeopleRemoveModal(true);
  };

  const handleOwnerRemove = () => {
    setIsOpenOwnerRemoveModal(false);

    const removeUser: IContentUser = {
      email: "",
      id: -1,
      name: "",
      status: "",
      username: "",
      userPermissions: [],
    };
    setContent({
      ...content,
      user: removeUser,
    });
  };

  const handleSelectAssestType = (value: string) => {
    setSelectedAssetType(value);
    setIsTagsEdited(true);
  };

  const handleSelectReferenceKeywords = (value: string[]) => {
    if (value.includes("selectAll")) {
      if (mappedReferenceKeyword && mappedReferenceKeyword.length) {
        const ids: string[] = mappedReferenceKeyword.map((item) => item.value);
        setSelectedReferenceKeywords(ids);
      }
    } else {
      setSelectedReferenceKeywords(value);
    }

    setIsTagsEdited(true);
  };

  const handleSelectOwner = (v: { value: string; key: string }) => {
    setSelectedOwner({ value: v.value, key: v.key });
  };

  const handleChangeOwnerAction = () => {
    const newOwner = adminsList.filter((item) => item.id === Number(selectedOwner.key));

    const removeUser: IContentUser = {
      email: newOwner[0].email,
      id: newOwner[0].id ?? -1,
      name: newOwner[0].name,
      status: newOwner[0].status,
      username: newOwner[0].username,
      userPermissions: newOwner[0].userPermissions,
    };
    setContent({
      ...content,
      user: removeUser,
    });
    setSelectedOwner({ key: "-1", value: "" });
  };

  const AvatarAndTitleSkeleton = () => {
    return (
      <li className="ant-list-item">
        <div className="ant-list-item-meta">
          <div className="ant-list-item-meta-avatar">
            <span className="ant-avatar ant-avatar-circle ant-avatar-image css-1k979oh">
              <Skeleton.Avatar className="mt-2" size={45} active={true} />
            </span>
          </div>

          <div className="ant-list-item-meta-content">
            <Skeleton.Input className="mt-1 content" active={true} />
          </div>
        </div>
      </li>
    );
  };

  const handleScroll = async (e: any) => {
    const { target } = e;
    if (Math.round(target.scrollTop + target.offsetHeight) === Math.round(target.scrollHeight)) {
      try {
        if (userList.length < totalUsersCount) {
          dispatch(setIsUserListLoading(true));
          const res = await fetchAllUsers("", userPageCount);
          dispatch(setUserListPageCount(userPageCount + 1));
          const data = getFilteredContributors(res);
          setfilteredContributors([...filteredContributors, ...data]);
          setUserList([...userList, ...res]);
          setAdminsList([...adminsList, ...res]);
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setIsUserListLoading(false));
      }
    }
  };

  const renderAssetAndContributor = () => {
    if (isAddContributorVisible) {
      return (
        <Card>
          {/* Add people */}
          <div className="add-people">
            <div className="add-people-inner acn-flex acn-flex-middle">
              <div className="people-input position-relative">
                <img
                  className="search-icon position-absolute"
                  src={IMAGES.searchIcon}
                  alt="Search Icon"
                />
                <Select
                  placeholder="Search names or emails"
                  optionLabelProp="value"
                  showSearch
                  onChange={onContributorsChange}
                  onSearch={onSearch}
                  value={addContributors}
                  labelInValue
                  suffixIcon={null}
                  popupClassName="people-input-dropdoun"
                  onPopupScroll={handleScroll}>
                  {filteredContributors &&
                    filteredContributors.map((v) => {
                      return (
                        <Select.Option key={v.id} value={v?.name} label={v?.name}>
                          <div className="people-info">
                            <img src={IMAGES.avatarDefault} />
                            <span className="user-name">{v?.name}</span>
                            <span className="user-email">{v?.email}</span>
                          </div>
                        </Select.Option>
                      );
                    })}
                </Select>
              </div>
              <Button
                type="primary"
                className="add-people-btn"
                onClick={handleAddContributors}
                disabled={addContributors?.value === ""}>
                Add people
              </Button>
            </div>

            {/* People List */}
            <div className="people-list-items">
              <ul className="ant-list-items sidebar-list">
                {content?.contributors &&
                  content?.contributors.map((v) => {
                    return (
                      <li className="ant-list-item" key={uniqueId()}>
                        <div className="ant-list-item-meta">
                          <div className="ant-list-item-meta-avatar">
                            <span className="ant-avatar ant-avatar-circle ant-avatar-image css-1k979oh">
                              <img src={IMAGES.avatar1} alt="avatar" />
                            </span>
                          </div>
                          <div className="ant-list-item-meta-content">
                            <h5 className="ant-list-item-meta-title">{v?.name}</h5>
                            {/* Edit View */}
                            <p
                              className="link-action"
                              onClick={() => handleRemoveContributorModal(v?.id)}>
                              Remove
                            </p>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="bottom-btn">
              <Button
                type="primary"
                className="save-changes"
                onClick={() => setIsAddContributorVisible(false)}>
                Cancel
              </Button>
            </div>
          </div>
        </Card>
      );
    } else {
      return (
        <Card>
          {loading ? (
            <Skeleton.Input className="skeleton-input" active={true} />
          ) : (
            <h4 className="card-heading">
              Asset Owner
              {isEditMode && !content?.user?.name && (
                <span className="choose-span">(choose asset owner)</span>
              )}
            </h4>
          )}

          <ul
            className={
              (content?.user?.name === "" || content?.user === null) && isEditMode
                ? "ant-list-items sidebar-list admin-list"
                : "ant-list-items sidebar-list"
            }>
            {(content?.user?.name === "" || content?.user === null) && isEditMode ? (
              <div className="add-people-inner acn-flex acn-flex-middle">
                <div className="people-input position-relative">
                  <img
                    className="search-icon position-absolute"
                    src={IMAGES.searchIcon}
                    alt="Search Icon"
                  />
                  <Select
                    placeholder="Search names or emails"
                    optionLabelProp="value"
                    showSearch
                    onChange={handleSelectOwner}
                    onSearch={onSearch}
                    value={selectedOwner}
                    labelInValue
                    suffixIcon={null}
                    popupClassName="people-input-dropdoun"
                    // onPopupScroll={handleScroll}
                  >
                    {adminsList.map((v) => {
                      return (
                        <Select.Option key={v.id} value={v?.name} label={v?.name}>
                          <div className="people-info">
                            <img src={IMAGES.avatarDefault} />
                            <span className="user-name">{v?.name}</span>
                            <span className="user-email">{v?.email}</span>
                          </div>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
                <Button
                  type="primary"
                  className="add-people-btn"
                  onClick={handleChangeOwnerAction}
                  disabled={selectedOwner?.value === ""}>
                  Change owner
                </Button>
              </div>
            ) : content?.user?.name === "" || content?.user === null ? (
              loading ? (
                <AvatarAndTitleSkeleton />
              ) : (
                <div className="empty-owner">
                  <Empty />
                </div>
              )
            ) : (
              <li className="ant-list-item">
                <div className="ant-list-item-meta">
                  <div className="ant-list-item-meta-avatar">
                    <span
                      className={
                        content.user.status === AssetUploadStatus.DELETED
                          ? "ant-avatar ant-avatar-circle ant-avatar-image css-1k979oh alt-avatar-grey-user-image"
                          : "ant-avatar ant-avatar-circle ant-avatar-image css-1k979oh"
                      }>
                      {loading ? (
                        <Skeleton.Avatar className="mt-2" size={45} active={true} />
                      ) : (
                        <img src={IMAGES.userProfile} alt="avatar" />
                      )}
                    </span>
                  </div>

                  {/* Edit View */}
                  <div className="ant-list-item-meta-content">
                    {loading ? (
                      <Skeleton.Input className="mt-1 content" active={true} />
                    ) : (
                      <div>
                        <h5
                          className={
                            content.user.status === AssetUploadStatus.DELETED
                              ? "ant-list-item-meta-delete-user-title"
                              : "ant-list-item-meta-title"
                          }>
                          {content?.user?.status === AssetUploadStatus.DELETED
                            ? "Deleted User"
                            : content?.user?.name}
                        </h5>

                        {content?.user?.status !== AssetUploadStatus.DELETED && (
                          <p
                            className="link-action"
                            hidden={isEditMode}
                            onClick={() => window.open(`mailto:${content?.user?.email}`)}>
                            <img src={IMAGES.emailIcon} alt="email-icon" />
                          </p>
                        )}

                        <p
                          className="link-action"
                          hidden={!isEditMode}
                          onClick={() => setIsOpenOwnerRemoveModal(true)}>
                          Remove
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </li>
            )}
          </ul>

          <div className="contributor-list-inner">
            {loading ? (
              <Skeleton.Input className="skeleton-input" active={true} />
            ) : (
              <h4 className="card-heading acn-flex acn-flex-middle acn-flex-between">
                Contributor(s)
                {/* Edit View */}
                <div hidden={!isEditMode || isShowContributorSearch}>
                  <Button
                    className="add-user-button acn-flex acn-flex-middle"
                    onClick={() => setIsShowContributorSearch(true)}>
                    + Add people
                  </Button>
                </div>
              </h4>
            )}

            <div
              hidden={!isEditMode || !isShowContributorSearch}
              className="add-people-inner acn-flex acn-flex-middle">
              <div className="people-input position-relative">
                <img
                  className="search-icon position-absolute"
                  src={IMAGES.searchIcon}
                  alt="Search Icon"
                />
                <Select
                  placeholder="Search names or emails"
                  optionLabelProp="value"
                  showSearch
                  onChange={onContributorsChange}
                  onSearch={onSearch}
                  value={addContributors}
                  labelInValue
                  suffixIcon={null}
                  popupClassName="people-input-dropdoun"
                  onPopupScroll={handleScroll}>
                  {filteredContributors &&
                    filteredContributors.map((v) => {
                      return (
                        <Select.Option key={v.id} value={v?.name} label={v?.name}>
                          <div className="people-info">
                            <img src={IMAGES.avatarDefault} />
                            <span className="user-name">{v?.name}</span>
                            <span className="user-email">{v?.email}</span>
                          </div>
                        </Select.Option>
                      );
                    })}
                  {isUserListLoading && (
                    <div className="loaderItem">
                      <img src={IMAGES.loadingSpinner} alt="loading" className="spinnerSvg" />
                    </div>
                  )}
                </Select>
              </div>
              <Button
                type="primary"
                className="add-people-btn"
                onClick={handleAddContributors}
                disabled={addContributors?.value === ""}>
                Add people
              </Button>
            </div>

            <ul className="ant-list-items sidebar-list">
              {content?.contributors && content?.contributors?.length ? (
                content?.contributors.map((v) => {
                  return (
                    <li className="ant-list-item" key={uniqueId()}>
                      <div className="ant-list-item-meta">
                        <div className="ant-list-item-meta-avatar">
                          <span
                            className={
                              v.status === AssetUploadStatus.DELETED
                                ? "ant-avatar ant-avatar-circle ant-avatar-image css-1k979oh alt-avatar-grey-user-image"
                                : "ant-avatar ant-avatar-circle ant-avatar-image css-1k979oh"
                            }>
                            <img src={IMAGES.userProfile} alt="avatar" />
                          </span>
                        </div>
                        <div className="ant-list-item-meta-content">
                          <h5
                            className={
                              v.status === AssetUploadStatus.DELETED
                                ? "ant-list-item-meta-delete-user-title"
                                : "ant-list-item-meta-title"
                            }>
                            {v?.status === AssetUploadStatus.DELETED ? "Deleted User" : v?.name}
                          </h5>

                          {/* Edit View */}
                          <p
                            className="link-action"
                            hidden={!isEditMode}
                            onClick={() => handleRemoveContributorModal(v?.id)}>
                            Remove
                          </p>
                        </div>
                      </div>
                    </li>
                  );
                })
              ) : loading ? (
                [...Array(3)].map((_, index) => {
                  return (
                    <div key={index}>
                      <AvatarAndTitleSkeleton />
                    </div>
                  );
                })
              ) : (
                <div className="no-record">No record found</div>
              )}
            </ul>

            {isShowContributorSearch && (
              <div className="bottom-btn">
                <Button
                  type="primary"
                  className="save-changes"
                  onClick={() => setIsShowContributorSearch(false)}>
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </Card>
      );
    }
  };

  const isShowEditButton = () =>
    getUserRole() === UserRoles.Admin ||
    (getUserRole() === UserRoles.ContentEditor && content?.is_editable === true);

  const handleNavigateBack = () => {
    const fromWhere = location?.state?.fromWhere || "";

    if (fromWhere === FromWhere.BrowseCategory) {
      navigate(PATHS.browseCategory, {
        state: {
          data: location?.state?.browseData,
          browseCategoryCurrLevel: location?.state?.browseCategoryCurrLevel,
          browseSelectedCategory: location?.state?.browseSelectedCategory,
        },
      });
    } else if (fromWhere === FromWhere.Home) {
      navigate(PATHS.home);
    } else if (location?.state?.fromWhere === FromWhere.ConversationalSearch) {
      navigate(PATHS.conversationalSearch, {
        state: {
          fromWhere: FromWhere.viewEditContentManagement,
          selectedChatHistoryId: location?.state?.selectedChatHistoryId,
        },
      });
    } else {
      handleNavigateToContent();
    }
  };

  const getIsContributorsEdited = (): boolean => {
    const prevContributorsId = (contentById?.contributors || []).map((item: any) => item?.id);
    const currContributorsId = (content?.contributors || []).map((item) => item?.id);
    let isContributorsEdited = true;

    if (prevContributorsId.length === currContributorsId.length) {
      if (prevContributorsId.length === 0 && currContributorsId.length === 0) {
        isContributorsEdited = false;
      } else {
        for (const id of prevContributorsId) {
          if (currContributorsId.includes(id)) {
            isContributorsEdited = false;
          } else {
            isContributorsEdited = true;
            break;
          }
        }
      }
    } else {
      isContributorsEdited = true;
    }

    return isContributorsEdited;
  };

  const getIsAssetTagsEdited = (): boolean => {
    const { prevTaxonomyNodeIds, currTaxonomyNodeIds } = getFormmatedAssetIds();
    let isAssetTagsEdited = false;

    if (prevTaxonomyNodeIds.length === currTaxonomyNodeIds.length) {
      if (prevTaxonomyNodeIds.length === 0 && currTaxonomyNodeIds.length === 0) {
        isAssetTagsEdited = false;
      } else {
        for (const id of prevTaxonomyNodeIds) {
          if (currTaxonomyNodeIds.includes(id)) {
            isAssetTagsEdited = false;
          } else {
            isAssetTagsEdited = true;
            break;
          }
        }
      }
    } else {
      isAssetTagsEdited = true;
    }

    return isAssetTagsEdited;
  };

  const getIsAnyAssestEdited = (): boolean => {
    const isContributorsEdited = getIsContributorsEdited();
    const isAssetTagsEdited = getIsAssetTagsEdited();

    const prevTitle = contentById?.title ?? "";
    const prevSummary = contentById?.summary ?? "";
    const prevContentUserId = contentById?.user?.id ?? -1;

    const currTitle = content?.title ?? "";
    const currSummary = content?.summary ?? "";
    const currContentUserId = content?.user?.id ?? -1;
    const currSummaryStatus = content?.summaryStatus ?? "";

    if (
      prevTitle !== currTitle ||
      prevSummary !== currSummary ||
      prevContentUserId !== currContentUserId ||
      isContributorsEdited ||
      isAssetTagsEdited ||
      currSummaryStatus === SummaryStaus.PROCESSING
    ) {
      return true;
    }
    return false;
  };

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      getIsAnyAssestEdited() && isEditMode && currentLocation.pathname !== nextLocation.pathname
  );

  const handlecancelUnsavedChanges = () => {
    setIsShowUnsavedChangesModal(false);
    blocker.state === "blocked" && blocker.reset();
  };

  const handleProceedUnsavedChanges = () => {
    if (blocker.state === "blocked") {
      if (content?.summaryStatus === SummaryStaus.PROCESSING) {
        handleUpdateSummaryStatus();
      }
      blocker.proceed();
    }
  };

  useEffect(() => {
    if (blocker?.state === "blocked") {
      setIsShowUnsavedChangesModal(true);
    }
  }, [blocker]);

  const handlePublishedValidation = () => {
    let flag = false;
    let messageList = [];

    if (!content?.title || content?.title.trim() === "") {
      messageList.push("Title");
      flag = true;
    }
    if (!content?.summary || content?.summary.trim() === "") {
      messageList.push("Summary");
      flag = true;
    }
    if (!selectedAssetType) {
      messageList.push("Asset type");
      flag = true;
    }

    if (messageList && messageList.length) {
      let message = "";
      messageList.forEach((item, index) => {
        message =
          message +
          `${
            index === 0 ? "" : index > 0 && index === messageList.length - 1 ? " and " : ", "
          }${item}`;
      });

      if (message) {
        message = message + ` ${messageList.length > 1 ? "inputs" : "input"} cannot be empty`;
        toast.error(message);
      }
    }

    return flag;
  };

  const handlePublishChange = async () => {
    if (handlePublishedValidation()) return;

    const isPublishedValue = ["TRUE", true].includes(content?.isPublished) ? true : false;
    if (!isPublishedValue) {
      const response = await enablePublish(content?.asset_id);
      setContent((prev) => ({
        ...prev,
        isPublished: "TRUE",
        publishedOn: response?.data?.publishedOn,
      }));
    } else {
      const response = await disablePublish(content?.asset_id);
      setContent((prev) => ({
        ...prev,
        isPublished: "FALSE",
        publishedOn: response?.data?.publishedOn,
      }));
    }
  };

  const handleSetAutoRefreshedData = () => {
    const prevAssetStatus = content?.assetStatus ?? "";
    const currAssetStatus = contentById?.assetStatus ?? "";
    const prevThumbnailPreSignedUrl = content?.thumbnail_pre_signed_url ?? "";
    const currThumbnailPreSignedUrl = contentById?.thumbnail_pre_signed_url ?? "";
    const prevSummaryStatus = content?.summaryStatus || "";
    const currSummaryStatus = contentById?.summaryStatus || "";

    if (
      prevAssetStatus !== currAssetStatus ||
      prevThumbnailPreSignedUrl !== currThumbnailPreSignedUrl ||
      prevSummaryStatus !== currSummaryStatus
    ) {
      setContent((prev) => ({
        ...prev,
        assetStatus: contentById?.assetStatus,
        thumbnail_pre_signed_url: contentById?.thumbnail_pre_signed_url,
        summaryStatus: contentById?.summaryStatus,
      }));
    }
  };

  useEffect(() => {
    handleSetAutoRefreshedData();
  }, [contentById, isNewAssetUpload]);

  const handleSetTitleOnNewUpload = () => {
    if (isNewAssetUpload && !content.title) {
      let title = "";

      if (contentById.asset_url) {
        title = contentById.asset_url;
      } else {
        title = contentById.original_file_name;
      }
      setContent((prev) => ({
        ...prev,
        title: title,
      }));
    }
  };

  useEffect(() => {
    handleSetTitleOnNewUpload();
  }, [contentById, isNewAssetUpload]);

  const handleDeleteAsset = async () => {
    setIsEditMode(false);
    if (currentAssetId) {
      await deleteAssetById(currentAssetId, "", 1, "ALL", totalContentsCount, false);
    }
    setIsShowAssetDeleteModal(false);
    setIsEditMode(false);
    handleNavigateToContent();
  };

  const onHandleOkAction = () => {
    persistStore(store).purge();
    removeLocalstorage();
    indexedDB.deleteDatabase("localforage");
    indexedDB.deleteDatabase("knowledge-management");
    navigate(PATHS.login);
  };

  const handleChangeIndustry = (selectedNodes: string[]) => {
    setIsTagsEdited(true);
    setSelectedIndustries(selectedNodes);
  };

  const handleChangeFunction = (selectedNodes: string[]) => {
    setIsTagsEdited(true);
    setSelectedFunction(selectedNodes);
  };

  const handleDownloadFile = async () => {
    try {
      const fileName = content?.inputFileName ? content.inputFileName : content?.file_name;
      const res = await getPDFPreSignedUrl(fileName);
      updateDownloadLogs(res, content, setIsPdfDownloading);
    } catch (error) {
      console.log("Error while fetching url", error);
    }
  };

  const getIsShowGenerateSummaryBtn = () =>
    [UserRoles.Admin, UserRoles.ContentEditor].includes(getUserRole()) && isEditMode;

  const handleGenerateSummary = async () => {
    dispatch(setIsSummaryGenerationLoading(true));
    const res = await generateSummary(currentAssetId);

    if (res?.data === "Summary generation under process.") {
      setContent((prev) => ({ ...prev, summaryStatus: SummaryStaus.PROCESSING }));
    } else {
      dispatch(setIsSummaryGenerationLoading(false));
    }
  };

  useEffect(() => {
    let contentInterval: any;
    const summaryStatus = content?.summaryStatus || "";

    if (isEditMode && [String(SummaryStaus.PROCESSING)].includes(summaryStatus)) {
      contentInterval = setInterval(async () => {
        let res = await getAssetDetailsByAssetId(currentAssetId);

        if (res?.summaryStatus === SummaryStaus.COMPLETED) {
          setContent((prev) => ({
            ...prev,
            summaryStatus: res?.summaryStatus,
            summary: res?.ai_generated_summary,
          }));
        }
      }, 5000);
    } else {
      clearInterval(contentInterval);
      dispatch(setIsSummaryGenerationLoading(false));
    }

    return () => {
      clearInterval(contentInterval);
    };
  }, [content]);

  return (
    <>
      <div className="asset-container view-edit-content-management">
        <div className="asset-block1 asset-edit-bar">
          <div
            hidden={isEditMode}
            className="page-title-box acn-flex acn-flex-middle acn-flex-between">
            <Typography className="card-heading">
              <div className="acn-flex">
                <Button className="back-arrow mr-5" onClick={handleNavigateBack}>
                  <LeftOutlined />
                </Button>
                <span className="back-text">Back</span>
              </div>
            </Typography>

            {!loading && (
              <div className="acn-flex acn-flex-middle">
                {content.assetStatus === AssetUploadStatus.COMPLETED && isShowEditButton() && (
                  <div className="published-box" hidden={isEditMode}>
                    <span className="published-label">Published:</span>
                    <Switch
                      checked={["TRUE", true].includes(content?.isPublished) ? true : false}
                      onChange={handlePublishChange}
                    />
                  </div>
                )}
                {isShowEditButton() && (
                  <Button
                    className="add-user-button acn-flex acn-flex-middle"
                    onClick={() => setIsEditMode(true)}>
                    <span className="edit-icon acn-flex acn-flex-middle acn-flex-center">
                      <img src={IMAGES.editIcon} alt="add" />
                    </span>
                    Edit
                  </Button>
                )}
              </div>
            )}
          </div>

          {/* Edit View */}
          <div
            hidden={!isEditMode}
            className="page-title-box page-title-edit-mode acn-flex acn-flex-middle acn-flex-right">
            {isNewAssetUpload ? (
              <Button
                className="add-user-button delete-button acn-flex acn-flex-middle mr-5"
                onClick={() => setIsShowAssetDeleteModal(true)}>
                <img src={IMAGES.deleteIcon} alt="upload file" />
                Delete
              </Button>
            ) : (
              <Button
                className="add-user-button acn-flex acn-flex-middle mr-5"
                onClick={handleCancel}>
                Cancel
              </Button>
            )}

            <Button
              className="add-user-button btn-fill acn-flex acn-flex-middle"
              onClick={handleSaveChanges}
              disabled={content?.summaryStatus === SummaryStaus.PROCESSING}
              loading={isEditContentLoading}>
              {isNewAssetUpload ? "Upload asset" : "Save changes"}
            </Button>
          </div>
        </div>

        <div className="view-edit-scroll">
          <Row>
            <Col className="asset-block1 radius-0" span={24}>
              <Card>
                <div className="asset-block1-card">
                  <Row>
                    <Col className="asset-block1-left" span={7}>
                      <Card>
                        {loading ? (
                          <Skeleton.Image className="skeleton-image" active={true} />
                        ) : isNewAssetUpload && !content?.thumbnail_pre_signed_url ? (
                          <div
                            className="thumbnail"
                            style={{
                              backgroundImage: `url(${content?.thumbnail_pre_signed_url})`,
                            }}>
                            <div className="file-uploaded">
                              <img src={IMAGES.fileUploadedIcon} alt="upload file" />
                              <p>File uploaded</p>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="thumbnail"
                            style={{
                              backgroundImage: `url(${content?.thumbnail_pre_signed_url})`,
                            }}></div>
                        )}

                        <span className="card-content">
                          {loading ? (
                            <Skeleton.Input className="skeleton-input content mt-1" active={true} />
                          ) : (
                            <Tooltip
                              className="title-span"
                              overlayClassName="tooltip-text"
                              color="#fff"
                              title={"Title"}>
                              {Images.includes(content?.file_type || "") ? (
                                <span onClick={() => setVisible(true)}>{content.title}</span>
                              ) : (
                                <span
                                  onClick={() => {
                                    if (
                                      content.file_type === "xls" ||
                                      content.file_type === "xlsx"
                                    ) {
                                      toast.error(
                                        "The Excel file is not supported. Please download it to view."
                                      );
                                    } else {
                                      handleOpenPdfDoc(
                                        content?.asset_id,
                                        content.file_name,
                                        content?.pre_signed_url,
                                        content?.title,
                                        1,
                                        false
                                      );
                                    }
                                  }}>
                                  {content.title}
                                </span>
                              )}
                            </Tooltip>
                          )}

                          <div className="acn-flex acn-flex-middle">
                            {!loading &&
                              content?.is_editable &&
                              (content?.asset_url === null ? (
                                !isPdfDownloading ? (
                                  <span
                                    onClick={() => {
                                      handleDownloadFile();
                                    }}>
                                    <Tooltip
                                      overlayClassName="tooltip-text"
                                      color="#fff"
                                      title={"Download"}>
                                      <img
                                        src={IMAGES.lineDownloadIcon}
                                        alt="Icon Link"
                                        className="icon-link"
                                      />
                                    </Tooltip>
                                  </span>
                                ) : (
                                  <Spin
                                    indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                                  />
                                )
                              ) : (
                                <Tooltip
                                  overlayClassName="tooltip-text"
                                  color="#fff"
                                  title={"Open URL"}>
                                  <span
                                    onClick={() => {
                                      if (content?.asset_url) window.open(content?.asset_url);
                                      // handleOpenPdfDoc(content.file_name, content?.pre_signed_url);
                                    }}>
                                    <img
                                      src={IMAGES.iconLink}
                                      alt="Icon Link"
                                      className="icon-link"
                                    />
                                  </span>
                                </Tooltip>
                              ))}
                          </div>
                        </span>
                      </Card>
                    </Col>

                    <Col className="asset-block1-right" span={17}>
                      {loading ? (
                        <Skeleton.Input className="skeleton-input" active={true} />
                      ) : (
                        <h4 hidden={isEditMode}>{content?.title}</h4>
                      )}

                      {/* Edit View */}
                      <div className="edit-mode edit-mode-title" hidden={!isEditMode}>
                        <h4>
                          Title<span className="star">*</span>
                        </h4>
                        <Input
                          className="title-edit input-style"
                          value={content?.title}
                          onChange={handleChangeTitle}
                        />
                      </div>
                      <div hidden={isEditMode} className="date-row">
                        <span className="publi-shed">
                          {content?.isPublished === "TRUE" && "Published"}
                          {content?.isPublished === "FALSE" && "Draft"}
                        </span>
                        <span className="date">
                          {content?.modifiedOn &&
                            `Last update : ${getFormattedDate(content?.modifiedOn, "type1")}`}
                        </span>
                      </div>

                      {loading ? (
                        <>
                          <Skeleton.Input className="skeleton-input" active={true} />
                          <Skeleton.Input className="mt-2 skeleton-input content" active={true} />
                          <Skeleton.Input className="mt-2 skeleton-input content" active={true} />
                          <Skeleton.Input className="mt-2 skeleton-input content" active={true} />
                          <Skeleton.Input className="mt-2 skeleton-input content" active={true} />
                          <Skeleton.Input className="mt-2 skeleton-input content" active={true} />
                        </>
                      ) : (
                        <div className="acn-flex acn-flex-between">
                          <h4>Asset Summary</h4>

                          {getIsShowGenerateSummaryBtn() && (
                            <Button
                              className="generate-summary-button"
                              onClick={handleGenerateSummary}
                              loading={isSummaryGenerationLoading}
                              disabled={isSummaryGenerationLoading}>
                              {isSummaryGenerationLoading ? "Generating" : "Generate"} Summary
                            </Button>
                          )}
                        </div>
                      )}

                      {!loading && (
                        <p hidden={isEditMode}>
                          <Markdown className="mark-down">{content?.summary}</Markdown>
                        </p>
                      )}

                      {/* Edit View */}
                      <div className="edit-mode" hidden={!isEditMode}>
                        <TextArea
                          value={(content?.summary ?? "").replaceAll("*", "").replaceAll("#", "")}
                          className="title-edit input-style"
                          rows={4}
                          onChange={handleChangeSummary}
                          disabled={isSummaryGenerationLoading}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>

            <Col className="asset-block2 radius-0" span={7}>
              {renderAssetAndContributor()}
            </Col>

            <Col className="asset-block3 radius-0" span={17}>
              <Card className="industry-function-tag mb-10">
                <div className="industry-function">
                  <h4 className="card-heading acn-flex acn-flex-middle acn-flex-between">
                    {loading ? (
                      <Skeleton.Input className="skeleton-input" active={true} />
                    ) : (
                      <span>Industry</span>
                    )}
                  </h4>

                  <div hidden={!isEditMode} className="select-industry-wrapper">
                    <EditContentTags
                      selectedTags={selectedIndustries}
                      mappedContentTags={mappedContentIndustry}
                      popupClassName="ui-choose-select"
                      placeholder="Choose"
                      handleChange={handleChangeIndustry}
                      isNonLeafNodeDisable={true}
                    />
                    <div className="list-tags-select">
                      {formattedSelectedIndustryTag && formattedSelectedIndustryTag.length ? (
                        formattedSelectedIndustryTag.map(
                          (industry: IFormattedIndustry, industryIdx: number) => {
                            if (industry.parents && industry.parents.length) {
                              return (
                                <div className="item-tags" key={industryIdx}>
                                  <Space size={[0, 1]} className="tags">
                                    {industry.parents.map(
                                      (
                                        indParent: IMappedIndustryChildren,
                                        indParentIdx: number
                                      ) => {
                                        return <Tag key={indParentIdx}>{indParent.title}</Tag>;
                                      }
                                    )}
                                    <Tag>{industry.title}</Tag>
                                  </Space>
                                </div>
                              );
                            } else {
                              return (
                                <div className="item-tags" key={industryIdx}>
                                  <Space size={[0, 1]} className="tags mb-24">
                                    <Tag>{industry.title}</Tag>
                                  </Space>
                                </div>
                              );
                            }
                          }
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  <div className="list-tags-select" hidden={isEditMode}>
                    {formattedSelectedIndustryTag &&
                    formattedSelectedIndustryTag.length &&
                    !loading ? (
                      formattedSelectedIndustryTag
                        .slice(0, showIndustryLimit)
                        .map((industry: IFormattedIndustry, industryIdx: number) => {
                          if (industry.parents && industry.parents.length) {
                            return (
                              <div className="item-tags" key={industryIdx}>
                                <Space size={[0, 1]} className="tags">
                                  {industry.parents.map(
                                    (indParent: IMappedIndustryChildren, indParentIdx: number) => {
                                      return <Tag key={indParentIdx}>{indParent.title}</Tag>;
                                    }
                                  )}
                                  <Tag>{industry.title}</Tag>
                                </Space>
                              </div>
                            );
                          } else {
                            return (
                              <div className="item-tags" key={industryIdx}>
                                <Space size={[0, 1]} className="tags mb-24">
                                  <Tag>{industry.title}</Tag>
                                </Space>
                              </div>
                            );
                          }
                        })
                    ) : loading ? (
                      <Skeleton.Input className="skeleton-input content" active={true} />
                    ) : (
                      <div className="no-record">No record found</div>
                    )}

                    {formattedSelectedIndustryTag.length > 1 &&
                      !loading &&
                      showIndustryLimit !== formattedSelectedIndustryTag.length && (
                        <div className="item-more-btn">
                          <Space
                            className="tags more-less-btn"
                            onClick={() =>
                              setShowIndustryLimit(formattedSelectedIndustryTag.length)
                            }>
                            <Tag>
                              +{formattedSelectedIndustryTag.length - showIndustryLimit} more
                            </Tag>
                          </Space>
                        </div>
                      )}
                    {formattedSelectedIndustryTag.length > 1 &&
                      showIndustryLimit === formattedSelectedIndustryTag.length && (
                        <div className="item-more-btn">
                          <Space
                            className="tags more-less-btn"
                            onClick={() => setShowIndustryLimit(1)}>
                            <Tag>less</Tag>
                          </Space>
                        </div>
                      )}
                  </div>
                </div>

                <div className="mt-2 industry-function">
                  {loading ? (
                    <Skeleton.Input className="skeleton-input" active={true} />
                  ) : (
                    <h4 className="card-heading">Function</h4>
                  )}

                  <div hidden={!isEditMode} className="select-industry-wrapper">
                    <EditContentTags
                      selectedTags={selectedFunction}
                      mappedContentTags={mappedContentFunction}
                      popupClassName="ui-choose-select"
                      placeholder="Choose"
                      handleChange={handleChangeFunction}
                      isNonLeafNodeDisable={true}
                    />
                    <div className="list-tags-select">
                      {formattedSelectedFunctionTag && formattedSelectedFunctionTag.length ? (
                        formattedSelectedFunctionTag.map(
                          (fun: IFormattedIndustry, funIdx: number) => {
                            if (fun.parents && fun.parents.length) {
                              return (
                                <div className="item-tags" key={funIdx}>
                                  <Space size={[0, 1]} className="tags">
                                    {fun.parents.map(
                                      (
                                        indParent: IMappedIndustryChildren,
                                        funParentIdx: number
                                      ) => {
                                        return <Tag key={funParentIdx}>{indParent.title}</Tag>;
                                      }
                                    )}
                                    <Tag>{fun.title}</Tag>
                                  </Space>
                                </div>
                              );
                            } else {
                              return (
                                <div className="item-tags" key={funIdx}>
                                  <Space size={[0, 1]} className="tags">
                                    <Tag>{fun.title}</Tag>
                                  </Space>
                                </div>
                              );
                            }
                          }
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  <div className="list-tags-select" hidden={isEditMode}>
                    {formattedSelectedFunctionTag &&
                    formattedSelectedFunctionTag.length &&
                    !loading ? (
                      formattedSelectedFunctionTag
                        .slice(0, showFunctionLimit)
                        .map((fun: IFormattedIndustry, funIdx: number) => {
                          if (fun.parents && fun.parents.length) {
                            return (
                              <div className="item-tags" key={funIdx}>
                                <Space size={[0, 1]} className="tags">
                                  {fun.parents.map(
                                    (indParent: IMappedIndustryChildren, funParentIdx: number) => {
                                      return <Tag key={funParentIdx}>{indParent.title}</Tag>;
                                    }
                                  )}
                                  <Tag>{fun.title}</Tag>
                                </Space>
                              </div>
                            );
                          } else {
                            return (
                              <div className="item-tags" key={funIdx}>
                                <Space size={[0, 1]} className="tags">
                                  <Tag>{fun.title}</Tag>
                                </Space>
                              </div>
                            );
                          }
                        })
                    ) : loading ? (
                      <Skeleton.Input className="skeleton-input content" active={true} />
                    ) : (
                      <div className="no-record">No record found</div>
                    )}
                    {formattedSelectedFunctionTag.length > 1 &&
                      !loading &&
                      showFunctionLimit !== formattedSelectedFunctionTag.length && (
                        <div className="item-more-btn">
                          <Space
                            className="tags more-less-btn"
                            onClick={() =>
                              setShowFunctionLimit(formattedSelectedFunctionTag.length)
                            }>
                            <Tag>
                              +{formattedSelectedFunctionTag.length - showFunctionLimit} more
                            </Tag>
                          </Space>
                        </div>
                      )}
                    {formattedSelectedFunctionTag.length > 1 &&
                      showFunctionLimit === formattedSelectedFunctionTag.length && (
                        <div className="item-more-btn">
                          <Space
                            className="tags more-less-btn"
                            onClick={() => setShowFunctionLimit(1)}>
                            <Tag>less</Tag>
                          </Space>
                        </div>
                      )}
                  </div>
                </div>
              </Card>

              <Card className="other-information-card">
                {loading ? (
                  <Skeleton.Input className="skeleton-input other-info" active={true} />
                ) : (
                  <h4 className="card-heading">Other Information</h4>
                )}

                <div hidden={isEditMode}>
                  <p className="other-info">
                    {loading ? (
                      <Skeleton.Input className="skeleton-input content" active={true} />
                    ) : (
                      <strong>Created date :</strong>
                    )}

                    {getFormattedDate(content.uploadedOn, "type2")}
                  </p>

                  <p className="other-info">
                    {loading ? (
                      <Skeleton.Input className="skeleton-input content" active={true} />
                    ) : (
                      <strong>Published date :</strong>
                    )}
                    {!loading &&
                      (content.publishedOn
                        ? getFormattedDate(content.publishedOn, "type2")
                        : "N/A")}
                  </p>

                  <p className="other-info">
                    {loading ? (
                      <Skeleton.Input className="skeleton-input content" active={true} />
                    ) : (
                      <strong>Asset type :</strong>
                    )}
                    {assetTypeSelectedLabel}
                  </p>

                  <p className="other-info">
                    {loading ? (
                      <Skeleton.Input className="skeleton-input content" active={true} />
                    ) : (
                      <strong>Reference Keywords :</strong>
                    )}
                    {ReferenceKeywordSelectedLabel && ReferenceKeywordSelectedLabel.length ? (
                      ReferenceKeywordSelectedLabel.map((label: string, index: number) => {
                        return (
                          <span key={index}>
                            {label}
                            {`${index !== ReferenceKeywordSelectedLabel.length - 1 ? ", " : ""}`}
                          </span>
                        );
                      })
                    ) : (
                      <></>
                    )}
                    {/* Test */}
                  </p>
                </div>

                {/* Edit View of Asset type*/}
                <p className="other-info" hidden={!isEditMode}>
                  <span className="asset-type-select">
                    <strong>Asset type :</strong>
                    <Select
                      showSearch
                      popupClassName="ui-dropdown-default"
                      className="other-info-dropdown"
                      placeholder="Select Asset Type"
                      onChange={handleSelectAssestType}
                      filterOption={(inputValue: string, option: any) =>
                        option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                      }
                      value={selectedAssetType}>
                      {mappedAssetType &&
                        mappedAssetType?.map((v) => {
                          return (
                            <Select.Option key={v?.value} value={v?.value} label={v?.label}>
                              <div>{v?.label}</div>
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </span>
                </p>

                {/* Edit View of Reference Keywords*/}
                <p className="other-info" hidden={!isEditMode}>
                  <span className="asset-type-select">
                    <strong>Reference Keywords :</strong>
                    <Select
                      showSearch
                      mode="multiple"
                      allowClear
                      popupClassName="ui-dropdown-default"
                      className="other-info-dropdown"
                      placeholder="Select Reference Keywords"
                      onChange={handleSelectReferenceKeywords}
                      maxTagCount={2}
                      options={[
                        { label: "Select All", value: "selectAll" },
                        ...mappedReferenceKeyword,
                      ]}
                      filterOption={(inputValue: string, option: any) =>
                        option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                      }
                      value={selectedReferenceKeywords}
                    />
                  </span>
                </p>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      {["png", "jpg"].includes(content?.file_type || "") && (
        <div style={{ display: "none" }}>
          <Image
            preview={{
              visible,
              scaleStep,
              src: `${content?.thumbnail_pre_signed_url}`,
              onVisibleChange: (value) => {
                setVisible(value);
              },
            }}
          />
        </div>
      )}

      {downloadLogError && (
        <Modal
          className="delete-modal"
          title={"Alert! Limit crossed"}
          centered={true}
          onCancel={onHandleOkAction}
          footer={
            <>
              <Button className="btn-type2" onClick={onHandleOkAction}>
                Ok
              </Button>
            </>
          }
          open={downloadLogError != null}>
          <div>{downloadLogError}</div>
        </Modal>
      )}
      {/* Remove contributor modal */}
      <DeleteModal
        isLoading={false}
        isModalOpen={isOpenPeopleRemoveModal}
        handleCancel={() => setIsOpenPeopleRemoveModal(false)}
        handleSubmit={handleRemoveContributor}
        message="This will remove the selected contributor"
        title="Are you sure you want to remove?"
      />

      {/* Remove owner modal */}
      <DeleteModal
        isLoading={false}
        isModalOpen={isOpenOwnerRemoveModal}
        handleCancel={() => {
          setIsOpenOwnerRemoveModal(false);
        }}
        handleSubmit={handleOwnerRemove}
        message="This will remove the current asset owner"
        title="Are you sure you want to remove?"
      />

      {/* Unsaved Changes warning Mmodal */}
      <DeleteModal
        isLoading={false}
        isModalOpen={isShowUnsavedChangesModal}
        handleCancel={handlecancelUnsavedChanges}
        handleSubmit={handleProceedUnsavedChanges}
        message="This will remove your unsaved changes."
        title="Are you sure you want to leave?"
        submitBtnName="Proceed"
      />

      {/* Cancel warning Mmodal */}
      <DeleteModal
        isLoading={false}
        isModalOpen={isShowCancelWarnModal}
        handleCancel={() => setIsShowCancelWarnModal(false)}
        handleSubmit={handleResetChanges}
        message="This will remove your unsaved changes."
        title="Are you sure you want to cancel?"
        submitBtnName="Yes"
        cancelBtnName="No"
      />

      {/* delete Content modal */}
      <DeleteModal
        isLoading={contentDeleteLoading}
        isModalOpen={isShowAssetDeleteModal}
        handleCancel={() => setIsShowAssetDeleteModal(false)}
        handleSubmit={handleDeleteAsset}
        message="Are you sure you want to delete this asset?"
        title="Delete Asset"
      />
    </>
  );
};

export default ViewEditContentManagement;
