import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Input,
  MenuProps,
  Row,
  Select,
  Switch,
  Table,
  Typography,
  Space,
  Tooltip,
} from "antd";
import "./UserManagement.scss";
import {
  IMAGES,
  useDebounce,
  CustomPagination,
  PageLimit,
  getIndustryList,
  getLocalStorageItem,
  removeLocalstorage,
  removeCookie,
  PATHS,
  LocalStorage,
} from "../../../shared";
import { RootState } from "../../../store";
import { AddEditUserManagement, DeleteModal } from "../../components";
import { IUser, IUserTaxonomyMapping } from "../../../store/userManagement/user.interface";
import {
  fetchAllUsers,
  updateUser,
  updateUserStatus,
  deleteUser,
} from "../../../services/userManagement";
import { setUserCurrPage } from "../../../store/userManagement/usersSlice";
import SkeletonTable from "./SkeletonTable";
import { Industry } from "../../../pages/home/Home";
import { TFiltersType } from "../../../store/filtersFromTaxonomyData/filters.interface";
import ShowAllTaxonomyTags from "../../components/AddEditUserManagement/showAllTaxonomyTags/ShowAllTaxonomyTags";
import { InfoCircleFilled } from "@ant-design/icons";
import { toast } from "react-toastify";
import { persistStore } from "redux-persist";
import { store } from "../../../store";
import { useNavigate } from "react-router";
const UserManagement = () => {
  const { allUsers, userTableLoading, userDeleteLoading, totalUsersCount, userCurrPage } =
    useSelector((state: RootState) => state.user);

  const { industryTaxonomy, functionTaxonomy } = useSelector((state: RootState) => state.taxonomy);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [isShowDeleteUserModal, setIsShowDeleteUserModal] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  const localStorageItem = getLocalStorageItem(LocalStorage.User);
  const details: string | undefined = localStorageItem !== null ? localStorageItem : undefined;
  const userDetails = details ? JSON.parse(details) : {};
  const dispatch = useDispatch();

  const [industryList, setIndustryList] = useState<Industry[]>([]);
  const [functionsList, setFunctionsList] = useState<Industry[]>([]);

  const [selectedContent, setSelectedContent] = useState<[string] | null>(null);
  const [isTagsModal, setIsTagsModal] = useState<number>(-1);
  const [selectedContentTitle, setSelectedContentTitle] = useState<string>(TFiltersType.Industry);
  const navigate = useNavigate();

  useEffect(() => {
    let levelChildren: any = [];

    if (industryTaxonomy.taxonomyTree.length > 0) {
      industryTaxonomy.taxonomyTree.map((element) => {
        levelChildren.push(...getIndustryList(element.children));
      });
    }
    if (functionTaxonomy.taxonomyTree.length > 0) {
      setFunctionsList(getIndustryList(functionTaxonomy.taxonomyTree));
    }
    setIndustryList(levelChildren);
  }, [industryTaxonomy, functionTaxonomy]);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            dispatch(setUserCurrPage(userCurrPage));
            setIsModalOpen(true);
          }}>
          Edit
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            dispatch(setUserCurrPage(userCurrPage));
            setIsShowDeleteUserModal(true);
          }}
          className="UserDeleteText">
          Delete
        </div>
      ),
    },
  ];

  const updateStatus = (value: boolean, userDetials: IUser) => {
    const isUserEnable = value ? "enable" : "disable";
    updateUserStatus(isUserEnable, userDetials?.id);
  };

  const renderTaxonomyTags = (user: IUser, data: any, type: string) => {
    if (user?.userPermissions[0]?.role !== "ADMIN") {
      if (data?.data?.length === 1) {
        return (
          <>
            <div className="item-tags item-tags-ui" key={data[0]}>
              <Space size={[0, 8]} wrap className="tags-span">
                {data?.data[0]}
              </Space>
            </div>
          </>
        );
      }
      if (data.id != -1 && data?.data?.length > 1) {
        return (
          <div className="item-tags item-tags-ui" key={"indsutry"}>
            <Space size={[0, 8]} wrap className="tags-span">
              {data.data.slice(0, 1).map((item: any, index: number) => {
                return (
                  <p key={index} className="tags-p">
                    {item}
                  </p>
                );
              })}
              <span
                className="more-tag"
                onClick={() => {
                  setSelectedContent(data.data);
                  setIsTagsModal(data.id);
                  setSelectedContentTitle(type);
                }}>
                +more
              </span>
            </Space>
            {data?.id === isTagsModal && (
              <ShowAllTaxonomyTags
                isOpen={data?.id === isTagsModal}
                setModalClose={handleCloseTagModal}
                selectedContent={selectedContent}
                contentTitle={selectedContentTitle}
              />
            )}
          </div>
        );
      }
    } else {
      return (
        <div className="item-tags item-tags-ui">
          <Space size={[0, 8]} wrap className="tags-span">
            N/A
          </Space>
        </div>
      );
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "auto",
      render: (text: string) => (
        <>
          <span className="td-label">Name : </span>
          {text}
        </>
      ),
    },
    {
      title: "Email address",
      dataIndex: "email",
      key: "email",
      width: "340px",
      render: (text: string) => (
        <>
          <span className="td-label">Email address : </span>
          {text}
        </>
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: "180px",
      render: (_: string, user: IUser) => {
        return (
          <>
            <span className="td-label">Role : </span>
            <div className="selectRole">
              <Select
                popupClassName="ui-dropdown-default ui-dropdown-select-role"
                value={user?.userPermissions[0].role}
                style={{ width: 120 }}
                onChange={(e) => handleRoleStatus(e, user)}
                options={[
                  { value: "USER", label: "User" },
                  { value: "CONTENT_EDITOR", label: "Content Editor" },
                  { value: "ADMIN", label: "Admin" },
                ]}
              />
              {user?.id === userDetails?.id && (
                <Tooltip
                  className="title-span"
                  overlayClassName="tooltip-text"
                  color="#fff"
                  overlayStyle={{ maxWidth: "400px" }}
                  title={
                    <div className="ml">
                      <span>Changing your role will log you out from the app.</span>
                    </div>
                  }>
                  <InfoCircleFilled style={{ color: "#A7A7A7" }} />
                </Tooltip>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "Industry",
      key: "industry",
      width: "200px",
      render: (user: IUser) => {
        const data = handleTaxonomyMapping(user, TFiltersType.Industry);
        return renderTaxonomyTags(user, data, TFiltersType.Industry);
      },
    },
    {
      title: "Function",
      key: "function",
      width: "200px",
      render: (user: IUser) => {
        let data = handleTaxonomyMapping(user, TFiltersType.Functions);
        return renderTaxonomyTags(user, data, TFiltersType.Functions);
      },
    },

    {
      title: "Status",
      width: "10%",
      render: (_: string, user: IUser) => {
        return (
          <>
            <span className="td-label">Status : </span>
            <Switch
              disabled={user?.id === userDetails?.id}
              defaultChecked={user?.status === "A" ? true : false}
              onChange={(e) => updateStatus(e, user)}
            />
          </>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "71px",
      render: (_: string, user: IUser) => (
        <>
          <span className="td-label">Action : </span>
          <Dropdown
            trigger={["click"]}
            menu={{
              onClick: () => {
                setSelectedUser(user);
              },
              items,
            }}
            overlayClassName="table-action-dropdown table-action-dropdown-user"
            placement="bottomRight">
            <Button className="action-bta">
              <img src={IMAGES.dotsIcon} alt="add" className="dots-icon" />
            </Button>
          </Dropdown>
        </>
      ),
    },
  ];

  const handleCloseTagModal = () => {
    setIsTagsModal(-1);
    setSelectedContent(null);
  };

  useEffect(() => {
    fetchAllUsers(searchText, userCurrPage);
  }, [userCurrPage]);

  const handleLogout = () => {
    toast.success("Logout Successfully");
    persistStore(store).purge();
    removeLocalstorage();
    removeCookie();
    indexedDB.deleteDatabase("localforage");
    indexedDB.deleteDatabase("knowledge-management");
    navigate(PATHS.login);
  };
  const handleRoleStatus = async (value: string, user: IUser) => {
    try {
      if (user) {
        const updatedRole = {
          userId: user.id,
          name: user.name,
          email: user.email,
          userPermission: [{ roleName: value, configSettingId: 1 }],
        };
        const isFormValueChanged = true;
        const isTaxonomyMappingChanged = false;

        await updateUser(
          { ...updatedRole },
          searchText,
          userCurrPage,
          null,
          isFormValueChanged,
          isTaxonomyMappingChanged
        );
        if (user?.id === userDetails?.id) {
          handleLogout();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnCancel = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const getUsersBySearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedSearchText = e.target.value.trim();
    if (trimmedSearchText.length >= 3) await fetchAllUsers(trimmedSearchText);
    if (e.target.value.length === 0) {
      await fetchAllUsers("");
    }
    dispatch(setUserCurrPage(1));
    setSearchText(trimmedSearchText);
  };

  const handleSearchDebounce = useDebounce(getUsersBySearch, 500);

  const handlePagination = (page: number) => {
    dispatch(setUserCurrPage(page));
  };

  const handleDeleteUser = async () => {
    if (selectedUser?.id) await deleteUser(selectedUser?.id, searchText, userCurrPage);
    setIsShowDeleteUserModal(false);
    setSelectedUser(null);
    dispatch(setUserCurrPage(userCurrPage));
    setSelectedUser(null);
  };

  const handleTaxonomyMapping = (user: IUser, type: string) => {
    let mappingObj: any = { id: -1, data: [] };
    user?.taxonomyMapping?.length &&
      user?.taxonomyMapping.map((item: IUserTaxonomyMapping) => {
        if (item.taxonomyId === industryTaxonomy.taxonomyId && type === TFiltersType.Industry) {
          // for industry
          if (item.taxonomyNodeIds?.length) {
            let nodeArr: string[] = [];
            industryList.forEach((element) => {
              if (item.taxonomyNodeIds.includes(element.taxonomyNodeId)) {
                nodeArr.push(element.label);
              }
            });

            mappingObj = { id: `${user.id}_${item.taxonomyId}`, data: nodeArr };
          }
        }

        if (item.taxonomyId === functionTaxonomy.taxonomyId && type === TFiltersType.Functions) {
          // for functions
          if (item.taxonomyNodeIds?.length) {
            let nodeArr: string[] = [];
            functionsList.forEach((element) => {
              if (item.taxonomyNodeIds.includes(element.taxonomyNodeId)) {
                nodeArr.push(element.label);
              }
            });
            mappingObj = { id: `${user.id}_${item.taxonomyId}`, data: nodeArr };
          }
        }
      });
    return mappingObj;
  };

  return (
    <div className="page-content top-container user-managements-page">
      <Row>
        <Col className="page-title-card" span={24}>
          <Card>
            <div className="page-title-box acn-flex acn-flex-middle acn-flex-between">
              <div className="page-title-left">
                <Typography className="card-heading">User Management</Typography>
              </div>

              <div className="page-title-right acn-flex acn-flex-middle acn-flex-between">
                <div className="search-form">
                  <Input
                    className="search-field"
                    placeholder="Search by name or email..."
                    onChange={handleSearchDebounce}
                    allowClear
                    prefix={<img src={IMAGES.searchIcon} alt="search" />}
                  />
                </div>

                <Button
                  className="add-user-button acn-flex acn-flex-middle plus-icon-bg-btn"
                  onClick={() => setIsModalOpen(true)}>
                  <span className="acn-flex acn-flex-middle acn-flex-center">
                    <img src={IMAGES.roundPlusIcon} alt="add" />
                  </span>
                  Add new user
                </Button>
              </div>
            </div>
          </Card>
        </Col>

        <Col className="global-table-card table-ui" span={24}>
          <Card>
            {userTableLoading ? (
              <SkeletonTable />
            ) : (
              <Table
                className="global-table responsive-table responsive-table-user-management"
                columns={columns}
                dataSource={allUsers?.length ? allUsers : []}
                tableLayout="fixed"
                scroll={{ y: "calc(100vh - 315px)" }}
                pagination={false}
              />
            )}

            {allUsers && allUsers.length ? (
              <CustomPagination
                currentPage={userCurrPage}
                total={totalUsersCount}
                handlePagination={handlePagination}
                pageSize={PageLimit.UserManagement}
              />
            ) : (
              <></>
            )}
          </Card>
        </Col>
      </Row>

      {!!isModalOpen && (
        <AddEditUserManagement
          title={selectedUser ? "Edit user" : "Add user"}
          isModalOpen={isModalOpen}
          onCancel={handleOnCancel}
          selectedUser={selectedUser}
          industries={industryList}
          functions={functionsList}
          searchText={searchText}
          industryTaxonomyId={industryTaxonomy?.taxonomyId}
          functionTaxonomyId={functionTaxonomy?.taxonomyId}
          handleLogout={handleLogout}
          userDetails={userDetails}
        />
      )}

      {/* delete user modal */}
      <DeleteModal
        isLoading={userDeleteLoading}
        isModalOpen={isShowDeleteUserModal}
        handleCancel={() => {
          setIsShowDeleteUserModal(false);
          setSelectedUser(null);
        }}
        handleSubmit={handleDeleteUser}
        message="Are you sure you want to delete this user?"
        title="Delete User"
      />
    </div>
  );
};

export default UserManagement;
