import ViewEditContentManagement from "./ViewEditContentManagement";

const ViewContentManagementByUser = () => {
  return (
    <div>
      <ViewEditContentManagement />
    </div>
  );
};

export default ViewContentManagementByUser;
