import { Button, Modal } from "antd";

interface IConfirmationModalProps {
  visible: boolean;
  handleCancel: ((...args: any[]) => any) | undefined;
  handleOk: ((...args: any[]) => any) | undefined;
  title: string;
  content: string;
  isLoading: boolean;
}

const ConfirmationModal = ({
  visible,
  title,
  content,
  isLoading = false,
  handleOk,
  handleCancel,
}: IConfirmationModalProps) => {
  return (
    <Modal
      className="delete-modal"
      title={title}
      open={visible}
      onOk={handleOk}
      closeIcon={false}
      onCancel={handleCancel}
      centered
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" danger onClick={handleOk} loading={isLoading}>
          Delete
        </Button>,
      ]}>
      <p>{content}</p>
    </Modal>
  );
};

export default ConfirmationModal;
