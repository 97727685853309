import { Pagination } from "antd";
import "./CustomPagination.scss";

interface ICustomPagination {
  currentPage: number;
  total: number;
  handlePagination: (page: number) => void;
  pageSize: number;
}

const CustomPagination = ({
  currentPage,
  total,
  handlePagination,
  pageSize,
}: ICustomPagination) => {
  return (
    <div className="pagination-setion">
      <Pagination
        current={currentPage}
        total={total}
        onChange={handlePagination}
        pageSize={pageSize}
        showSizeChanger={false}
      />
    </div>
  );
};

export default CustomPagination;
