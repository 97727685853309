import { ITaxonomyNode } from "../../pages/browseCategory/BrowseCategory";

export interface TFilters {
  selectedIndustry: Array<string>;
  selectedFunctions: Array<string>;
  selectedFileType: Array<string>;
  selectedAssetType: Array<string>;
  selectedDateRange: Array<string>;
  isFiltersDataLoading: boolean;
  browseCategoryFiltersData: ITaxonomyNode | null;
  browseCategoryCurrentLevel: ICurrentLevel[];
  browseCategorySelectedCategory: ITaxonomyNode;
  browseCategorySelectedFunctionValue: string;
  browseCategorySelectedIndustryValue: string;
  browseCategorySelectedRadioOption: string;
}

export enum TFiltersType {
  Industry = "Industry",
  Functions = "Function",
  AssetType = "Asset Type",
  FileType = "File Type",
  DateRange = "Date Range",
}

export interface TFiltersUser {
  defaultSettingId: number | null;
  expiryDate: string | null;
  status: string;
  id: number;
  username: string;
  name: string;
  email: string;
  userPermissions: TFiltersUserPermissions[];
}

export interface TFiltersUserPermissions {
  configSetting: {
    status: string;
    id: number;
    configName: string;
    primaryColor: string;
    secondaryColor: string;
    titleColor: string;
    paragraphColor: string;
    logoName: string;
    namespace: string;
    indexName: string;
    citationsToggle: string;
    minimumScore: string;
    promptPrefix: string;
    promptSuffix: string;
    isEnabled: boolean;
    documentEnabled: boolean;
    count: number;
    orgEnabled: boolean;
    sentimentAnalysisToggle: string;
    sentimentAnalysisRateLimit: number;
    sentimentAnalysisTokenLimit: number;
    sentimentAnalysisRetryCount: number;
    useCase: [];
    tools: [];
    expiryDate: string;
  };
  role: string;
  defaultUseCaseId: number;
  user_permission_id: number;
}

export interface TFilterContributor {
  status: string;
  id: number;
  username: string;
  name: string;
  email: string;
  userPermissions: TFiltersUserPermissions[];
  defaultSettingId: number;
  expiryDate: string;
}

export interface ICurrentLevel {
  title: string;
  id: number;
  linkedAsset: number[] | [];
}
