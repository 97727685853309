import { Button, Col, Flex, Form, Input, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./Login.scss";
import { IMAGES, PATHS } from "../../../shared";
import { RootState } from "../../../store";
import { authenticatesUser } from "../../../services/authApi";

interface ILoginRequest {
  username: string;
  password: string;
}

const Login = () => {
  const navigate = useNavigate();

  const { loginLoading } = useSelector((state: RootState) => state.auth);

  const onFinish = async (values: ILoginRequest) => {
    const { username, password } = values;

    await authenticatesUser({ username, password }, navigate);
  };

  return (
    <Flex id="login-page" className="login-page">
      <div className="authentication-bg">
        <img className="sitelogo" src={IMAGES.logo} alt="Knowledge Management Portal" />
        <div className="top-effect"></div>
        <Flex className="authenticationTitle" align="center" justify="center">
          <h1>Transform your Knowledge Management using Generative AI</h1>
        </Flex>
      </div>
      <Flex className="loginForm" align="center" justify="center">
        <div className="loginFormInner">
          <h2>
            Knowledge Management Portal <img className="aiIcon" src={IMAGES.aiIcon} alt="logo" />
          </h2>
          <div id="loginView" className="card-inner">
            <span className="labelLogin">
              Welcome! <img className="handWave" src={IMAGES.handWave} alt="logo" />
            </span>
            <h3>Sign in to your account</h3>
            <Form
              name="login"
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: false }}
              onFinish={onFinish}
              layout="vertical"
              requiredMark={false}
              size="large">
              <Form.Item
                label="Your email"
                name="username"
                rules={[{ required: true, message: "Please input your email!", type: "email" }]}>
                <Input placeholder="" />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                className="InputPassword"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}>
                <Input.Password placeholder="" />
              </Form.Item>
              <Row className="forgotPassword" justify="end" align="middle">
                <Col className="mb-10">
                  <Link className="text-fp" to={PATHS.forgotPassword}>
                    Forgot password?
                  </Link>
                </Col>
              </Row>
              <Form.Item className="loginBtn">
                <Button
                  loading={loginLoading}
                  className="primary-btn"
                  type="primary"
                  htmlType="submit"
                  block>
                  Login
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Flex>
    </Flex>
  );
};

export default Login;
