import React, { useState } from "react";
import { createElement } from "react";
// import wheelIcon4 from "../../assets/images/wheel-icon4.svg";
import "./WheelChart.scss";
import { PATHS } from "../../shared";
import { useNavigate } from "react-router-dom";
import { Industry } from "../../pages/home/Home";
import WarningModal from "./WarningModal/WarningModal";
import { TFiltersType } from "../../store/filtersFromTaxonomyData/filters.interface";
import { useDispatch } from "react-redux";
import {
  // setBrowseCategorySelectedCategory,
  setBrowseCategorySelectedFunctionValue,
  setBrowseCategorySelectedIndustryValue,
  setBrowseCategorySelectedRadioOption,
} from "../../store/filtersFromTaxonomyData/filters.slice";

interface WheelChartProps {
  data: any;
}
const subMenuData = [
  "Disruptive Growth",
  "Distinguished  Talent",
  "Definitive Profits",
  "Dynamic Process",
  "Digital Pioneer",
];

const WheelChart: React.FC<WheelChartProps> = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isShowWarnModal, setIsShowWarnModal] = useState<boolean>(false);

  const handleFunctionAction = (item: Industry) => {
    dispatch(setBrowseCategorySelectedRadioOption(TFiltersType.Functions));
    dispatch(setBrowseCategorySelectedFunctionValue(item.label));
    dispatch(setBrowseCategorySelectedIndustryValue("Select Industry"));
    // dispatch(setBrowseCategorySelectedCategory(item))
    navigate(PATHS.browseCategory, { state: { data: item, filterType: TFiltersType.Functions } });
  };

  const renderWheelSVG = () => {
    const blades = [];
    // const icons = [];
    const infos = [];

    const SVG_WIDTH = 520;

    const pi = Math.PI;
    const radius = SVG_WIDTH / 2;

    const diff = 10;

    const gap = 0.8; // Space between blades
    const bladeTextOffset = 60; // Blade text offset
    const iconGroupCircleRadius = radius * 0.55; // Icon circle radius
    // const iconDimension = {
    //   width: 17,
    //   height: 17,
    // }; // Icon image dimensions
    const iconOffset = 20; // Icon offset
    const infoGroupCircleRadius = radius * 0.4; // Inner circle radius
    const innerCircleTextOffset = 35; // Inner circle text offset
    const centerCircleRadius = 40; // Inner circle text offset

    var SVG,
      cx,
      cy,
      r,
      fromAngle,
      middleAngle,
      toAngle,
      fromCoordX,
      fromCoordY,
      toCoordX,
      toCoordY,
      d,
      bladeGroup,
      iconGroup,
      centerCircle,
      textPosX,
      textPosY,
      // iconPosX,
      // iconPosY,
      angleinRadians,
      infoGroup;

    // Blades & Icon Loop Start

    for (let i = 0; i < data.length; i++) {
      const label = data[i].taxonomyNodeName; // label of blades

      fromAngle = (i * 360) / data.length + gap;
      toAngle = ((i + 1) * 360) / data.length - gap;
      middleAngle = fromAngle + (toAngle - fromAngle) / 2;

      cx = radius;
      cy = radius;
      r = radius - diff;

      fromCoordX = cx + r * Math.cos((fromAngle * Math.PI) / 180);
      fromCoordY = cy + r * Math.sin((fromAngle * Math.PI) / 180);
      toCoordX = cx + r * Math.cos((toAngle * Math.PI) / 180);
      toCoordY = cy + r * Math.sin((toAngle * Math.PI) / 180);

      d =
        "M" +
        cx +
        "," +
        cy +
        " L" +
        fromCoordX +
        "," +
        fromCoordY +
        " A" +
        r +
        "," +
        r +
        " 0 0,1 " +
        toCoordX +
        "," +
        toCoordY +
        "z";

      const pathID = "blade" + (i + 1);

      r = radius - bladeTextOffset;

      angleinRadians = middleAngle * (pi / 180);
      textPosX = cx + r * Math.cos(angleinRadians);
      textPosY = cy + r * Math.sin(angleinRadians);

      blades.push(
        <g className="blade" style={{ animationDelay: `${30 * i}ms` }}>
          <path
            d={d}
            id={pathID}
            fill="#c22dd2"
            onClick={() => {
              handleFunctionAction(data[i]);
            }}></path>
          <foreignObject
            width="80"
            height="50"
            x={textPosX}
            y={textPosY}
            transform="translate(-40, -25)">
            <p>{label}</p>
          </foreignObject>
        </g>
      );

      r = radius - diff;

      const circleOffset = r - iconGroupCircleRadius;

      cx = iconGroupCircleRadius + circleOffset + diff;
      cy = iconGroupCircleRadius + circleOffset + diff;
      r = iconGroupCircleRadius - iconOffset;

      // iconPosX = cx + r * Math.cos(angleinRadians) - iconDimension.width / 2;
      // iconPosY = cy + r * Math.sin(angleinRadians) - iconDimension.height / 2;

      // icons.push(
      //   <image
      //     href={wheelIcon4}
      //     height={iconDimension.width}
      //     width={iconDimension.height}
      //     x={iconPosX}
      //     y={iconPosY}
      //     visibility="visible"></image>
      // );
    }

    // Blades & Icon Loop End

    // Blade Group
    bladeGroup = (blades: any) => {
      return <g className="blades">{blades}</g>;
    };

    // Icon Group
    iconGroup = (icons: any) => {
      return (
        <g className="icon-group">
          <circle
            cx={SVG_WIDTH / 2}
            cy={SVG_WIDTH / 2}
            r={iconGroupCircleRadius}
            fill="#00000066"
            stroke="none"></circle>
          {icons}
        </g>
      );
    };

    var x1, x2, y1, y2;

    // Inner Circle Loop Start

    for (let j = 0; j < subMenuData.length; j++) {
      const element = subMenuData[j];

      fromAngle = (j * 360) / subMenuData.length;
      toAngle = ((j + 1) * 360) / subMenuData.length;
      middleAngle = fromAngle + (toAngle - fromAngle) / 2;

      cx = radius;
      cy = radius;
      r = infoGroupCircleRadius;

      x1 = radius;
      y1 = radius;
      x2 = cx + r * Math.cos((toAngle * Math.PI) / 180);
      y2 = cy + r * Math.sin((toAngle * Math.PI) / 180);

      r = infoGroupCircleRadius - innerCircleTextOffset;

      angleinRadians = middleAngle * (pi / 180);
      textPosX = cx + r * Math.cos(angleinRadians) - 5;
      textPosY = cy + r * Math.sin(angleinRadians);

      infos.push(
        <g className="group">
          <line x1={x1} y1={y1} x2={x2} y2={y2}></line>
          <foreignObject
            width="70"
            height="35"
            x={textPosX}
            y={textPosY}
            transform="translate(-30, -15)">
            <p>{element}</p>
          </foreignObject>
        </g>
      );
    }

    // Inner Circle Loop End

    // Info Group
    infoGroup = (infos: any) => {
      return (
        <g className="info-group">
          <circle
            cx={SVG_WIDTH / 2}
            cy={SVG_WIDTH / 2}
            r={infoGroupCircleRadius}
            fill="#8e4dff"
            stroke="none"></circle>
          {infos}
        </g>
      );
    };

    // Info Group
    centerCircle = () => {
      return (
        <g className="svg-btn">
          <circle
            cx={radius}
            cy={radius}
            r={centerCircleRadius}
            fill="white"
            stroke="none"></circle>
          <foreignObject
            width={centerCircleRadius * 1.6}
            height={centerCircleRadius * 0.7}
            x={radius}
            y={radius}
            transform={`translate(-${(centerCircleRadius * 1.6) / 2}, -${
              (centerCircleRadius * 0.7) / 2
            })`}>
            <p>Enterprise Transformation</p>
          </foreignObject>
        </g>
      );
    };

    SVG = createElement(
      "svg",
      {
        className: "pie",
        id: "pie",
        width: SVG_WIDTH,
        height: SVG_WIDTH,
        viewBox: `0 0 ${SVG_WIDTH} ${SVG_WIDTH}`,
      },
      bladeGroup(blades),
      // iconGroup(icons), if need icon enable this and remove below
      iconGroup([]),
      infoGroup(infos),
      centerCircle()
    );

    return SVG;
  };

  return (
    <div className="plate" id="plate">
      {/* <ul className="wheel-main-category">
        <li>
          <svg width="545" height="545" viewBox="0 0 545 545" fill="none">
            <path
              d="M123.683 486.822C123.261 487.425 122.431 487.571 121.83 487.146C92.2515 466.21 67.3873 439.3 48.8477 408.152C30.3081 377.003 18.5105 342.316 14.2111 306.333C14.1238 305.603 14.6488 304.943 15.3795 304.859C16.1102 304.776 16.7699 305.301 16.8571 306.031C21.1137 341.639 32.7895 375.965 51.1363 406.79C69.4831 437.614 94.0879 464.244 123.358 484.965C123.958 485.39 124.105 486.22 123.683 486.822Z"
              fill="#7A2BCC"
            />
            <path
              d="M318.214 21.9638C318.347 21.2405 317.868 20.5458 317.144 20.4165C264.517 11.0193 210.259 18.1229 161.811 40.7719C113.363 63.4208 73.1164 100.497 46.577 146.903C46.2119 147.541 46.4376 148.354 47.0779 148.716C47.7181 149.078 48.5306 148.852 48.8957 148.213C75.1625 102.289 114.992 65.5988 162.939 43.1845C210.885 20.7702 264.58 13.7389 316.662 23.0359C317.386 23.1652 318.081 22.6871 318.214 21.9638Z"
              fill="#7A2BCC"
            />
            <path
              d="M320.249 23.4182C320.857 23.0049 321.015 22.1768 320.602 21.5686L313.867 11.6563C313.454 11.048 312.626 10.8899 312.018 11.3032C311.409 11.7164 311.251 12.5446 311.664 13.1528L317.651 21.9637L308.84 27.95C308.232 28.3632 308.073 29.1913 308.487 29.7996C308.9 30.4079 309.728 30.5659 310.336 30.1527L320.249 23.4182ZM318.379 23.4583L319.251 23.6247L319.75 21.0089L318.878 20.8425L318.379 23.4583Z"
              fill="#7A2BCC"
            />
            <path
              d="M522.146 213.363C522.86 213.184 523.293 212.461 523.111 211.748C511.302 165.595 486.999 123.581 452.861 90.3276C418.724 57.0745 376.086 33.8826 329.639 23.289C328.922 23.1255 328.21 23.5781 328.05 24.296C327.89 25.0138 328.343 25.7251 329.06 25.8886C375.024 36.3748 417.219 59.3271 451.003 92.2354C484.787 125.144 508.839 166.722 520.528 212.395C520.71 213.107 521.433 213.542 522.146 213.363Z"
              fill="#C22DD2"
            />
            <path
              d="M132.879 493.122C132.483 493.741 132.663 494.565 133.284 494.959C162.923 513.736 196.069 526.302 230.715 531.892C265.8 537.552 301.675 535.937 336.109 527.148C370.544 518.358 402.803 502.58 430.882 480.796C458.611 459.284 481.678 432.368 498.691 401.683C499.048 401.04 498.811 400.23 498.166 399.877C497.521 399.524 496.712 399.761 496.355 400.404C479.518 430.769 456.69 457.404 429.25 478.692C401.459 500.252 369.531 515.867 335.451 524.567C301.37 533.267 265.864 534.865 231.14 529.263C196.853 523.731 164.052 511.296 134.721 492.717C134.1 492.323 133.276 492.503 132.879 493.122Z"
              fill="#C22DD2"
            />
            <path
              d="M131.547 491.006C130.827 491.159 130.368 491.865 130.52 492.585L133.004 504.308C133.156 505.028 133.863 505.487 134.582 505.335C135.302 505.182 135.761 504.476 135.609 503.756L133.401 493.335L143.822 491.128C144.542 490.975 145.001 490.269 144.849 489.549C144.696 488.83 143.99 488.37 143.27 488.523L131.547 491.006ZM133.293 491.677L132.549 491.193L131.097 493.425L131.841 493.909L133.293 491.677Z"
              fill="#C22DD2"
            />
            <path
              d="M522.023 223.228C522.583 225.994 525.017 227.277 528.027 226.667L528.166 226.639C530.95 226.076 532.881 223.982 532.3 221.111C531.715 218.223 529.254 216.982 526.296 217.581L526.157 217.609C523.147 218.218 521.499 220.635 522.023 223.228ZM520.771 223.481C520.027 219.81 522.343 216.751 525.823 216.046L525.962 216.018C529.494 215.303 532.778 217.21 533.518 220.864C534.258 224.519 531.963 227.501 528.466 228.209L528.327 228.237C524.812 228.949 521.503 227.1 520.771 223.481Z"
              fill="white"
            />
            <path
              d="M519.318 232.338L531.694 230.463L531.914 231.92L530.37 232.154C531.345 232.545 532.341 233.669 532.543 235.003C532.907 237.408 531.42 239.447 528.559 239.88L528.419 239.901C525.61 240.327 523.429 238.969 523.047 236.441C522.818 234.932 523.41 233.747 524.243 233.082L519.539 233.794L519.318 232.338ZM524.236 236.118C524.513 237.943 525.891 238.74 528.12 238.402L528.26 238.381C530.577 238.03 531.542 236.573 531.308 235.028C531.05 233.326 529.73 232.197 527.378 232.553L527.237 232.575C524.885 232.931 523.965 234.327 524.236 236.118Z"
              fill="white"
            />
            <path
              d="M524.293 245.657C524.096 243.982 524.989 243.055 526.435 242.886L532.112 242.22L531.957 240.898L533.192 240.753L533.347 242.076L535.445 241.83L535.616 243.293L533.518 243.539L533.77 245.69L532.536 245.835L532.284 243.684L526.747 244.333C525.848 244.438 525.455 244.895 525.541 245.636C525.595 246.094 525.707 246.439 525.868 246.742L524.633 246.886C524.492 246.599 524.363 246.257 524.293 245.657Z"
              fill="white"
            />
            <path
              d="M525.708 249.01L534.917 248.127L535.058 249.588L525.848 250.472L525.708 249.01ZM536.904 248.647C536.854 248.119 537.252 247.636 537.78 247.585C538.308 247.535 538.79 247.933 538.841 248.461C538.892 248.989 538.494 249.472 537.966 249.522C537.437 249.573 536.955 249.175 536.904 248.647Z"
              fill="white"
            />
            <path
              d="M525.259 253.538L534.529 253.019L534.612 254.491L533.194 254.57C534.086 254.947 534.852 255.918 534.926 257.247C534.992 258.435 534.555 259.508 533.341 260.056C534.582 260.627 535.191 261.98 535.255 263.132C535.349 264.798 534.437 266.307 531.743 266.458L526 266.779L525.917 265.308L531.767 264.981C533.398 264.89 534.033 264.161 533.961 262.884C533.894 261.679 533.009 260.484 531.343 260.577L525.67 260.895L525.588 259.423L531.438 259.096C533.068 259.005 533.703 258.276 533.632 256.999C533.564 255.794 532.68 254.599 531.013 254.692L525.341 255.01L525.259 253.538Z"
              fill="white"
            />
            <path
              d="M525.989 269.644L535.273 269.492L535.297 270.966L526.013 271.117L525.989 269.644ZM537.219 270.171C537.211 269.638 537.647 269.187 538.18 269.179C538.712 269.17 539.163 269.607 539.172 270.139C539.18 270.672 538.744 271.123 538.211 271.131C537.679 271.14 537.228 270.703 537.219 270.171Z"
              fill="white"
            />
            <path
              d="M526.115 272.994L526.329 272.995L534.149 278.547L534.18 273.578L535.426 273.585L535.382 280.834L535.115 280.832L527.401 275.406L527.368 280.713L526.068 280.705L526.115 272.994Z"
              fill="white"
            />
            <path
              d="M525.617 286.333C525.738 283.706 527.67 281.928 530.527 282.059L530.669 282.066C533.474 282.195 535.343 284.078 535.226 286.633C535.127 288.781 533.738 290.745 530.419 290.592L529.94 290.57L530.262 283.577C528.146 283.551 526.928 284.508 526.841 286.407C526.775 287.845 527.269 288.704 528.343 288.949L528.275 290.422C526.426 290.017 525.521 288.428 525.617 286.333ZM531.449 283.685L531.198 289.116C533.122 289.062 533.931 288.103 534.001 286.577C534.071 285.068 533.088 284.009 531.449 283.685Z"
              fill="white"
            />
            <path
              d="M524.994 296.02C525.164 293.594 527.004 291.961 529.891 292.162L530.033 292.172C532.866 292.37 534.744 294.281 534.566 296.831C534.458 298.372 533.647 299.277 532.707 299.745L538.622 300.158L538.519 301.628L524.829 300.671L524.932 299.202L526.526 299.313C525.66 298.718 524.9 297.366 524.994 296.02ZM526.205 296.265C526.085 297.983 527.124 299.408 529.498 299.574L529.639 299.584C532.013 299.75 533.207 298.694 533.334 296.888C533.462 295.046 532.246 293.857 529.997 293.7L529.855 293.69C527.518 293.527 526.314 294.707 526.205 296.265Z"
              fill="white"
            />
            <path
              d="M524.182 314.117C523.76 316.908 525.606 318.947 528.643 319.406L528.783 319.427C531.592 319.852 534.124 318.547 534.561 315.651C535.002 312.737 533.115 310.728 530.131 310.276L529.991 310.255C526.954 309.796 524.577 311.502 524.182 314.117ZM522.918 313.926C523.478 310.222 526.701 308.142 530.212 308.673L530.352 308.694C533.916 309.233 536.348 312.15 535.79 315.836C535.233 319.523 532.055 321.538 528.527 321.005L528.387 320.983C524.841 320.447 522.366 317.577 522.918 313.926Z"
              fill="white"
            />
            <path
              d="M519.445 321.956L531.672 324.392L531.386 325.831L529.859 325.527C530.64 326.224 531.193 327.614 530.93 328.932C530.457 331.308 528.374 332.714 525.547 332.151L525.408 332.124C522.633 331.571 521.05 329.56 521.547 327.063C521.845 325.571 522.801 324.662 523.807 324.321L519.159 323.395L519.445 321.956ZM522.772 327.163C522.412 328.966 523.433 330.179 525.636 330.618L525.774 330.646C528.064 331.102 529.461 330.064 529.765 328.538C530.1 326.855 529.245 325.351 526.921 324.888L526.783 324.86C524.459 324.397 523.124 325.394 522.772 327.163Z"
              fill="white"
            />
            <path
              d="M518.366 336.918C518.989 334.366 521.225 332.993 524.001 333.67L524.139 333.704C526.865 334.369 528.337 336.574 527.73 339.058C527.221 341.145 525.482 342.804 522.257 342.016L521.791 341.903L523.45 335.107C521.38 334.675 520.002 335.381 519.552 337.226C519.211 338.623 519.53 339.56 520.536 340.007L520.187 341.438C518.452 340.686 517.869 338.954 518.366 336.918ZM524.593 335.441L523.304 340.719C525.201 341.036 526.178 340.251 526.54 338.767C526.898 337.301 526.138 336.074 524.593 335.441Z"
              fill="white"
            />
            <path
              d="M516.976 343.001L525.92 345.492L525.525 346.912L523.917 346.464C524.769 347.31 525.256 348.275 524.859 349.896L523.525 349.525C523.94 347.705 523.695 346.402 521.489 345.788L516.58 344.42L516.976 343.001Z"
              fill="white"
            />
            <path
              d="M514.345 351.47C514.859 349.826 516.063 348.77 517.876 349.336C519.927 349.977 519.944 352.363 519.234 354.634L518.838 355.904L519.481 356.106C520.888 356.545 521.65 356.188 522.074 354.832C522.455 353.612 522.158 352.776 521.116 352.301L521.556 350.895C523.514 351.711 523.757 353.554 523.222 355.265C522.693 356.96 521.516 358.285 519.042 357.512L513.196 355.686L513.635 354.279L514.754 354.629C514.13 353.709 513.938 352.774 514.345 351.47ZM515.421 351.955C514.956 353.446 515.364 354.819 516.736 355.248L517.838 355.592L518.219 354.372C518.775 352.593 518.742 351.15 517.437 350.743C516.522 350.457 515.808 350.717 515.421 351.955Z"
              fill="white"
            />
            <path
              d="M511.232 361.025C511.784 359.431 512.991 358.985 514.367 359.462L519.768 361.333L520.204 360.075L521.378 360.482L520.943 361.74L522.939 362.431L522.457 363.824L520.46 363.132L519.751 365.179L518.577 364.772L519.286 362.725L514.019 360.901C513.163 360.604 512.61 360.845 512.366 361.549C512.215 361.985 512.166 362.344 512.178 362.687L511.004 362.28C511.002 361.96 511.035 361.595 511.232 361.025Z"
              fill="white"
            />
            <path
              d="M510.18 364.339L518.888 367.561L518.376 368.943L509.668 365.721L510.18 364.339ZM520.456 368.899C520.641 368.399 521.211 368.137 521.711 368.322C522.21 368.507 522.473 369.077 522.288 369.576C522.103 370.076 521.533 370.338 521.033 370.153C520.534 369.968 520.271 369.398 520.456 368.899Z"
              fill="white"
            />
            <path
              d="M508.17 372.508C507.471 374.237 508.333 375.848 510.275 376.633L510.391 376.68C512.382 377.484 514.112 376.9 514.803 375.188C515.502 373.46 514.657 371.855 512.681 371.057L512.55 371.003C510.591 370.212 508.874 370.763 508.17 372.508ZM507.04 372.033C508.031 369.58 510.603 368.57 513.121 369.588L513.253 369.641C515.837 370.685 516.923 373.211 515.939 375.647C514.955 378.083 512.436 379.153 509.852 378.109L509.72 378.055C507.119 377.005 506.05 374.486 507.04 372.033Z"
              fill="white"
            />
            <path
              d="M504.581 378.387L513.039 382.218L512.431 383.56L511.089 382.952C511.706 383.699 511.976 384.952 511.346 386.343C510.555 388.09 509.045 388.887 506.586 387.773L501.395 385.422L502.003 384.08L507.291 386.475C508.779 387.149 509.728 386.818 510.343 385.46C510.907 384.215 510.62 382.74 509.1 382.051L503.973 379.729L504.581 378.387Z"
              fill="white"
            />
            <path
              d="M498.725 390.493C499.715 388.507 501.309 387.814 503.049 388.581L502.408 389.869C501.375 389.473 500.494 389.689 499.829 391.023C499.204 392.279 499.356 393.049 500.245 393.492C501.119 393.927 501.73 393.419 502.61 392.25C504.084 390.287 505.052 389.857 506.498 390.578C508.039 391.346 508.081 393.251 507.432 394.554C506.688 396.048 505.443 397.034 503.536 396.362L504.17 395.09C505.17 395.351 505.821 395.041 506.344 393.992C506.819 393.038 506.651 392.181 505.936 391.825C505.189 391.453 504.681 391.715 503.651 393.106C502.478 394.664 501.495 395.682 499.731 394.803C498.222 394.051 497.719 392.511 498.725 390.493Z"
              fill="white"
            />
            <path
              d="M18.4138 300.905L5.41928 297.526L5.26368 295.508L17.5921 290.247L17.7095 291.77L14.1579 293.29L14.5605 298.513L18.3018 299.454L18.4138 300.905ZM6.62166 296.489L13.3062 298.183L12.9677 293.792L6.62166 296.489Z"
              fill="white"
            />
            <path
              d="M17.5957 284.573C17.6828 287.163 15.9208 289.177 13.0463 289.273L12.9044 289.278C10.0654 289.373 8.08117 287.415 7.99771 284.931C7.93273 282.997 8.75909 281.157 11.1094 280.794L11.1583 282.249C9.69336 282.529 9.17908 283.612 9.22201 284.89C9.27864 286.575 10.6707 287.825 12.8531 287.752L12.9951 287.747C15.2662 287.671 16.4311 286.388 16.3708 284.596C16.3267 283.283 15.5238 282.12 14.0103 282.011L13.9643 280.645C15.9759 280.773 17.5206 282.337 17.5957 284.573Z"
              fill="white"
            />
            <path
              d="M16.543 274.542C16.5333 277.121 14.7066 279.058 11.8448 279.047L11.7035 279.046C8.87704 279.036 6.9767 277.015 6.98592 274.542C6.9931 272.616 7.88307 270.818 10.2336 270.544L10.2282 271.992C8.76114 272.216 8.20956 273.274 8.20482 274.546C8.19857 276.224 9.53632 277.519 11.7091 277.527L11.8505 277.528C14.1116 277.536 15.3175 276.304 15.3241 274.52C15.329 273.213 14.5738 272.026 13.0728 271.862L13.0779 270.501C15.0734 270.703 16.5513 272.316 16.543 274.542Z"
              fill="white"
            />
            <path
              d="M17.713 264.511C17.5982 267.136 15.6721 268.918 12.8165 268.793L12.6746 268.787C9.87222 268.664 7.9996 266.787 8.11136 264.233C8.20528 262.087 9.58852 260.122 12.9052 260.267L13.3841 260.288L13.0783 267.276C15.1921 267.298 16.4069 266.338 16.49 264.44C16.5528 263.003 16.0572 262.146 14.9838 261.904L15.0483 260.432C16.8966 260.833 17.8046 262.418 17.713 264.511ZM11.8923 267.171L12.1298 261.744C10.2081 261.802 9.40193 262.762 9.33518 264.287C9.26921 265.794 10.2537 266.85 11.8923 267.171Z"
              fill="white"
            />
            <path
              d="M17.8295 258.204L4.08131 257.197L4.19074 255.703L17.939 256.71L17.8295 258.204Z"
              fill="white"
            />
            <path
              d="M18.7705 250.011C18.5084 252.625 16.4853 254.296 13.6412 254.011L13.4999 253.997C10.7089 253.717 8.94461 251.738 9.19966 249.194C9.41397 247.057 10.9054 245.172 14.2088 245.503L14.6857 245.551L13.9879 252.511C16.0971 252.651 17.3639 251.761 17.5534 249.871C17.6969 248.44 17.2502 247.557 16.1921 247.254L16.3391 245.788C18.1621 246.292 18.9795 247.926 18.7705 250.011ZM12.8096 252.339L13.3516 246.934C11.4296 246.884 10.5709 247.797 10.4185 249.316C10.268 250.818 11.1916 251.927 12.8096 252.339Z"
              fill="white"
            />
            <path
              d="M18.3771 243.641L9.20616 242.388L9.40514 240.932L11.0534 241.157C10.098 240.437 9.48691 239.55 9.65966 237.895L11.0274 238.082C10.8634 239.935 11.2814 241.189 13.5435 241.498L18.5761 242.186L18.3771 243.641Z"
              fill="white"
            />
            <path
              d="M20.6692 234.984C20.3852 236.69 19.3332 237.907 17.4514 237.594C15.3234 237.239 14.9771 234.868 15.3693 232.512L15.5889 231.193L14.9206 231.081C13.4609 230.838 12.7517 231.299 12.5175 232.706C12.3067 233.972 12.7172 234.763 13.8193 235.091L13.5764 236.551C11.5157 236.009 11.02 234.21 11.3157 232.433C11.6084 230.675 12.5959 229.194 15.1636 229.622L21.231 230.632L20.988 232.091L19.8273 231.898C20.5749 232.728 20.8946 233.63 20.6692 234.984ZM19.5318 234.65C19.7895 233.103 19.1942 231.793 17.7697 231.556L16.6265 231.365L16.4157 232.632C16.1084 234.478 16.3402 235.909 17.6944 236.134C18.6441 236.292 19.3181 235.934 19.5318 234.65Z"
              fill="white"
            />
            <path
              d="M22.4533 225.121C22.1233 226.78 20.9856 227.388 19.5537 227.103L13.9311 225.985L13.6705 227.294L12.4481 227.051L12.7087 225.741L10.6308 225.328L10.9192 223.879L12.9971 224.292L13.4211 222.162L14.6434 222.405L14.2195 224.535L19.7024 225.626C20.593 225.803 21.1094 225.489 21.2554 224.755C21.3457 224.301 21.3453 223.938 21.2857 223.6L22.508 223.843C22.5537 224.161 22.5715 224.527 22.4533 225.121Z"
              fill="white"
            />
            <path
              d="M24.051 218.072C23.4623 220.633 21.2453 222.036 18.4596 221.396L18.3212 221.364C15.5875 220.735 14.0859 218.55 14.6586 216.059C15.1399 213.965 16.8564 212.283 20.0919 213.027L20.559 213.134L18.992 219.951C21.0669 220.355 22.4355 219.632 22.8611 217.78C23.1832 216.379 22.8511 215.446 21.8394 215.013L22.1695 213.577C23.9147 214.306 24.5203 216.03 24.051 218.072ZM17.8447 219.633L19.0617 214.338C17.1613 214.047 16.1945 214.845 15.8525 216.333C15.5144 217.804 16.2913 219.021 17.8447 219.633Z"
              fill="white"
            />
            <path
              d="M28.282 202.104C27.1651 205.847 23.9226 207.214 20.3671 206.153L20.231 206.112C16.6754 205.051 14.7373 201.86 15.778 198.373C16.4989 195.957 18.2664 194.132 21.1171 194.575L20.6754 196.055C18.6345 195.816 17.49 196.92 16.962 198.689C16.1295 201.479 17.6937 203.687 20.6708 204.576L20.8069 204.616C23.8181 205.515 26.2382 204.607 27.0911 201.749C27.878 199.112 26.4999 197.459 24.3546 196.764L23.2936 200.319L22.0687 199.954L23.5612 194.952L24.4629 195.221C27.9844 196.272 29.211 198.991 28.282 202.104Z"
              fill="white"
            />
            <path
              d="M30.5216 194.482L21.7353 191.48L22.2118 190.085L23.791 190.625C22.9892 189.732 22.5588 188.741 23.048 187.144L24.3584 187.592C23.8396 189.385 24.0094 190.7 26.1765 191.44L30.9981 193.088L30.5216 194.482Z"
              fill="white"
            />
            <path
              d="M32.9643 184.465C33.624 182.722 32.727 181.129 30.7676 180.388L30.6514 180.344C28.6422 179.584 26.9261 180.206 26.2727 181.933C25.613 183.677 26.4935 185.263 28.486 186.016L28.6189 186.067C30.5948 186.814 32.2984 186.225 32.9643 184.465ZM34.1038 184.915C33.1676 187.389 30.6191 188.456 28.0786 187.495L27.9457 187.444C25.3388 186.458 24.1971 183.957 25.127 181.5C26.0568 179.042 28.5516 177.917 31.1585 178.903L31.2913 178.954C33.9149 179.946 35.0399 182.441 34.1038 184.915Z"
              fill="white"
            />
            <path
              d="M36.2437 176.616L26.6474 175.569L27.2762 174.112L34.9479 175.077L29.0159 170.078L29.519 168.912L37.1697 169.926L31.2097 164.992L31.8036 163.615L39.1362 169.91L38.4935 171.4L31.3824 170.427L36.8795 175.142L36.2437 176.616Z"
              fill="white"
            />
            <path
              d="M43.3257 163.213C42.5932 164.742 41.3375 165.05 40.0181 164.418L34.8372 161.935L34.2588 163.142L33.1326 162.602L33.7109 161.395L31.7962 160.478L32.4362 159.142L34.3508 160.06L35.2916 158.097L36.4178 158.637L35.4771 160.6L40.5294 163.021C41.3499 163.414 41.9295 163.237 42.2534 162.561C42.4539 162.143 42.5437 161.79 42.5699 161.446L43.6962 161.986C43.6616 162.306 43.5879 162.666 43.3257 163.213Z"
              fill="white"
            />
            <path
              d="M44.791 160.114L32.6232 153.767L33.3046 152.461L38.5464 155.195C37.9721 154.415 37.7714 153.149 38.4775 151.795C39.3642 150.095 40.9169 149.383 43.3096 150.631L48.3624 153.267L47.681 154.573L42.5337 151.888C41.0855 151.133 40.1199 151.41 39.4303 152.733C38.7981 153.945 39.0029 155.433 40.4825 156.205L45.4724 158.807L44.791 160.114Z"
              fill="white"
            />
          </svg>
        </li>
      </ul> */}
      {renderWheelSVG()}
      <svg className="flt_svg" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <filter id="flt_tag">
            <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
              result="flt_tag"
            />
            <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
          </filter>
        </defs>
      </svg>

      <WarningModal
        title="Please Select Industry"
        isModalOpen={isShowWarnModal}
        handleOk={() => setIsShowWarnModal(false)}
        handleCancel={() => setIsShowWarnModal(false)}
      />
    </div>
  );
};

export default WheelChart;
