import { useEffect, useState } from "react";
import "./SideBar.scss";
import { Button, Menu, Tree, DatePicker } from "antd";
import { getTaxonomyByTitle } from "../../services/taxonomy";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { fileType } from "./FileTypeData";
import {
  removeFilterData,
  setAllAssetTypesSelected,
  setAllFileTypesSelected,
  // setAllAssetTypesSelected,
  // setAllFileTypesSelected,
  setFilterDateRange,
  setFiltersData,
} from "../../store/filtersFromTaxonomyData/filters.slice";
const { RangePicker } = DatePicker;
import Sider from "antd/es/layout/Sider";
import { IMAGES, TaxonomyTitle, compareFilters, getAllIds } from "../../shared";
import { TFiltersType } from "../../store/filtersFromTaxonomyData/filters.interface";
import dayjs from "dayjs";
import { ITaxonomyTree } from "../../store/taxonomy/taxonomy.interface";
import { setShowNewTopicAlert } from "../../store/conversationalSearch/conversationalSearchSlice";
import { toast } from "react-toastify";

interface Industry {
  title: string;
  key: string;
  children: any;
}

const SideBar = () => {
  const { allTaxonomyData, industryTaxonomy, functionTaxonomy, assetTypeTaxonomy } = useSelector(
    (state: RootState) => state.taxonomy
  );
  const { selectedChatHistory } = useSelector((state: RootState) => state.conversationalSearch);

  const {
    selectedAssetType,
    selectedFileType,
    selectedFunctions,
    selectedIndustry,
    selectedDateRange,
    isFiltersDataLoading,
  } = useSelector((state: RootState) => state.filtersData);
  const [industryList, setIndustryList] = useState<Industry[]>([]);
  const [functionsList, setFunctionsList] = useState<Industry[]>([]);
  const [assetTypeList, setAssetTypeList] = useState<{ title: string; key: string }[]>([]);
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedIndustryKeys, setSelectedIndustryKeys] = useState(selectedIndustry);
  const [selectedFunctionKeys, setSelectedFunctionKeys] = useState(selectedFunctions);
  const [selectedAssetTypeKeys, setSelectedAssetTypeKeys] = useState([...selectedAssetType]);
  const [selectedFileTypeKeys, setSelectedFileTypeKeys] = useState([...selectedFileType]);
  const [selectedDateRangeKeys, setSelectedDateRangeKeys] = useState([...selectedDateRange]);
  const [showApplyButton, setShowApplyButton] = useState<boolean>(false);
  const [showClearButton, setShowClearButton] = useState<boolean>(false);
  let timeoutId: number | undefined;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.classList.toggle("sidebar-filter-open"); // Toggle the class on body tag
  };

  useEffect(() => {
    getTaxonomyByTitle(TaxonomyTitle.Industry);
    getTaxonomyByTitle(TaxonomyTitle.Function);
    getTaxonomyByTitle(TaxonomyTitle.AssetType);
    dispatch(setAllFileTypesSelected(fileType));

    if (selectedChatHistory?.id === -1) {
      handleResetFiltersData();
    }

    return () => {
      onRemoveDataAction();
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    const tempIndustriesArr: ITaxonomyTree[] = [];
    const tempFunctionsArr: ITaxonomyTree[] = [];
    const tempAssetTypeArr: any = [];

    assetTypeTaxonomy?.taxonomyTree.forEach((item: ITaxonomyTree) => {
      tempAssetTypeArr.push({
        title: item.taxonomyNodeName,
        key: item.taxonomyNodeId.toString(),
      });
    });
    industryTaxonomy?.taxonomyTree.forEach((item: ITaxonomyTree) => {
      tempIndustriesArr.push(item);
    });
    functionTaxonomy?.taxonomyTree.forEach((item: ITaxonomyTree) => {
      tempFunctionsArr.push(item);
    });

    const dataIndustries = convertData(tempIndustriesArr);
    const dataFunctions = convertData(tempFunctionsArr);

    setIndustryList(dataIndustries);
    setFunctionsList(dataFunctions);
    setAssetTypeList(tempAssetTypeArr);
    setSelectedAssetTypeKeys(selectedAssetType);
    setSelectedDateRangeKeys(selectedDateRange);
    setSelectedFileTypeKeys(selectedFileType);
    setSelectedFunctionKeys(selectedFunctions);
    setSelectedIndustryKeys(selectedIndustry);
  }, [
    allTaxonomyData,
    assetTypeTaxonomy,
    industryTaxonomy,
    functionTaxonomy,
    selectedAssetType,
    selectedFileType,
    selectedFunctions,
    selectedIndustry,
    selectedDateRange,
  ]);

  const convertData = (inputData: ITaxonomyTree[]) => {
    const convertNode = (node: ITaxonomyTree): Industry => {
      return {
        title: node.taxonomyNodeName,
        key: String(node.taxonomyNodeId),
        children: node.children ? node.children.map(convertNode) : [],
      };
    };

    return inputData.map(convertNode);
  };

  const onCheck = (checkedKeys: any, _: any, type: string) => {
    let selectedKeys = [...checkedKeys];

    updateFilterData(type, selectedKeys);
  };

  const onChange = (_: any, dateString: [string, string]) => {
    setSelectedDateRangeKeys(dateString);
    if (compareFilters(dateString, selectedDateRange)) {
      setShowApplyButton(false);
    } else {
      setShowApplyButton(true);
      setShowClearButton(true);
    }
  };
  const onOk = (value: any) => {
    console.log("onOk: ", value);
  };

  const updateFilterData = (type: any, data: any) => {
    let flagValue = true;
    if (type === TFiltersType.Industry) {
      setSelectedIndustryKeys(data);
      if (!compareFilters(data, selectedIndustry)) {
        flagValue = false;
      }
    }
    if (type === TFiltersType.Functions) {
      setSelectedFunctionKeys(data);
      if (!compareFilters(data, selectedFunctions)) {
        flagValue = false;
      }
    }
    if (type === TFiltersType.AssetType) {
      setSelectedAssetTypeKeys(data);
      if (!compareFilters(data, selectedAssetType)) {
        flagValue = false;
      }
    }
    if (type === TFiltersType.FileType) {
      setSelectedFileTypeKeys(data);
      if (!compareFilters(data, selectedFileType)) {
        flagValue = false;
      }
    }
    !flagValue ? setShowApplyButton(true) : setShowApplyButton(false); // set flag for apply button
    !flagValue ? setShowClearButton(true) : setShowClearButton(false); // set flag for apply button
  };

  const handleResetFiltersData = () => {};

  const applyFilters = () => {
    dispatch(
      setFiltersData([
        { type: TFiltersType.Industry, data: selectedIndustryKeys },
        { type: TFiltersType.Functions, data: selectedFunctionKeys },
        { type: TFiltersType.AssetType, data: selectedAssetTypeKeys },
        { type: TFiltersType.FileType, data: selectedFileTypeKeys },
      ])
    );
    dispatch(setFilterDateRange(selectedDateRangeKeys));
    setShowApplyButton(false);
    toast.success("Filters successfully applied");
  };

  const handleApplyFilterAction = () => {
    if (selectedChatHistory.id != -1) {
      setSelectedIndustryKeys(selectedIndustry);
      setSelectedFunctionKeys(selectedFunctions);
      setSelectedAssetTypeKeys(selectedAssetType);
      setSelectedFileTypeKeys(selectedFileType);
      setFilterDateRange(selectedDateRange);
      dispatch(setShowNewTopicAlert(true));
      setShowApplyButton(false);
      timeoutId = setTimeout(() => {
        dispatch(setShowNewTopicAlert(false));
      }, 4000);
    } else {
      applyFilters();
    }
  };

  const onRemoveDataAction = () => {
    dispatch(removeFilterData([]));
    setSelectedAssetTypeKeys([]);
    setSelectedDateRangeKeys([]);
    setSelectedFileTypeKeys([]);
    setSelectedFunctionKeys([]);
    setSelectedIndustryKeys([]);
    setShowClearButton(false);
    setShowApplyButton(false);
    dispatch(setAllFileTypesSelected(fileType));
    dispatch(setAllAssetTypesSelected(getAllIds(assetTypeTaxonomy)));
  };

  const handleClearFilterAction = () => {
    onRemoveDataAction();
    toast.success("Filters successfully cleared");
    // handleResetFiltersData();
  };

  return (
    <>
      <div id="sidebar" className="sidebar position-fixed">
        <span className="sidebar-overlay" onClick={toggleMenu}></span>
        <div className="mobileView toggle-click" onClick={toggleMenu}>
          <div className="arrow-list">
            <img className="icon" src={IMAGES.arrowMenu} alt="Icon" />
          </div>
          Filters
        </div>

        <div className="sidebar-inner-main">
          <div className="filter-head acn-flex acn-flex-between acn-flex-middle">
            <span className="filter-label">
              <img className="icon" src={IMAGES.filterIcon} alt="Icon" /> Filters
            </span>
            {showApplyButton && (
              <Button
                className="add-user-button acn-flex acn-flex-middle mr-5"
                loading={isFiltersDataLoading}
                onClick={handleApplyFilterAction}>
                Apply
              </Button>
            )}
            {showClearButton && selectedChatHistory?.id === -1 && (
              <Button type="text" className="filter-btn" onClick={handleClearFilterAction}>
                Clear
              </Button>
            )}
          </div>

          <Sider width={"100%"} className="sidebar-scroll sidebar-inner">
            <Menu mode="inline" defaultOpenKeys={[TFiltersType.Industry]}>
              <Menu.SubMenu key={TFiltersType.Industry} title="Industry">
                <Tree
                  checkable
                  defaultSelectedKeys={industryList.length > 0 ? selectedIndustryKeys : []}
                  onCheck={(e, i) => onCheck(e, i, TFiltersType.Industry)}
                  treeData={industryList}
                  multiple={true}
                  checkedKeys={selectedIndustryKeys} //redux state
                />
              </Menu.SubMenu>
            </Menu>
            <Menu mode="inline" defaultOpenKeys={[TFiltersType.Functions]}>
              <Menu.SubMenu key={TFiltersType.Functions} title="Functions">
                <Tree
                  checkable
                  defaultCheckedKeys={functionsList.length > 0 ? selectedFunctionKeys : []}
                  onCheck={(e, i) => onCheck(e, i, TFiltersType.Functions)}
                  treeData={functionsList}
                  checkedKeys={selectedFunctionKeys}
                />
              </Menu.SubMenu>
            </Menu>
            <Menu mode="inline">
              <Menu.SubMenu key={TFiltersType.AssetType} title="Asset Type">
                <Tree
                  checkable
                  defaultCheckedKeys={assetTypeList.length > 0 ? selectedAssetTypeKeys : []}
                  onCheck={(e, i) => onCheck(e, i, TFiltersType.AssetType)}
                  treeData={assetTypeList}
                  checkedKeys={selectedAssetTypeKeys}
                />
              </Menu.SubMenu>
            </Menu>
            <Menu mode="inline">
              <Menu.SubMenu key={TFiltersType.FileType} title="File Type">
                <Tree
                  checkable
                  defaultCheckedKeys={fileType.length > 0 ? selectedFileTypeKeys : []}
                  onCheck={(e, i) => onCheck(e, i, TFiltersType.FileType)}
                  treeData={fileType}
                  checkedKeys={selectedFileTypeKeys}
                />
              </Menu.SubMenu>
            </Menu>

            <div hidden={true}>
              <Menu mode="inline">
                <Menu.SubMenu key={TFiltersType.DateRange} title="Date Range">
                  <RangePicker
                    defaultValue={
                      selectedDateRangeKeys
                        ? [dayjs(selectedDateRangeKeys[0]), dayjs(selectedDateRangeKeys[1])]
                        : [dayjs(), dayjs()]
                    }
                    placement="bottomLeft"
                    onChange={onChange}
                    onOk={onOk}
                  />
                </Menu.SubMenu>
              </Menu>
            </div>
          </Sider>
        </div>
      </div>
    </>
  );
};

export default SideBar;
