import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input, MenuProps, Skeleton } from "antd";
import dotsIcon from "../../assets/images/icons/dots2-icon.svg";
import {
  IChatHistory,
  ITags,
} from "../../store/conversationalSearch/conversationalSearch.interface";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  setChatHistory,
  setIsOpenChatHistory,
  setSelectedChatHistory,
} from "../../store/conversationalSearch/conversationalSearchSlice";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PATHS, convertToString, formateDate } from "../../shared";
import ConfirmationModal from "./confirmationModal/ConfirmationModal";
import { deleteChatHistory, editChatTitle } from "../../services/conversationalSearch";
import { useChats } from "../../shared/hooks/useChat";
import { TFiltersType } from "../../store/filtersFromTaxonomyData/filters.interface";
import {
  setFilterDateRange,
  setFiltersData,
} from "../../store/filtersFromTaxonomyData/filters.slice";

interface RecentChatHistoryPopUpProps {}
const items: MenuProps["items"] = [
  {
    key: "rename",
    label: <span>Rename</span>,
  },
  {
    key: "delete",
    label: <span>Delete</span>,
  },
];

const RecentChatHistoryPopUp: React.FC<RecentChatHistoryPopUpProps> = ({}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const fromHome = pathname === PATHS.home;
  const navigate = useNavigate();
  const { defaultConfigureState, clearAllChatStates } = useChats();
  const {
    chatHistoryLoading,
    chatHistory,
    selectedChatHistory,
    deleteChatHistoryLoading,
    saveTitleLoading,
  } = useSelector((state: RootState) => state.conversationalSearch);

  const [editTitleRowIndex, setEditTitleRowIndex] = useState<number>();
  const [deleteChatRowIndex, setDeleteChatRowIndex] = useState(-1);
  const [isDeleteChat, setIsDeleteChat] = useState(false);

  const handleChatHistoryAction = (e: string, chatRowIndex: number) => {
    if (e === "rename") {
      setEditTitleRowIndex(chatRowIndex);
    } else {
      setDeleteChatRowIndex(chatRowIndex);
      setIsDeleteChat(true);
    }
  };

  const onChangeHistoryChatTitle = (e: any, index: number) => {
    const updatedChatHistory = chatHistory.map((item, subIndex) => {
      if (subIndex === index) {
        return { ...item, title: e.target.value };
      }
      return item;
    });

    dispatch(setChatHistory(updatedChatHistory));
  };

  const handleUpdateChatTitle = async () => {
    if (editTitleRowIndex !== undefined) {
      const editedId = chatHistory[editTitleRowIndex].id;
      const editedTitle = chatHistory[editTitleRowIndex].title;
      await editChatTitle({ id: editedId, title: editedTitle });
      setEditTitleRowIndex(undefined);
    }
  };

  const handleCancelClick = () => {
    setEditTitleRowIndex(undefined);
  };

  const handleSelectChatHistory = (chatHistory: IChatHistory) => {
    dispatch(setSelectedChatHistory(chatHistory));
    const { chatTaxonomies } = chatHistory;
    const selectedTaxonomies = chatTaxonomies[0];

    const industryFilter = selectedTaxonomies.tags.find(
      (filter: ITags) => filter.taxonomyName === TFiltersType.Industry
    );
    const functionFilter = selectedTaxonomies.tags.find(
      (filter: ITags) => filter.taxonomyName === TFiltersType.Functions
    );
    const assetTypeFilter = selectedTaxonomies.tags.find(
      (filter: ITags) => filter.taxonomyName === TFiltersType.AssetType
    );

    dispatch(
      setFiltersData([
        {
          type: TFiltersType.Industry,
          data: convertToString(industryFilter?.taxonomyNodeIds) || [],
        },
        {
          type: TFiltersType.Functions,
          data: convertToString(functionFilter?.taxonomyNodeIds) || [],
        },
        {
          type: TFiltersType.AssetType,
          data: convertToString(assetTypeFilter?.taxonomyNodeIds) || [],
        },
        { type: TFiltersType.FileType, data: selectedTaxonomies.fileType },
      ])
    );
    dispatch(setFilterDateRange([selectedTaxonomies.startDate, selectedTaxonomies.endDate]));

    if (fromHome) {
      navigate(PATHS.conversationalSearch);
    }
  };

  const handleDelete = async () => {
    const deleteChatId = chatHistory[deleteChatRowIndex].id;
    await deleteChatHistory(deleteChatId);
    setIsDeleteChat(false);
    if (deleteChatId === selectedChatHistory.id) {
      clearAllChatStates();
      defaultConfigureState();
    }
  };

  return (
    <div className="recent-history-dropdown">
      <div className="close-history-container">
        <h3>Recent history</h3>
        <CloseOutlined
          className="close-icon"
          onClick={() => {
            dispatch(setIsOpenChatHistory(false));
          }}
        />
      </div>
      {chatHistoryLoading ? (
        <div className="loader-history-list">
          <Skeleton active />
        </div>
      ) : (
        <div className="history-list">
          {chatHistory
            .slice(0, fromHome ? 3 : chatHistory.length)
            .map((item: IChatHistory, index: number) => (
              <div
                className={
                  selectedChatHistory?.id === item.id ? "history-item selected" : "history-item"
                }
                key={item.id}
                onClick={() => handleSelectChatHistory(item)}>
                <span className="left-history">
                  {editTitleRowIndex === index ? (
                    <div className="acn-flex-middle acn-flex">
                      <Input
                        className="custom-ant-input"
                        key={index}
                        id={`input-array-${index + 1}`}
                        placeholder=""
                        value={item.title}
                        onChange={(e) => onChangeHistoryChatTitle(e, index)}
                      />
                      <Button
                        loading={saveTitleLoading}
                        type="text"
                        icon={<CheckOutlined style={{ fontSize: "12px" }} />}
                        shape="circle"
                        onClick={() => handleUpdateChatTitle()}
                      />
                      <Button
                        type="text"
                        icon={<CloseOutlined style={{ fontSize: "12px" }} />}
                        shape="circle"
                        onClick={() => handleCancelClick()}
                      />
                      {/* <CheckOutlined onClick={() => handleDoneClick(index)} /> */}
                    </div>
                  ) : (
                    <span className="item-title">{item.title}</span>
                  )}
                  <span className="time">
                    {!!item?.modifiedOn && formateDate(item?.modifiedOn)}
                  </span>
                </span>
                {editTitleRowIndex !== index && (
                  <span
                    className="right-history"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}>
                    <Dropdown
                      trigger={["click"]}
                      menu={{
                        items,
                        onClick: ({ key }) => {
                          handleChatHistoryAction(key, index);
                        },
                      }}
                      placement="bottomRight"
                      overlayClassName="history-action">
                      <img className="icon" src={dotsIcon} alt="Recent history" />
                    </Dropdown>
                  </span>
                )}
              </div>
            ))}
        </div>
      )}
      <ConfirmationModal
        visible={isDeleteChat}
        title="Delete Chat"
        content="Are you sure you want to delete chat?"
        isLoading={deleteChatHistoryLoading}
        handleCancel={() => {
          setIsDeleteChat(false);
        }}
        handleOk={handleDelete}
      />
    </div>
  );
};

export default RecentChatHistoryPopUp;
