import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import {
  IBusProposalSliceProps,
  IFields,
  IIsDisable,
  IIsLoading,
  IIsShow,
} from "./businessProposal.interface";

export const generationFields = {
  topic: "topic",
  industryFun: "industryFun",
  industry: "industry",
  function: "function",
  agenda: "agenda",
  competitors: "competitors",
};

const isShowInitValue: IIsShow = {
  topic: true,
  industryFun: false,
  agenda: false,
  competitors: false,
};

const isDisableInitValue: IIsDisable = {
  topic: false,
  industryFun: false,
  agenda: false,
  competitors: false,
};

const isLoadingInitValue: IIsLoading = {
  topic: false,
  industryFun: false,
  agenda: false,
  competitors: false,
};

const fieldsInitValue: IFields = {
  topic: "",
  industry: [],
  function: [],
  agenda: [],
  competitors: [],
};

const initialState: IBusProposalSliceProps = {
  isShow: isShowInitValue,
  isDisable: isDisableInitValue,
  isLoading: isLoadingInitValue,
  fields: fieldsInitValue,
};

export const busProposalSlice = createSlice({
  name: "busProposalSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setUpdateIsShow: (state, action: PayloadAction<{ field: string; value: boolean }>) => {
      state.isShow = {
        ...state.isShow,
        [action.payload.field]: action.payload.value,
      };
    },
    clearIsShow: (state) => {
      state.isShow = isShowInitValue;
    },

    setUpdateIsDisable: (state, action: PayloadAction<{ field: string; value: boolean }>) => {
      state.isDisable = {
        ...state.isDisable,
        [action.payload.field]: action.payload.value,
      };
    },
    clearIsDisable: (state) => {
      state.isDisable = isDisableInitValue;
    },

    setUpdateIsLoading: (state, action: PayloadAction<{ field: string; value: boolean }>) => {
      state.isLoading = {
        ...state.isLoading,
        [action.payload.field]: action.payload.value,
      };
    },
    clearIsLoading: (state) => {
      state.isLoading = isLoadingInitValue;
    },

    setUpdateFields: (
      state,
      action: PayloadAction<{ field: string; value: string | React.Key[] | any[] }>
    ) => {
      state.fields = {
        ...state.fields,
        [action.payload.field]: action.payload.value,
      };
    },
    clearFields: (state) => {
      state.fields = fieldsInitValue;
    },

    clearAllDataBusProp: (state) => {
      state.isShow = isShowInitValue;
      state.isDisable = isDisableInitValue;
      state.isLoading = isLoadingInitValue;
      state.fields = fieldsInitValue;
    },
    clearDataOnTopicStart: (state) => {
      state.isShow = isShowInitValue;
      state.isDisable = isDisableInitValue;
      state.isLoading = isLoadingInitValue;
      state.fields = { ...fieldsInitValue, topic: state?.fields?.topic };
    },
  },
});

export const {
  setUpdateIsShow,
  clearIsShow,
  setUpdateIsDisable,
  clearIsDisable,
  setUpdateIsLoading,
  clearIsLoading,
  setUpdateFields,
  clearFields,
  clearAllDataBusProp,
  clearDataOnTopicStart,
} = busProposalSlice.actions;

export default busProposalSlice.reducer;
