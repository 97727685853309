import "./Footer.scss";

const Footer = () => {
  return (
    <>
      <footer className="global-footer">
        <p className="Copyright">© 2024 Accenture. All Right Reserved.</p>
      </footer>
    </>
  );
};

export default Footer;
