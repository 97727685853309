import { Button, Flex, Form, Input, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { IMAGES, PATHS } from "../../../shared";
import "./ForgotPassword.scss";
import { resetOtpPassword, setUserEmailAtForgetPassword } from "../../../store/auth/auth.slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { sendOtp } from "../../../services/authApi";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isSendOtpLoading } = useSelector((state: RootState) => state.auth);

  const onFinish = (value: { email: string }) => {
    const { email } = value;

    dispatch(setUserEmailAtForgetPassword(email));
    sendOtp({ email }, navigate);
  };

  return (
    <>
      <Flex id="login-page" className="login-page">
        <div className="authentication-bg">
          <img className="sitelogo" src={IMAGES.siteLogo} alt="Knowledge Management Portal" />
          <div className="top-effect"></div>
          <Flex className="authenticationTitle" align="center" justify="center">
            <h1>Transform your Knowledge Management using Generative AI </h1>
          </Flex>
        </div>
        <Flex className="loginForm" align="center" justify="center">
          <div className="loginFormInner">
            <h2>
              Knowledge Management Portal <img className="aiIcon" src={IMAGES.aiIcon} />
            </h2>
            <div id="fp-step-2" className="card-inner">
              <h3>Forgot password?</h3>
              <p className="short-text">
                Enter your email below and we’ll send you an otp to reset your password.
              </p>
              <Form
                name="forgotPassword"
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: false }}
                onFinish={onFinish}
                layout="vertical"
                requiredMark={false}
                size="large">
                <Form.Item
                  label="Your email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please input your email.",
                    },
                  ]}>
                  <Input placeholder="" />
                </Form.Item>
                <Row className="forgotPassword" justify="end" align="middle"></Row>
                <Form.Item className="loginBtn">
                  <Button
                    loading={isSendOtpLoading}
                    className="primary-btn"
                    type="primary"
                    htmlType="submit"
                    block>
                    Send OTP
                  </Button>
                </Form.Item>
              </Form>
              <div className="back-btn acn-flex acn-flex-middle acn-flex-center">
                <Button
                  loading={false}
                  className="back-to-log acn-flex acn-flex-middle mt-20"
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    navigate(PATHS.login);
                    dispatch(resetOtpPassword([]));
                  }}
                  block>
                  <img className="back-icon" src={IMAGES.backIconArrow} alt="Back Icon" />
                  <Link to={PATHS.login}> Back to log in</Link>
                </Button>
              </div>
            </div>
          </div>
        </Flex>
      </Flex>
    </>
  );
};

export default ForgotPassword;
