export const fileType = [
  { title: ".pdf", key: "pdf" },
  { title: ".jpg", key: "jpg" },
  { title: ".jpeg", key: "jpeg" },
  { title: ".svg", key: "svg" },

  { title: ".png", key: "png" },
  { title: ".csv", key: "csv" },
  { title: ".pptx", key: "pptx" },
  { title: ".ppt", key: "ppt" },

  { title: ".txt", key: "txt" },
  { title: ".docx", key: "docx" },
  { title: ".doc", key: "doc" },
  { title: ".xls", key: "xls" },

  { title: ".xlsx", key: "xlsx" },
  { title: ".mp3", key: "mp3" },
  { title: ".mp4", key: "mp4" },
  { title: ".mpeg", key: "mpeg" },

  { title: ".mpga", key: "mpga" },
  { title: ".m4a", key: "m4a" },
  { title: ".ogg", key: "ogg" },
  { title: ".wav", key: "wav" },
  { title: ".webm", key: "webm" },
];
