import { Navigate, Outlet, useLocation } from "react-router-dom";
import { LocalStorage, PATHS, UserRoles, getUserRole } from "../../shared";

const ProtectedRoute = () => {
  const isLogin = localStorage.getItem(LocalStorage.Token);
  const { pathname } = useLocation();

  const renderContent = () => {
    if (!isLogin) {
      return <Navigate to={PATHS.login} />;
    }

    if (
      (![UserRoles.Admin, UserRoles.ContentEditor].includes(getUserRole()) &&
        pathname.includes(PATHS.admin)) ||
      (getUserRole() !== UserRoles.Admin &&
        [
          String(PATHS.adminUserManagement),
          PATHS.adminTaxonomyManagement,
          PATHS.adminTaxonomyOverview,
          PATHS.adminLogs,
        ].includes(pathname))
    ) {
      return <Navigate to={PATHS.home} />;
    }
    return <Outlet />;
  };

  return renderContent();
};

export default ProtectedRoute;
