import { TreeSelect } from "antd";
import { IMappedIndustry } from "../../../../store/contentManagement/content.interface";

interface IEditContentTags {
  selectedTags: string[];
  mappedContentTags: IMappedIndustry[];
  placeholder: string;
  popupClassName: string;
  handleChange: (selectedNodes: string[]) => void;
  isNonLeafNodeDisable?: boolean;
}

const EditContentTags = ({
  selectedTags,
  mappedContentTags,
  placeholder,
  popupClassName,
  handleChange,
  isNonLeafNodeDisable = true,
}: IEditContentTags) => {
  const isLeaf = (node: any) => {
    return !node.children || node.children.length === 0;
  };

  const enableDisableNonLeafNodes = (nodes: any[]): any => {
    return nodes.map((node: any) => {
      if (!isLeaf(node)) {
        return {
          ...node,
          disabled: isNonLeafNodeDisable,
          children: enableDisableNonLeafNodes(node.children),
        };
      }
      return node;
    });
  };

  return (
    <>
      <TreeSelect
        showSearch
        value={selectedTags}
        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
        placeholder={placeholder}
        allowClear={false}
        treeDefaultExpandAll
        onChange={handleChange}
        treeData={enableDisableNonLeafNodes(mappedContentTags)}
        multiple
        treeLine
        treeCheckable={isNonLeafNodeDisable}
        maxTagCount={3}
        popupClassName={popupClassName}
      />
    </>
  );
};

export default EditContentTags;
