import { Button, Flex, Form, Input } from "antd";
import { Link, useNavigate } from "react-router-dom";
import "./ResetPassword.scss";
import { IMAGES, PATHS } from "../../../shared";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import { useLayoutEffect } from "react";
import { resetPassword } from "../../../services/authApi";
// import { PasswordField } from "../../../components/PasswordField/PasswordField";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { userId } = useSelector((state: RootState) => state.auth);
  const { resetPasswordLoading } = useSelector((state: RootState) => state.auth);

  useLayoutEffect(() => {
    if (userId < 0) {
      navigate(PATHS.login);
      window.location.reload();
    }
  }, [userId]);

  const onFinish = (values: {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
  }) => {
    resetPassword({ ...values, userId }, navigate);
  };
  const [form] = Form.useForm();
  // const handlePasswordchange = (password: string) => {
  //   form.setFieldsValue({ password: password });
  //   setForm({ ...resetPasswordForm, password });
  // };
  return (
    <>
      <Flex id="login-page" className="login-page">
        <div className="authentication-bg">
          <img className="sitelogo" src={IMAGES.siteLogo} alt="Knowledge Management Portal" />
          <div className="top-effect"></div>
          <Flex className="authenticationTitle" align="center" justify="center">
            <h1>Transform your Knowledge Management using Generative AI </h1>
          </Flex>
        </div>
        <Flex className="loginForm" align="center" justify="center">
          <div className="loginFormInner">
            <h2>
              Knowledge Management Portal <img className="aiIcon" src={IMAGES.aiIcon} />
            </h2>

            <div id="fp-step-2" className="card-inner">
              <h3>Set a new password</h3>
              <p className="short-text">
                Your new password must be different to previously used passwords.
              </p>
              <Form
                name="login"
                form={form}
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 24 }}
                onFinish={onFinish}
                layout="vertical"
                requiredMark={false}
                size="large">
                {/* <PasswordField passwordVal={resetPasswordForm.password} handlePasswordchange={handlePasswordchange}/> */}
                <Form.Item
                  label="Password"
                  name="oldPassword"
                  className="InputPassword"
                  rules={[{ required: true, message: "Please input your password" }]}>
                  <Input.Password placeholder="" />
                </Form.Item>
                <Form.Item
                  label="New Password"
                  name="newPassword"
                  className="InputPassword"
                  rules={[{ required: true, message: "Please input your new password" }]}>
                  <Input.Password placeholder="" />
                </Form.Item>
                <Form.Item
                  label="Confirm password"
                  name="confirmPassword"
                  className="InputPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input your new password again",
                    },
                  ]}>
                  <Input.Password placeholder="" />
                </Form.Item>
                <Form.Item className="loginBtn">
                  <Button
                    loading={resetPasswordLoading}
                    className="primary-btn mt-20 mb-20"
                    type="primary"
                    htmlType="submit"
                    block>
                    Update password
                  </Button>
                </Form.Item>
              </Form>
              <div className="back-btn acn-flex acn-flex-middle acn-flex-center">
                <Button
                  className="back-to-log acn-flex acn-flex-middle"
                  type="primary"
                  htmlType="submit"
                  block
                  onClick={() => navigate(PATHS.login)}>
                  <img className="back-icon" src={IMAGES.backIconArrow} alt="Back Icon" />
                  <Link to={PATHS.login}> Back to log in</Link>
                </Button>
              </div>
            </div>
          </div>
        </Flex>
      </Flex>
    </>
  );
};

export default ResetPassword;
