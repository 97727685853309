import { Button, Card, Col, Row, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "../../../store";
import {
  generationFields,
  setUpdateIsDisable,
  setUpdateIsShow,
  setUpdateFields,
} from "../../../store/generation/businessProposal/businessProposalSlice";
import { IMAGES } from "../../../shared";

const IndustryFun = () => {
  const dispatch = useDispatch();
  const { isLoading, isDisable, fields } = useSelector(
    (state: RootState) => state.busProposalSlice
  );

  const handleNext = async () => {
    if (
      (fields?.industry && fields?.industry?.length) ||
      (fields?.function && fields?.function?.length)
    ) {
      dispatch(setUpdateIsShow({ field: generationFields.agenda, value: true }));
      dispatch(setUpdateIsDisable({ field: generationFields.industryFun, value: true }));
    } else {
      toast.error("Please select industry or function");
    }
  };

  const handleSelectIndustry = (value: string[]) => {
    dispatch(setUpdateFields({ field: generationFields.industry, value: value }));
  };

  const handleSelectFunction = (value: string[]) => {
    dispatch(setUpdateFields({ field: generationFields.function, value: value }));
  };

  return (
    <>
      <Card className="card-item">
        <div className="card-header">
          <span className="card-number">2</span>
          <h2>Selected Industry and Function</h2>
        </div>
        <div className="card-content">
          <div className="selects-wrap">
            <Row gutter={30}>
              <Col span={4}>
                <div className="asset-type-select">
                  <label>Industry</label>
                  <Select
                    mode="multiple"
                    maxTagCount={1}
                    popupClassName="ui-dropdown-default"
                    placeholder="Choose Industry"
                    value={fields?.industry || []}
                    onChange={handleSelectIndustry}
                    disabled={isDisable?.industryFun || isLoading?.industryFun}>
                    <Select.Option value="sample1">Sample1</Select.Option>
                    <Select.Option value="sample2">Sample2</Select.Option>
                    <Select.Option value="sample3">Sample3</Select.Option>
                  </Select>
                </div>
              </Col>
              <Col span={4}>
                <div className="asset-type-select">
                  <label>Function</label>
                  <Select
                    mode="multiple"
                    maxTagCount={1}
                    popupClassName="ui-dropdown-default"
                    placeholder="Choose Function"
                    value={fields?.function || []}
                    onChange={handleSelectFunction}
                    disabled={isDisable?.industryFun || isLoading?.industryFun}>
                    <Select.Option value="sample1">Sample1</Select.Option>
                    <Select.Option value="sample2">Sample2</Select.Option>
                    <Select.Option value="sample3">Sample3</Select.Option>
                  </Select>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="card-footer acn-flex acn-flex-end">
          <div className="card-footer-right acn-flex acn-flex-middle">
            {isLoading?.industryFun && (
              <div className="processing-wrap">
                <img src={IMAGES.loadingSpinner} alt="loading" className="spinnerSvg" />
                Processing
              </div>
            )}

            <Button
              type="primary"
              className="outline-btn"
              disabled={isDisable?.industryFun || isLoading?.industryFun}
              onClick={handleNext}>
              Next
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
};

export default IndustryFun;
