import { useDispatch, useSelector } from "react-redux";
import { Button, Flex, Form, Input, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import { IMAGES, PATHS } from "../../../shared";
import { RootState } from "../../../store";
import { sendOtp, verifyOtp } from "../../../services/authApi";
import { resetOtpPassword, setOtpAtForgetPassword } from "../../../store/auth/auth.slice";
import { LoadingOutlined } from "@ant-design/icons";

const OTP = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [form] = useForm();

  const { userEmailAtForgetPassword, isSendOtpLoading, isVerifyOtpLoading } = useSelector(
    (state: RootState) => state.auth
  );

  const handleValueChange = (value: { otp: string }) => {
    const { otp } = value;

    if (otp && /^[0-9]*$/.test(otp) && otp.length === 6) {
      form.submit(); // Submit the form when OTP is valid
    }
  };

  const onFinish = (value: { otp: number }) => {
    const { otp } = value;

    dispatch(setOtpAtForgetPassword(otp));
    verifyOtp({ email: userEmailAtForgetPassword, otp }, navigate);
  };

  const handleResendOtp = () => {
    sendOtp({ email: userEmailAtForgetPassword }, navigate);
    form.resetFields();
  };

  return (
    <>
      <Flex id="login-page" className="login-page">
        <div className="authentication-bg">
          <img className="sitelogo" src={IMAGES.siteLogo} alt="Knowledge Management Portal" />
          <div className="top-effect"></div>
          <Flex className="authenticationTitle" align="center" justify="center">
            <h1>Transform your Knowledge Management using Generative AI </h1>
          </Flex>
        </div>
        <Flex className="loginForm" align="center" justify="center">
          <div className="loginFormInner">
            <h2>
              Knowledge Management Portal <img className="aiIcon" src={IMAGES.aiIcon} />
            </h2>
            <div id="fp-step-2" className="card-inner">
              <h3>Check your email</h3>
              <p className="short-text">
                we've sent a password reset link to {userEmailAtForgetPassword ?? ""}
              </p>
              <Form
                name="otp"
                form={form}
                layout="vertical"
                onFinish={onFinish}
                requiredMark={false}
                validateTrigger="onSubmit"
                onValuesChange={handleValueChange}
                onFinishFailed={() => form.submit()}>
                <Form.Item
                  label="OTP"
                  name="otp"
                  validateTrigger="onChange"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (!/^[0-9]*$/.test(value)) {
                          return Promise.reject(new Error("Please enter only numbers"));
                        }
                        if (value.length !== 6) {
                          return Promise.reject(new Error("OTP must be 6 digits"));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}>
                  <Input
                    className="otp-input-field"
                    placeholder="123456"
                    suffix={isVerifyOtpLoading ? <LoadingOutlined /> : null}
                  />
                </Form.Item>

                <Row className="forgotPassword" justify="end" align="middle"></Row>
              </Form>
              <p className="receive-email no-border">
                Didn’t receive an email yet?{" "}
                <Button
                  className="resendButton"
                  onClick={handleResendOtp}
                  type="text"
                  loading={isSendOtpLoading}>
                  <span className="resendText">Click to resend</span>
                </Button>
              </p>
              <div className="back-btn acn-flex acn-flex-middle acn-flex-center">
                <Button
                  loading={false}
                  className="back-to-log acn-flex acn-flex-middle backToLoginContainer"
                  type="primary"
                  htmlType="submit"
                  block
                  onClick={() => {
                    navigate(PATHS.login);
                    dispatch(resetOtpPassword([]));
                  }}>
                  <img className="back-icon" src={IMAGES.backIconArrow} alt="Back Icon" />
                  Back to log in
                </Button>
              </div>
            </div>
          </div>
        </Flex>
      </Flex>
    </>
  );
};

export default OTP;
