import { Outlet, useLocation } from "react-router-dom";
import "./AppLayout.scss";
import { AdminSideBar } from "../../admin";
import { Footer, PATHS } from "../../shared";
import { Header, SideBar } from "../../components";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import PDFViewer from "../../components/pdf/PDFViewer";

const AppLayout = () => {
  const { pathname } = useLocation();
  const { isShowPDF } = useSelector((state: RootState) => state.pdfViewer);

  const renderSideNav = (): boolean => {
    if (
      pathname === PATHS.home ||
      pathname === PATHS.browseProject ||
      pathname === PATHS.about ||
      pathname === PATHS.browseCategory ||
      pathname === PATHS.generation ||
      pathname === PATHS.generateKPIs ||
      pathname === PATHS.generateKPIs ||
      pathname === PATHS.generateBusinessProposal ||
      (pathname.includes(PATHS.viewContentManagementByUser) &&
        !pathname.includes(PATHS.viewEditContentManagement))
    )
      return false;
    return true;
  };

  const renderAdminSideNav = (): boolean => {
    if (pathname.includes("/admin")) return true;
    return false;
  };

  return (
    <div className="appLayoutContainer">
      <Header />
      <div
        className={
          renderSideNav()
            ? renderAdminSideNav()
              ? "sideBarOutletWrap sidebar-with-content sidebar-with-admin-content"
              : "sideBarOutletWrap sidebar-with-content"
            : "sideBarOutletWrap"
        }>
        {renderAdminSideNav() ? <AdminSideBar /> : <SideBar />}
        <div className="outletWrap">
          <Outlet />
          <Footer />
        </div>
        {!!isShowPDF && <PDFViewer />}
      </div>
    </div>
  );
};

export default AppLayout;
