import { Card, Col } from "antd";
import selectIcon from "../../assets/images/icons/select-icon.png";
import { ITaxonomyTree } from "../../store/taxonomy/taxonomy.interface";

interface BrowseCategoryLevelComponentProps {
  item: ITaxonomyTree;
  index: number;
  handleCategoryClick: Function;
}
const BrowseCategoryLevelComponent: React.FC<BrowseCategoryLevelComponentProps> = ({
  item,
  index,
  handleCategoryClick,
}) => {
  return (
    <Col className="select-box" span={8} key={index} onClick={() => handleCategoryClick(index)}>
      <Card>
        <div className="select-inner">
          <img src={selectIcon} alt="add" />
          <h5>
            {item.taxonomyNodeName}{" "}
            {item?.children?.length ? <em>{`(${item?.children?.length})`}</em> : ""}
          </h5>
          <span className="arrow-right"></span>
        </div>
      </Card>
    </Col>
  );
};

export default BrowseCategoryLevelComponent;
