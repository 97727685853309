import { store } from "../store";
import { get, post } from "./apiClients";
import {
  setIsLinkedAssetLoading,
  setIsLoading,
  setIsRecentlyViewedAssetsLoading,
  setLinkedAssetsData,
  setRecentlyUploadedAssets,
  setRecentlyViewedAssets,
} from "../store/assets/assets.slice";

export const getRecentlyUploadedAssets = async () => {
  try {
    store.dispatch(setIsLoading(true));
    const res = await get(`/core/asset/recentlyUploadedAsset`);
    store.dispatch(setRecentlyUploadedAssets(res));
  } catch (error: any) {
    console.log(error, "getRecentlyUploadedAssets api error");
  } finally {
    store.dispatch(setIsLoading(false));
  }
};

export const updateRecentlyViewedAsset = async (request: { userId: number; assetId: number }) => {
  try {
    store.dispatch(setIsLoading(true));
    await post(`/core/asset/assetView?assetId=${request.assetId}&userId=${request.userId}`);
    getRecentlyViewedAssets(request.userId);
  } catch (error: any) {
    store.dispatch(setIsLoading(false));
    console.log(error, "updateRecentlyViewedAsset api error");
  }
};

export const getRecentlyViewedAssets = async (userId: number) => {
  try {
    store.dispatch(setIsRecentlyViewedAssetsLoading(true));
    const res = await get(`/core/asset/viewedAssetList/${userId}`);
    store.dispatch(setRecentlyViewedAssets(res));
  } catch (error: any) {
    console.log(error, "getRecentlyViewedAssets api error");
  } finally {
    store.dispatch(setIsRecentlyViewedAssetsLoading(false));
  }
};

export const getAssetsDataByIds = async (assetIds: number[]) => {
  try {
    store.dispatch(setIsLinkedAssetLoading(true));
    const ids = assetIds.join(",");
    const res = await get(`/core/asset/assetList/${ids}`);
    store.dispatch(setLinkedAssetsData(res));
    return res;
  } catch (error) {
    console.log(error);
    return error;
  } finally {
    store.dispatch(setIsLinkedAssetLoading(false));
  }
};
