import { Card, Carousel, Col, Image, Input, Row, Select, Skeleton } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RecentChatHistoryPopUp, WheelChart } from "../../components";
import {
  getRecentlyUploadedAssets,
  getRecentlyViewedAssets,
  updateRecentlyViewedAsset,
} from "../../services/assets";
import {
  IMAGES,
  formateDate,
  getAllIds,
  getLocalStorageItem,
  getFunctionsList,
  getIndustryList,
  getUserId,
  handleOpenPdfDoc,
} from "../../shared";
import { FromWhere, LocalStorage, PATHS } from "../../shared/constants";
import { useChats } from "../../shared/hooks/useChat";
import { RootState } from "../../store";
import {
  setIsOpenChatHistory,
  setUserQuestion,
} from "../../store/conversationalSearch/conversationalSearchSlice";
import {
  removeFilterData,
  setAllAssetTypesSelected,
  setAllFileTypesSelected,
  setBrowseCategoryCurrentLevel,
  setBrowseCategoryFiltersData,
  setBrowseCategorySelectedCategory,
  setBrowseCategorySelectedFunctionValue,
  setBrowseCategorySelectedIndustryValue,
  setBrowseCategorySelectedRadioOption,
  taxonomyByInitValue,
} from "../../store/filtersFromTaxonomyData/filters.slice";
import { setPdfDetails } from "../../store/pdfViewer/pdfViewer.slice";
import "./Home.scss";
import { fileType } from "../../components/sideBar/FileTypeData";
import { TFiltersType } from "../../store/filtersFromTaxonomyData/filters.interface";
import { toast } from "react-toastify";

export interface Industry {
  value: string;
  label: string;
  key: string;
  children: any;
  taxonomyNodeId: number;
}

const Home = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5.5,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const chatHistoryRef = useRef<HTMLDivElement>(null);
  const { clearAllChatStates, defaultConfigureState } = useChats();
  const {
    recentlyUploadedAssets,
    recentlyViewedAssets,
    isRecentlyUploadedAssetsLoading,
    isRecentlyViewedAssetsLoading,
  } = useSelector((state: RootState) => state.assets);
  const { industryTaxonomy, functionTaxonomy, assetTypeTaxonomy } = useSelector(
    (state: RootState) => state.taxonomy
  );
  const { isOpenChatHistory } = useSelector((state: RootState) => state.conversationalSearch);
  const [industryList, setIndustryList] = useState<Industry[]>([]);
  const [functionsList, setFunctionsList] = useState<string[]>([]);
  const [username, setUsername] = useState<string>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [selectedImageURL, setSelectedImageURL] = useState<string | null>(null);
  const [scaleStep] = useState(0.5);
  useEffect(() => {
    getRecentlyUploadedAssets();
    const localStorageItem = getLocalStorageItem(LocalStorage.User);
    const details: string | undefined = localStorageItem !== null ? localStorageItem : undefined;
    let parsedDetails: any;
    if (details) {
      parsedDetails = JSON.parse(details);
      setUsername(parsedDetails.name);
      getRecentlyViewedAssets(parsedDetails.id);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (chatHistoryRef.current && !chatHistoryRef.current.contains(event.target as Node)) {
        if (isOpenChatHistory) {
          dispatch(setIsOpenChatHistory(false));
        }
      }
    };
    document.body.addEventListener("click", handleClickOutside);
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [isOpenChatHistory]);

  useEffect(() => {
    if (industryTaxonomy.taxonomyTree.length > 0) {
      setIndustryList(getIndustryList(industryTaxonomy.taxonomyTree));
    }
    if (functionTaxonomy.taxonomyTree.length > 0) {
      setFunctionsList(getFunctionsList(functionTaxonomy.taxonomyTree));
    }

    dispatch(setBrowseCategoryCurrentLevel([]));
    dispatch(setBrowseCategoryFiltersData(null));
    dispatch(setBrowseCategorySelectedCategory(taxonomyByInitValue));
  }, [industryTaxonomy, functionTaxonomy]);

  const handleChange = (_: string, data: any) => {
    dispatch(setBrowseCategorySelectedIndustryValue(data.taxonomyNodeName));
    dispatch(setBrowseCategorySelectedFunctionValue("Select Function"));
    dispatch(setBrowseCategorySelectedRadioOption(TFiltersType.Industry));
    navigate(PATHS.browseCategory, { state: { data, filterType: TFiltersType.Industry } });
  };

  const handleEnterKeyAction = (e: any) => {
    if (e.key === "Enter" && e.target.value.trim()) {
      dispatch(removeFilterData([]));
      dispatch(setAllFileTypesSelected(fileType));
      dispatch(setAllAssetTypesSelected(getAllIds(assetTypeTaxonomy)));
      clearAllChatStates();
      defaultConfigureState();
      dispatch(setUserQuestion(e.target.value.trim()));
      navigate(PATHS.conversationalSearch);
    }
  };

  const renderSkeletonLoader = () => {
    const arr = [1, 2, 3, 4, 5, 6];
    return arr.map((item) => (
      <div key={item}>
        <Col>
          <Card className="home-assets-card skeleton-style">
            <Skeleton.Image active style={{ width: "100%" }} />
            <Skeleton.Input className="card-title" active size="small" />
            <Skeleton.Input className="viewed-file" active size="small" />
          </Card>
        </Col>
      </div>
    ));
  };

  const handleRecentlyViewedClick = (
    assetId: number,
    fileName: string,
    presignedUrl: string,
    title: string
  ) => {
    setPdfDetails({
      fileName,
      presignedUrl,
      assetId,
      title,
      page: 1,
      showOpenIcon: false,
    });
    navigate(`${PATHS.viewContentManagementByUser}/${assetId}`, {
      state: { fromWhere: FromWhere.Home },
    });
  };

  const handleRecentlyUploadedClick = (
    assetId: number,
    fileName: string,
    presignedUrl: string,
    title: string
  ) => {
    dispatch(
      setPdfDetails({
        fileName,
        presignedUrl,
        assetId,
        title,
        page: 1,
        showOpenIcon: false,
      })
    );
    updateRecentlyViewedAsset({
      userId: getUserId(),
      assetId,
    });
    navigate(`${PATHS.viewContentManagementByUser}/${assetId}`, {
      state: { fromWhere: FromWhere.Home },
    });
  };

  return (
    <div className="home-page">
      <div className="home-banner">
        <div className="container">
          <Row>
            <Col span={12}>
              <div className="inner-ai">
                <h1>
                  {`Welcome ${username}`} <img src={IMAGES.handWave} />
                </h1>
                <div className="get-started">
                  <label>Get started</label>
                  <Select
                    popupClassName="ui-dropdown-default"
                    defaultValue="Select Industry"
                    onChange={(e, i) => handleChange(e, i)}
                    options={industryList}
                  />
                </div>
                <div className="conversational-ai">
                  <h2>
                    Discover <strong>insights</strong> with Conversational AI
                  </h2>
                  <div className="ai-search-input position-relative">
                    <div className="position-relative">
                      <img
                        className="search-icon position-absolute"
                        src={IMAGES.searchIcon}
                        alt="Search Icon"
                      />
                      <Input
                        type="text"
                        placeholder="Search here..."
                        onKeyDownCapture={handleEnterKeyAction}
                      />
                    </div>
                    <span className="key-areas">
                      or <strong>Key Areas</strong>
                    </span>
                  </div>
                  <div className="inner-span">
                    <span className="powered-by-ai">
                      <img className="aiIcon" src={IMAGES.aiIcon} />
                      POWERED BY AI
                    </span>
                    <span className="recent-history" ref={chatHistoryRef}>
                      <div
                        className="acn-flex acn-flex-middle"
                        onClick={() => dispatch(setIsOpenChatHistory(true))}>
                        <img className="history-icon" src={IMAGES.historyWhiteIcon} />
                        Recent history
                      </div>
                      {isOpenChatHistory && <RecentChatHistoryPopUp />}
                    </span>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={12} className="wheel-chart-home">
              <WheelChart data={functionsList} />
            </Col>
          </Row>
        </div>
      </div>

      {recentlyViewedAssets.length > 0 && (
        <div className="recently-viewed">
          <div className="container">
            <div className="assets-title">
              <h3>Recently viewed assets</h3>
              <h4 className="showing-results">{`Showing ${recentlyViewedAssets.length} results`}</h4>
            </div>

            <Carousel className="assets-carousel " {...settings}>
              {isRecentlyViewedAssetsLoading
                ? renderSkeletonLoader()
                : recentlyViewedAssets.map((item, index) => (
                    <div key={index}>
                      <Col>
                        <Card
                          className="home-assets-card cursor-pointer"
                          onClick={() =>
                            handleRecentlyViewedClick(
                              item.assetId,
                              item.fileName,
                              item.preSignedUrl,
                              item?.title
                            )
                          }>
                          <div
                            className="thumbnail"
                            style={{ backgroundImage: `url(${item?.thumbnailPreSignedUrl})` }}>
                            <span className="file-link-icon">
                              <img src={IMAGES.linkIcon} />
                            </span>
                          </div>
                          <h3
                            className="card-title"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (["png", "jpg", "jpeg"].includes(item?.fileType || "")) {
                                setVisible(true);
                                setSelectedImageURL(item.pdfPreSignedUrl);
                              } else {
                                if (item.fileType === "xls" || item.fileType === "xlsx") {
                                  toast.error("Cannot open preview of this file");
                                } else {
                                  handleOpenPdfDoc(
                                    item?.assetId,
                                    item.fileName,
                                    item?.preSignedUrl,
                                    item?.title,
                                    1,
                                    true
                                  );
                                }
                              }
                            }}>
                            {item.assetUrl
                              ? item.assetUrl
                              : item?.title
                              ? item.title
                              : item.originalFileName}
                          </h3>
                          <div className="viewed-file">
                            <span className="eye-icon">
                              <img src={IMAGES.eyeIcon} />
                            </span>
                            {`Viewed ${formateDate(item.viewedOn)}`}
                          </div>
                        </Card>
                      </Col>
                    </div>
                  ))}
            </Carousel>
          </div>
        </div>
      )}
      <div className="recently-uploaded">
        <div className="container">
          <div className="assets-title">
            <h3>Recently uploaded assets</h3>
            <h4 className="showing-results">{`Showing ${recentlyUploadedAssets.length} results`}</h4>
          </div>
          <Carousel className="assets-carousel " {...settings}>
            {isRecentlyUploadedAssetsLoading
              ? renderSkeletonLoader()
              : recentlyUploadedAssets.map((item, index) => (
                  <div key={index}>
                    <Col>
                      <Card
                        className="home-assets-card cursor-pointer"
                        onClick={() =>
                          handleRecentlyUploadedClick(
                            item.asset_id,
                            item.file_name,
                            item.pre_signed_url,
                            item.title
                          )
                        }>
                        <div
                          className="thumbnail"
                          style={{
                            backgroundImage: `url(${item?.thumbnail_pre_signed_url})`,
                          }}>
                          <span className="file-link-icon">
                            <img src={IMAGES.linkIcon} />
                          </span>
                          <div className="hover-effect">
                            <h3
                              className="card-title"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (["png", "jpg", "jpeg"].includes(item?.file_type || "")) {
                                  setVisible(true);
                                  setSelectedImageURL(item.pre_signed_url);
                                } else {
                                  if (item.file_type === "xls" || item.file_type === "xlsx") {
                                    toast.error("Cannot open preview of this file");
                                  } else {
                                    handleOpenPdfDoc(
                                      item?.asset_id,
                                      item.file_name,
                                      item?.pre_signed_url,
                                      item?.title,
                                      1,
                                      true
                                    );
                                  }
                                }
                              }}>
                              {item.asset_url
                                ? item.asset_url
                                : item?.title
                                ? item.title
                                : item.original_file_name}
                            </h3>
                            <div className="viewed-file">{`Viewed ${formateDate(
                              item.uploadedOn
                            )}`}</div>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </div>
                ))}
          </Carousel>
        </div>
      </div>
      {visible && (
        <div style={{ display: "none" }}>
          <Image
            preview={{
              visible,
              scaleStep,
              src: `${selectedImageURL}`,
              onVisibleChange: (value) => {
                setVisible(value);
              },
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Home;
