import { Button, Card, Col, Image, Row, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  FromWhere,
  IMAGES,
  Images,
  PATHS,
  PageLimit,
  handleOpenPdfDoc,
  renderFileTypeIcon,
} from "../../../shared";
import { RootState } from "../../../store";
import { useEffect, useState } from "react";
import { ICitations } from "../../../store/conversationalSearch/conversationalSearch.interface";
import { toast } from "react-toastify";

interface IGroupedItems {
  id: number;
  title: string;
  file_name: string;
  original_file_name: string;
  page: number[];
  file_type: string;
}

const OtherAssets = () => {
  const navigate = useNavigate();
  const { isFiltersDataLoading } = useSelector((state: RootState) => state.filtersData);
  const { selectedChatHistory } = useSelector((state: RootState) => state.conversationalSearch);
  const { messages } = selectedChatHistory;
  const citations = messages[messages?.length - 1]?.citations;
  const [isGridView, setGridView] = useState(true);
  const [listViewRecords, setListViewRecords] = useState<IGroupedItems[]>([]);
  const [citationList, setCitationList] = useState(citations);
  const [totalCitations, setTotalCitations] = useState(citations);
  const [visible, setVisible] = useState(false);
  const [selectedImageURL, setSelectedImageURL] = useState<string | undefined>("");
  const [scaleStep] = useState(0.5);

  useEffect(() => {
    if (citations && citations?.length > 0) {
      const filteredCitations = citations.filter((c) => c.asset_level === "secondary");
      setCitationList(filteredCitations?.slice(0, 20));
      setTotalCitations(filteredCitations);
      const groupedData = groupItemsByFileName(filteredCitations?.slice(0, 20));
      setListViewRecords(groupedData);
    }
  }, [citations]);

  const loadDataInBatch = () => {
    let tempdata: ICitations[] = [...citationList];
    const startIndex = tempdata.length;

    if (totalCitations.length - tempdata.length < PageLimit.ConversationalSearch) {
      const remainingItems = totalCitations.slice(tempdata.length);
      tempdata.push(...remainingItems);
    } else {
      const batch = totalCitations.slice(startIndex, startIndex + PageLimit.ConversationalSearch);
      tempdata.push(...batch);
    }
    setCitationList(tempdata);
    const groupedData = groupItemsByFileName(tempdata);
    setListViewRecords(groupedData);
  };

  const groupItemsByFileName = (items: ICitations[]): IGroupedItems[] => {
    const grouped: { [key: string]: IGroupedItems } = {};
    items.forEach((item) => {
      const key = `${item.title}-${item.file_name}-${item.original_file_name}`;
      if (!(key in grouped)) {
        grouped[key] = {
          id: item.asset_id,
          title: item.title,
          file_name: item.file_name,
          original_file_name: item.original_file_name,
          page: [],
          file_type: item?.file_type,
        };
      }
      if (!grouped[key].page.includes(item.page)) {
        grouped[key].page.push(item.page);
      }
    });

    return Object.values(grouped);
  };

  const onLoadMoreDataAction = () => {
    // relevantAssetsTotalPages >= pageNumner ? null : null;
    loadDataInBatch();
  };

  const resultCount = (data: number = 0) => {
    if (data === 0) {
      return "";
    } else if (data < 10) {
      return `Showing top 0${data} results`;
    } else {
      return `Showing top ${data} results`;
    }
  };

  const isImage = (item: ICitations) => Images.includes(item?.file_type || "");

  const handleOnTitleClick = (
    item: ICitations,
    e: React.MouseEvent<HTMLHeadingElement, MouseEvent>
  ) => {
    if (isImage(item)) {
      setVisible(true);
      setSelectedImageURL(item.pre_signed_url);
    } else {
      e.stopPropagation();
      if (item.file_type === "xls" || item.file_type === "xlsx") {
        toast.error("Cannot open preview of this file");
      } else {
        handleOpenPdfDoc(item?.asset_id, item.file_name, "", item?.title, item.page, true);
      }
    }
  };

  const columns: ColumnsType<IGroupedItems> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (_, item) => (
        <>
          <span className="td-label">Name : </span>
          <strong>
            <img src={renderFileTypeIcon(item?.file_type)} alt="Image Icon" />
            {item?.title}
          </strong>
        </>
      ),
    },
    {
      title: "Reference/Page",
      dataIndex: "page",
      key: "page",
      render: (text) => (
        <>
          <span className="td-label">Reference/Page : </span>
          {text?.toString()?.replace(/,/g, ", ")}
        </>
      ),
    },
    {
      title: " ",
      key: "action",
      render: () => (
        <>
          <span className="td-label">Action </span>
          <Button
            className="action-bta"
            // onClick={() => navigate(`${PATHS.viewContentManagementByUser}/${item?.id}`)}
          >
            <img src={IMAGES.linkIcon} alt="Link Icon" className="link-icon" />
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="other-assets">
        <div className="grid-list-view">
          <Tooltip overlayClassName="tooltip-text" color="#fff" title={"Grid View"}>
            <Button
              type="text"
              className={isGridView ? "grid-view active-view" : "grid-view"}
              onClick={() => setGridView(true)}>
              <span className="view-icon grid-view-icon"></span>
            </Button>
          </Tooltip>
          <Tooltip overlayClassName="tooltip-text" color="#fff" title={"List View"}>
            <Button
              type="text"
              className={!isGridView ? "list-view active-view" : "list-view"}
              onClick={() => setGridView(false)}>
              <span className="view-icon list-view-icon"></span>
            </Button>
          </Tooltip>
        </div>
        <h2>Assets found</h2>
        <h4 className="showing-results">
          {resultCount(isGridView ? citationList?.length : listViewRecords?.length)}
        </h4>
        <div className="inner-assets">
          {isGridView ? (
            <div id="grid-view" className="grid-card-view">
              <Row gutter={[14, 26]}>
                {citationList &&
                  !!citationList?.length &&
                  citationList?.map((item) => (
                    <Col span={6}>
                      <Card className="skeleton-style cursor-pointer">
                        <div
                          onClick={() =>
                            navigate(`${PATHS.viewContentManagementByUser}/${item?.asset_id}`, {
                              state: {
                                fromWhere: FromWhere.ConversationalSearch,
                                selectedChatHistoryId: selectedChatHistory?.id,
                              },
                            })
                          }
                          className="thumbnail"
                          style={{
                            backgroundImage: `url(${item?.pre_signed_url})`,
                          }}></div>
                        <span className="card-content">
                          <h3
                            onClick={(e) => {
                              handleOnTitleClick(item, e);
                            }}>
                            {item.title}
                          </h3>
                          <span>{!isImage(item) && item.page + 1}</span>

                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`${PATHS.viewContentManagementByUser}/${item?.asset_id}`, {
                                state: {
                                  fromWhere: FromWhere.ConversationalSearch,
                                  selectedChatHistoryId: selectedChatHistory?.id,
                                },
                              });
                            }}>
                            <img src={IMAGES.iconLink} alt="Icon Link" className="icon-link" />
                          </span>
                          {/* )} */}
                        </span>
                      </Card>
                    </Col>
                    // <Col span={6}>
                    //   <Card>
                    //     <Link
                    //       to={`${PATHS.viewContentManagementByUser}/${item?.asset_id}`}
                    //       state={{
                    //         fromWhere: FromWhere.ConversationalSearch,
                    //         selectedChatHistoryId: selectedChatHistory?.id,
                    //       }}
                    //       type="button"
                    //       className="mr-5">
                    //       <div
                    //         className="thumbnail"
                    //         style={{
                    //           backgroundImage: `url(${item?.pre_signed_url}) `,
                    //         }}></div>
                    //       <span className="card-content">
                    //         <h3>{item.title}</h3>
                    //         <span>{item.page}</span>
                    //         <img src={IMAGES.iconLink} alt="Icon Link" className="icon-link" />
                    //       </span>
                    //     </Link>
                    //   </Card>
                    // </Col>
                  ))}
                {citationList && !citationList?.length && (
                  <Col span={24}>
                    <div className="no-records-found">No records found.</div>
                  </Col>
                )}
              </Row>
            </div>
          ) : (
            <Col id="list-view" className="list-card-view" span={24}>
              <Card>
                <Table
                  className="global-table responsive-table"
                  rowKey={"asset_id"}
                  columns={columns}
                  dataSource={listViewRecords?.length ? listViewRecords : []}
                  tableLayout="fixed"
                  loading={isFiltersDataLoading}
                  pagination={false}
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        navigate(`${PATHS.viewContentManagementByUser}/${record?.id}`, {
                          state: {
                            fromWhere: FromWhere.ConversationalSearch,
                            selectedChatHistoryId: selectedChatHistory?.id,
                          },
                        });
                      },
                    };
                  }}
                />
              </Card>
            </Col>
          )}
          {citationList?.length < totalCitations?.length && (
            <div className="load-more-btn" onClick={() => onLoadMoreDataAction()}>
              <Button type="text" className="load-more" loading={isFiltersDataLoading}>
                Show more results
              </Button>
            </div>
          )}
        </div>
      </div>
      {visible && (
        <div style={{ display: "none" }}>
          <Image
            preview={{
              visible,
              scaleStep,
              src: `${selectedImageURL}`,
              onVisibleChange: (value) => {
                setVisible(value);
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default OtherAssets;
