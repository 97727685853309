import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Card, Input } from "antd";

import { RootState } from "../../../store";
import {
  clearDataOnTopicStart,
  generationFields,
  setUpdateFields,
  setUpdateIsShow,
} from "../../../store/generation/businessProposal/businessProposalSlice";
import { fetchIndustryFunByTopic } from "../../../services/generation";
import { IMAGES } from "../../../shared";

const Topic = () => {
  const dispatch = useDispatch();
  const { isDisable, isLoading, fields } = useSelector(
    (state: RootState) => state.busProposalSlice
  );

  const handleChangeTopic = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setUpdateFields({ field: generationFields.topic, value: e.target.value }));
  };

  const handleTopicGetStarted = async () => {
    if (fields?.topic && fields?.topic?.trim()) {
      dispatch(clearDataOnTopicStart());
      const res = await fetchIndustryFunByTopic(fields?.topic);

      if (res) {
        dispatch(setUpdateIsShow({ field: generationFields.industryFun, value: true }));
      } else {
        dispatch(setUpdateIsShow({ field: generationFields.industryFun, value: true })); // remove this once API work
      }
    } else {
      toast.error("Topic input cannot be empty");
    }
  };

  return (
    <>
      <Card className="card-item card-enter-topic">
        <div className="card-header">
          <span className="card-number">1</span>
          <h2>Enter topic</h2>
        </div>
        <div className="card-content">
          <div className="generation-input position-relative">
            <Input
              type="text"
              placeholder="Please enter topic"
              value={fields?.topic ?? ""}
              onChange={handleChangeTopic}
              onKeyDownCapture={(event) => {
                if (event.key === "Enter") {
                  handleTopicGetStarted();
                }
              }}
              disabled={isDisable?.topic || isLoading?.topic}
            />
            <div className="action-mode acn-flex acn-flex-middle  position-absolute">
              {isLoading?.topic && (
                <div className="processing-wrap">
                  <img src={IMAGES.loadingSpinner} alt="loading" className="spinnerSvg" />
                  Processing
                </div>
              )}
              <Button
                type="primary"
                className="outline-btn"
                onClick={handleTopicGetStarted}
                disabled={isDisable?.topic || isLoading?.topic}>
                Get started
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default Topic;
