import { Button, Modal } from "antd";

interface IModal {
  title: string;
  message: string;
  isLoading: boolean;
  isModalOpen: boolean;
  handleSubmit: () => void;
  handleCancel: () => void;
  submitBtnName?: string;
  cancelBtnName?: string;
}

const DeleteModal: React.FC<IModal> = ({
  submitBtnName = "Delete",
  cancelBtnName = "Cancel",
  ...props
}) => {
  const { isLoading, isModalOpen, handleSubmit, handleCancel, message, title } = props;

  return (
    <Modal
      className="delete-modal"
      title={title}
      centered={true}
      onCancel={handleCancel}
      footer={
        <>
          <Button className="btn-type1" onClick={handleCancel}>
            {cancelBtnName}
          </Button>
          <Button className="btn-type2" loading={isLoading} danger onClick={handleSubmit}>
            {submitBtnName}
          </Button>
        </>
      }
      open={isModalOpen}>
      <div>{message}</div>
    </Modal>
  );
};

export default DeleteModal;
